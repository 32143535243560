import { ISite } from '../../project/sites-and-study-team/study-and-study-team-manage/site.interface';

export interface CurrentSiteManage {
  site: ISite;
  siteSnapshot: ISite;
  pending: boolean;
  success: boolean;
  error: boolean;
  errors: string[];
  dispatched: boolean;
  pendingChanges: boolean;
  isLocked: boolean;
}

export interface SiteState {
  list: ISite[];
  current: CurrentSiteManage;
}

export interface AppSiteState {
  site: SiteState;
}

export const SITE_INITIAL_STATE: ISite = {
  id: 0,
  code: '',
  name: '',
  country_id: 0,
  city: '',
  is_active: false,
  created_at: '',
  modified_at: '',
  timezone_id: 0,
  language_id: 0,
  street: '',
  street_ln: '',
  postal_code: '',
  email: '',
  phone: 0,
  state: '',
  country_code: '',
  investigator: '',
  teamMembers: null,
}

export const appStateSite: AppSiteState = {
  site: {
    list: [],
    current: {
      site: { ...SITE_INITIAL_STATE },
      siteSnapshot: { ...SITE_INITIAL_STATE },
      error: false,
      errors: [],
      pending: false,
      success: false,
      dispatched: false,
      pendingChanges: false,
      isLocked: false,
    },
  }
};

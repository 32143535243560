import { AssignModel } from '../assign.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface AssignState {
  current: AssignModel;
  list: AssignModel[];
  commentsCounters: {};
}

export interface AppStateAssign {
  assign: AssignState;
}

export const appStateAssign: AppStateAssign = {
  assign: {
    current: {
      userIdUpdated: null,
      userIds: [],
      userIdCreated: null,
      projectId: null,
      organizationId: null,
      securityGroupId: null,
      formId: null,
      emails: [],
      updatedAt: '',
      createdAt: '',
      formName: '',
      id: null,
      assignmentType: null,
      permissionsIds: [],
      accessUrl: '',
      securityGroupType: null,
      orderPriority: null,
      parentId: null
    },
    list: [],
    commentsCounters: {}
  }
};

export const selectAssignState = createFeatureSelector<AssignState>('assign');
export const selectCurrentAssignState = createSelector(selectAssignState, (state: AssignState) => state.current);
export const selectAssignListState = createSelector(selectAssignState, (state) => state.list);
export const selectAssignCommentsCounter = createSelector(selectAssignState, (state) => state.commentsCounters);



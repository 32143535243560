import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[pharAutoIncreaseFieldWidth]'
})
export class AutoIncreaseFieldWidthDirective implements OnInit, OnDestroy {
  @Input() initialWidth = 18;
  private subscription: Subscription = new Subscription();

  constructor(private el: ElementRef) {

  }

  ngOnInit(): void {
    this.el.nativeElement.style.width = this.initialWidth + 'px';
    this.subscription.add(
      fromEvent(this.el.nativeElement, 'change').subscribe(() => {
        const elem = this.el.nativeElement;
        let newWidth = ((elem.value.length) * 9);
        if (newWidth < this.initialWidth) {
          newWidth = this.initialWidth;
        }
        elem.style.width = newWidth + 'px';
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

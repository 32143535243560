import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[pharScrollTo]'
})
export class ScrollToElementDirective implements OnChanges {
  @Input() pharScrollTo: boolean;

  constructor(private el: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.pharScrollTo) {
      const rect = this.el.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;

      if (!topShown) {
        this.el.nativeElement.scrollIntoView({ block: "start", inline: "center" });
      } else if (!bottomShown) {
        this.el.nativeElement.scrollIntoView({ block: "end", inline: "center" });
      }
    }
  }
}

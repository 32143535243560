import { Directive, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[pharNoPropagation]'
})
export class NoPropagationDirective {

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent) {
    e.stopPropagation();
  }
}

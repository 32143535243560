import {
  Component,
  DoCheck,
  ElementRef,
  Inject,
  Input,
  OnDestroy, OnInit,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl, ValidationErrors,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { BaseComponent } from '../base.class';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';

type TimeRangeFormGroup = FormGroup<{
  startHours: FormControl<string | null>;
  startMinutes: FormControl<string | null>;
  endHours: FormControl<string | null>;
  endMinutes: FormControl<string | null>;
}>


@Component({
  selector: 'phar-time-range-picker',
  templateUrl: 'time-range-picker.component.html',
  styleUrl: 'time-range-picker.component.scss',
  providers: [{ provide: MatFormFieldControl, useExisting: TimeRangePickerComponent }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})

export class TimeRangePickerComponent extends BaseComponent implements ControlValueAccessor, MatFormFieldControl<{
  start: string | null,
  end: string | null
}>, OnDestroy, DoCheck, OnInit {
  static nextId = 0;
  @ViewChild('firstInput') firstInput: HTMLInputElement;
  @ViewChild('endInput') endInput: HTMLInputElement;
  @Input() startPlaceholder = 'Start';
  @Input() endPlaceholder = 'End';
  @Input() format: '12h' | '24h' = '24h'
  maxHours = this.format === '12h' ? 12 : 23;
  formGroup: TimeRangeFormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'time-range-input';
  id = `time-range-input-${TimeRangePickerComponent.nextId++}`;
  errorState = false;

  onChange = (_: any) => {
  };
  onTouched = () => {
    this.formGroup.markAllAsTouched();

  };

  get empty() {
    const {
      value: { startHours, startMinutes, endHours, endMinutes },
    } = this.formGroup;

    return !this.isValid(startHours) && !this.isValid(startMinutes) && !this.isValid(endHours) && !this.isValid(endMinutes)
  }


  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string;    // eslint-disable-line

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formGroup.disable() : this.formGroup.enable();
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get value(): { start: string | null; end: string | null } | null {
    return this.formatValue();
  }

  set value(range: { start: string; end: string } | null) {
    if (!range || !range.start || !range.end) {
      this.formGroup.setValue({
        startHours: null,
        startMinutes: null,
        endHours: null,
        endMinutes: null,
      })
      return;
    }
    const [startHours, startMinutes,] = range.start.split(':')
    const [endHours, endMinutes,] = range.end.split(':')
    this.formGroup.patchValue({ startMinutes, startHours, endMinutes, endHours });
    this.stateChanges.next();

  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    super();
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formGroup = formBuilder.group({
      startHours: new FormControl<string | null>(null, [Validators.required]),
      startMinutes: new FormControl<string | null>(null, [Validators.required]),
      endHours: new FormControl<string | null>(null, [Validators.required]),
      endMinutes: new FormControl<string | null>(null, [Validators.required]),
    }, { validators: this.rangeValidator });
  }


  rangeValidator(fg: TimeRangeFormGroup): ValidationErrors | null {
    const errorMsg = { 'invalid': true };
    if (fg.controls.endHours.invalid || fg.controls.endMinutes.invalid || fg.controls.startHours.invalid || fg.controls.startMinutes.invalid) {
      return errorMsg
    }
    const { startHours, startMinutes, endHours, endMinutes } = fg.value;

    return moment(`${startHours}:${startMinutes}`, 'HH:mm').isBefore(moment(`${endHours}:${endMinutes}`, 'HH:mm')) ? null : errorMsg;
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(
      takeUntil(this.destroy$)).subscribe(() => {
      this.onChange(this.formatValue())
    })

  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
      this.onTouched();
    }
  }

  private formatValue(): { start: string, end: string } | null {
    if (this.formGroup.invalid) {
      return null;
    }

    const { startHours, startMinutes, endHours, endMinutes } = this.formGroup.value;
    return {
      start: `${startHours}:${startMinutes}`,
      end: `${endHours}:${endMinutes}`,
    }
  }


  ngOnDestroy() {
    super.ngOnDestroy();
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }


  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.time-picker',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    this._focusMonitor.focusVia(this.firstInput, 'program');
  }

  writeValue(range: { start: string, end: string } | null): void {
    this.value = range;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  isValid(val: string): boolean {
    return val !== null && val !== undefined && val !== '';
  }


  readonly autofilled: boolean;


}


<div
  [style.backgroundImage]="getImageUrl(user.picture ? user.picture : '')"
  class="user-avatar d-flex align-items-center justify-content-center">
  @if (!user.picture) {
    <div id="user-avatar-placeholder">
      @if (alternativeField && user[alternativeField]) {
        {{ user[alternativeField]?.slice(0, 1) }}
      }
      <!--      {{user.firstName?.slice(0, 1) }}{{ user.lastName?.slice(0, 1)}}-->
      {{ user.name }}
    </div>
  }
</div>

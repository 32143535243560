import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[pharInputTrim]',
})

export class InputTrimDirective {
  constructor(
    private el: ElementRef,
    @Optional() private ngControl: NgControl
  ) {
  }

  @HostListener('input') onBlur() {
    const value = this.el.nativeElement.value;
    if (value.length > 1) {
      return;
    }
    const valueTrim = value.trim();
    if (value.trimLeft !== valueTrim) {
      this.ngControl.control.setValue(this.el.nativeElement.value.trimLeft(), { emitValue: false });
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { AppState } from '../../../store/models/app.state';
import { selectCurrentFormStateForm } from '../../../form/store/form.state';
import { ControlModel } from '../../../question/control.model';
import { QuestionModel } from '../../../question/question.model';
import { FormStatusEnum } from '../../../form/form.model';
import { BaseComponent } from '../../base.class';
import { PendingChangesService } from '../../pending-changes.service';
import { EntityCommentState } from '../../entity-comments/entity-comment.state.enum';

export enum ControlActionsEnum {
  Save = 'save',
  Remove = 'remove',
  Edit = 'edit',
  ViewComments = 'viewComments',
  DragStart = 'dragStart',
  DragEnd = 'dragEnd',
  Duplicate = 'duplicate'
}

export interface ControlActionClick {
  action: ControlActionsEnum;
  event: MouseEvent;
}

@Component({
  selector: 'phar-base-control-template',
  templateUrl: 'base-control-template.component.html',
  styleUrls: ['base-control-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseControlTemplateComponent extends BaseComponent implements OnInit {
  @Input() controlName: string = 'Control';
  @Input() builderMode: boolean;
  @Input() isDraggable: boolean;
  @Input() isCommentsVisible: boolean;
  @Input() canManageControl: boolean;
  @Input() isRequired: boolean;
  @Input() showActionButtons: boolean;
  @Input() highlightBackground: boolean;
  @Input() hasConditionalRules: boolean;
  @Input({ required: true }) control: ControlModel;
  @Input({ required: true }) question: QuestionModel;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Output() actionClick = new EventEmitter<ControlActionClick>();
  isHighlighted = false;
  viewCommentsOnly$: Observable<boolean>;
  readonly colorMap = {
    [EntityCommentState.HasUnresolved]: 'warn',
    [EntityCommentState.Resolved]: 'primary',
    [EntityCommentState.NoComments]: '',
  }
  protected readonly ControlActionsEnum = ControlActionsEnum;
  protected readonly CommentsState = EntityCommentState;

  constructor(private readonly store: Store<AppState>, private pendingChangesService: PendingChangesService) {
    super();
  }

  ngOnInit() {
    this.viewCommentsOnly$ = this.store.select(selectCurrentFormStateForm)
      .pipe(
        take(1),
        map((form) => form.formStatus === FormStatusEnum.Released),
      );

    this.pendingChangesService.triggerSave$.pipe(
      filter((triggerId: string) => this.control.controlID == triggerId),
      takeUntil(this.destroy$),
    ).subscribe({
      next: () => {
        // listen for outer save
        this.actionsHandler(ControlActionsEnum.Save, new MouseEvent('click'));
      }
    })

  }

  actionsHandler(action: ControlActionsEnum, event: MouseEvent): void {
    this.actionClick.emit({ action, event });
  }

  highlight(start = true) {
    this.isHighlighted = start;
  }

  handleCloseCommentsDialog() {
    this.trigger.closeMenu();
  }

}

import { Component } from '@angular/core';
import { FormElementsEnum } from '../../../../form/form-elements.enum';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/models/app.state';
import { PharDragService } from '../../../drag.service';
import { setDragged, setDraggedHelper } from '../../../../store/actions/ui.actions';

@Component({
  selector: 'phar-time-picker-widget',
  templateUrl: 'time-picker-widget.component.html',
  styleUrls: ['time-picker-widget.component.scss'],
})
export class TimePickerWidgetComponent {
  readonly type = FormElementsEnum.TimePicker;

  get dragData(): any {
    return {
      control: {
        controlID: '',
        label: '',
        info: '',
        controlType: this.type,
        validators: {},
        bindDataField: '',
        dependencies: {},
        values: [],
      }
    };
  }

  constructor(
    private readonly store: Store<AppState>,
    private readonly dragService: PharDragService,
  ) {
  }

  onDragStart(event): void {
    event.stopImmediatePropagation();
    this.dragService.setDragData(this.dragData);
    this.store.dispatch(setDragged({ dragged: true }));
    this.store.dispatch(setDraggedHelper({ draggedHelper: { type: this.type, reorder: false } }));
  }

  onDragEnd(): void {
    this.store.dispatch(setDragged({ dragged: false }));
    this.store.dispatch(setDraggedHelper({ draggedHelper: {} }));
  }
}

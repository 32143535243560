<button
  id="columns-trigger"
  mat-flat-button
  class="no-highlight"
  [disableRipple]="true"
  [matMenuTriggerFor]="columnsMenu"
  (click)="$event.stopImmediatePropagation()"
  (menuOpened)="handleOpenMenu()"
  (menuClosed)="handleCloseMenu()"
>
  <span>Columns</span>
  <mat-icon svgIcon="arrow-down"></mat-icon>
</button>

<mat-menu #columnsMenu="matMenu" [class]="'columns-container'" yPosition="below">
  <ng-template matMenuContent>
    <div class="columns-section py-2" (click)="$event.stopImmediatePropagation()">
      <h6 class="px-2 my-1">Columns</h6>

      @if (columns?.length) {
        <div class="px-2 columns-section_restore-defaults" (click)="restoreDefaults()">restore defaults</div>

        <mat-selection-list class="columns-list" color="primary" [formControl]="selectedColumnFieldsFormControl">
          @for (column of columns | filterBy: ['selectable']: true; track column.field) {
            <mat-list-option togglePosition="before" [value]="column.field">{{ column.title }}</mat-list-option>
          }
        </mat-selection-list>

        <div class="d-flex justify-content-between px-2 mt-2">
          <button
            mat-stroked-button
            (click)="cancelSelections()">
            Cancel
          </button>

          <button
            class="btn-small button-success"
            mat-stroked-button
            [disabled]="(hasChanges$ | async) === false"
            (click)="saveSelections()">
            Done
          </button>
        </div>
      } @else {
        <div class="d-flex justify-content-center">
          <span>No columns</span>
        </div>
      }
    </div>
  </ng-template>
</mat-menu>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bindDataFieldShorten'
})
export class BindDataFieldShortenPipe implements PipeTransform {

  transform(value: string, length: number = 15, noFieldText: string = 'No data field.'): any {
    if (!value) {
      return noFieldText;
    }

    let fieldsTest: string[] | string = value.split('.');
    if (fieldsTest.length > 1) {
      fieldsTest.shift();
    }

    if (fieldsTest.length > 2) {
      // is in repeatable in repeatable so additional trim the parent group
      fieldsTest.shift();
    }

    fieldsTest = fieldsTest.join('.');
    if (fieldsTest.length < 20) {
      return fieldsTest;
    }

    let fields: string[] = value.split('.');
    fields.shift();
    fields = fields.map(field => field.length > length ? field.split('').slice(0, length).join('') + '...' : field);
    return fields.join('.');
  }

}

import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../../store/models/app.state";
import { PharBaseControlModelComponent } from "../phar-base-control/phar-base-control-model.component";

@Component({
  selector: 'phar-general-boolean',
  templateUrl: 'general-boolean.component.html',
  styleUrls: ['general-boolean.component.scss'],
})
export class GeneralBooleanComponent extends PharBaseControlModelComponent {
  constructor(
    store: Store<AppState>
  ) {
    super(store);
  }
}

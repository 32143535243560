import { IRole } from '../../shared/models/role.interface';
import { IApplication } from '../../shared/models/aplications.interface';
import { IPermission } from '../../shared/models/permission.interface';

export interface CurrentRoleManage {
  role: IRole;
  roleSnapshot: IRole;
  pending: boolean;
  success: boolean;
  error: boolean;
  errors: string[];
  dispatched: boolean;
  pendingChanges: boolean;
}

export interface RoleState {
  list: IRole[];
  current: CurrentRoleManage;
  applications: IApplication[];
  activeApplication: IApplication | null;
  permissions: PermissionState;
}

export interface PermissionState {
  list: IPermission[];
  rolePermissions: IRole[]
}

export interface AppRoleState {
  roles: RoleState;
}

export const ROLE_INITIAL_STATE: IRole = {
  id: null,
  name: '',
  description: '',
  roleType: '',
  studyId: null,
  study: null,
}

export const appStateRole: AppRoleState = {
  roles: {
    list: [],
    current: {
      role: { ...ROLE_INITIAL_STATE },
      roleSnapshot: { ...ROLE_INITIAL_STATE },
      error: false,
      errors: [],
      pending: false,
      success: false,
      dispatched: false,
      pendingChanges: false,
    },
    applications: [],
    activeApplication: null,
    permissions: {
      list: [],
      rolePermissions: [],
    },
  }
};

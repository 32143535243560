@if (autoCompleter) {
  <input #trigger
         (focusin)="preventPanelOpening($event)"
         [formControl]="customInput"
         [matAutocomplete]="auto"
         [matAutocompleteDisabled]="true"
         [ngClass]="{'disabled-item': disabledItem}"
         [placeholder]="placeholder"
         (click)="openPanel($event)"
         matInput
         type="text">

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    @for (option of filteredData$ | async; track option[returnValue]) {
      <mat-option [value]="option">
        @if (displayTemplate) {
          <ng-template
            [ngTemplateOutlet]="displayTemplate"
            [ngTemplateOutletContext]="{$implicit: option}">
          </ng-template>
        } @else {
          {{ option[displayValue] }}
        }
      </mat-option>
      @if (!(filteredData$ | async).length) {
        <mat-option [disabled]="true"
                    class="text-muted">{{ noDataText }}
        </mat-option>
      }

    }
  </mat-autocomplete>
  @if (showAll) {
    <div (click)="openPanel($event)" class="mat-select-arrow-wrapper" id="auto-completer-icon">
      <div class="mat-select-arrow"></div>
    </div>
  }
} @else {
  <mat-select [compareWith]="compareFn" [formControl]="customInput" [placeholder]="placeholder">
    @for (option of data; track option[returnValue]) {
      <mat-option [value]="option">
        {{ option[displayValue] }}
      </mat-option>
    }
    @if (data && !data.length) {
      <mat-option [disabled]="true" class="text-muted">{{ noDataText }}</mat-option>
    }

  </mat-select>
}

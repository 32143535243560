import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormStatusEnum } from '../../form/form.model';
import { ProjectStatusEnum } from '../../project/project.model';
import { cloneDeep } from 'lodash-es';


enum ApprovalState {
  Approved = 'approved',
  Active = 'active',
  Pending = 'pending',
  Rejected = 'rejected',
  Amended = 'amended',
  Archived = 'archived'
}

interface Approval {
  label: string;
  state: ApprovalState;
  status: ApprovalStatusType;
  actionsTemplate?: TemplateRef<any>;
}

export const ApprovalStatus = { ...FormStatusEnum, ...ProjectStatusEnum };
export type ApprovalStatusType = FormStatusEnum | ProjectStatusEnum;

@Component({
  selector: 'phar-approvals',
  templateUrl: 'approvals.component.html',
  styleUrls: ['approvals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class ApprovalsComponent implements OnInit, OnChanges {
  @ViewChild('sendForReviewTemplate', { static: true }) sendForReviewTemplate: TemplateRef<any>;
  @ViewChild('startReviewTemplate', { static: true }) startReviewTemplate: TemplateRef<any>;
  @ViewChild('releaseOrRejectTemplate', { static: true }) releaseOrRejectTemplate: TemplateRef<any>;
  @Input() recordStatus: ApprovalStatusType = ApprovalStatus.Draft;
  @Input() locked = false;
  @Output() statusChange: EventEmitter<{ status: ApprovalStatusType }> = new EventEmitter()
  approvals$: BehaviorSubject<Approval[]> = new BehaviorSubject<Approval[]>([]);
  FINAL_STATES = [
    {
      label: 'Released',
      status: ApprovalStatus.Released,
      state: ApprovalState.Pending,
    },
    {
      label: 'Rejected',
      status: ApprovalStatus.Rejected,
      state: ApprovalState.Pending,
    },
    {
      label: 'Archived',
      status: ApprovalStatus.Archived,
      state: ApprovalState.Pending,
    },
    {
      label: 'Amended',
      status: ApprovalStatus.Amended,
      state: ApprovalState.Pending,
    },
  ];
  DEFAULT_STATES: Approval[] = []
  protected readonly ApprovalState = ApprovalState;
  protected readonly ApprovalStatus = ApprovalStatus;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.DEFAULT_STATES = [
      {
        label: 'Draft',
        status: ApprovalStatus.Draft,
        state: ApprovalState.Active,
        actionsTemplate: this.sendForReviewTemplate,
      },
      {
        label: 'Sent for review',
        status: ApprovalStatus.PendingReview,
        actionsTemplate: this.startReviewTemplate,
        state: ApprovalState.Pending,
      },
      {
        label: 'Under review',
        status: ApprovalStatus.UnderReview,
        actionsTemplate: this.releaseOrRejectTemplate,
        state: ApprovalState.Pending,
      },
    ]

    this.approvals$.next(
      cloneDeep(this.DEFAULT_STATES)
    );

    this.calculateStatus();
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.recordStatus && changes.recordStatus.currentValue) {
      this.calculateStatus();
    }
  }

  setFormStatus(status: ApprovalStatusType) {
    this.statusChange.emit({ status });

  }

  private calculateStatus() {

    if (!this.recordStatus) {
      return;
    }

    const finalState = this.getFinalState(this.recordStatus);
    let currentApprovals = [...cloneDeep(this.DEFAULT_STATES), finalState];
    const index = currentApprovals.findIndex(item => item.status === this.recordStatus);
    if (index < 0) {
      return;
    }


    if (finalState.status === currentApprovals[index].status) {
      switch (finalState.status) {
        case ApprovalStatus.Rejected:
          currentApprovals[index].state = ApprovalState.Rejected;
          break;
        case ApprovalStatus.Released:
          currentApprovals[index].state = ApprovalState.Approved;
          break;
        case ApprovalStatus.Amended:
          currentApprovals[index].state = ApprovalState.Amended;
          break;
        case ApprovalStatus.Archived:
          currentApprovals[index].state = ApprovalState.Archived;
          break;
        default:
          currentApprovals[index].state = ApprovalState.Active;
          break;
      }
    } else {
      currentApprovals[index].state = ApprovalState.Active;
    }

    for (let i = 0; i <= index - 1; i++) {
      // mark previous as done
      currentApprovals[i].state = ApprovalState.Approved;
    }

    this.approvals$.next(currentApprovals);
  }

  private getFinalState(currentState: ApprovalStatusType): Approval {
    const target = this.FINAL_STATES.find(element => currentState === element.status);
    if (!target) {
      // default final state is RELEASED
      return this.FINAL_STATES.find(el => el.status === ApprovalStatus.Released);
    }

    return target;
  }

}

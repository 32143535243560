<div class="mfm-timepicker input-group" [attr.aria-labelledby]="_formField?.getLabelId()" [formGroup]="form">
  <div class=" d-flex flex-row align-items-center">
    <phar-timepicker-control
      class="mfm-timepicker__control--first"
      formControlName="hours"
      [max]="maxHour"
      [min]="minHour"
      [placeholder]="'HH'">
    </phar-timepicker-control>

    <phar-timepicker-control
      class="mfm-timepicker__control--third"
      formControlName="minutes"
      [max]="59"
      [min]="0"
      [placeholder]="'mm'"
      #minutesElement
    >
    </phar-timepicker-control>

    @if (withSeconds) {
      <phar-timepicker-control
        class="mfm-timepicker__control--fifth"
        formControlName="seconds"
        [placeholder]="'ss'"
        [max]="59"
        [min]="0">
      </phar-timepicker-control>
    }
  </div>
  @if (format === 12) {
    <div class="input-group-append cursor-pointer">
      <phar-timepicker-period-selector
        class="mfm-timepicker__control--sixth input-group-text"
        formControlName="period">
      </phar-timepicker-period-selector>
    </div>
  }

</div>




import { createAction, createFeatureSelector, createSelector, props } from '@ngrx/store';
import { ControlModel } from '../../question/control.model';
import { ControlState, CurrentControlManage } from '../models/control.state';

const PREFIX = "[CONTROL]"

export enum ActionTypes {
  POPULATE_CURRENT_CONTROL = `${PREFIX} POPULATE_CURRENT_CONTROL`,
  UPDATE_CONTROL_FIELD = `${PREFIX} UPDATE_CONTROL_FIELD`,
  RESET_CURRENT_CONTROL = `${PREFIX} RESET_CURRENT_CONTROL`,
  UPDATE_CONTROL_SETTINGS_FIELD = `${PREFIX} UPDATE_CONTROL_SETTINGS_FIELD`,
}

export const populateCurrentControl = createAction(ActionTypes.POPULATE_CURRENT_CONTROL, props<{
  control: ControlModel
}>());
export const updateControlField = createAction(ActionTypes.UPDATE_CONTROL_FIELD, props<{
  field: string,
  value: any
}>());
export const resetCurrentControl = createAction(ActionTypes.RESET_CURRENT_CONTROL);
export const updateControlSettingsField = createAction(ActionTypes.UPDATE_CONTROL_SETTINGS_FIELD, (settingsField: string, value: any) => ({
  settingsField,
  value
}));

export const selectControlState = createFeatureSelector<ControlState>('control');
export const selectCurrentControlState = createSelector(selectControlState, (state: ControlState) => state.current);

/* src/app/shared/help-icon/help-icon.component.scss */
:host {
  height: auto;
}
#help-icon {
  height: 13px;
  font-size: 14px;
  width: 14px;
}
/*# sourceMappingURL=help-icon.component.css.map */

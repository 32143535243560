import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, getState } from '../store/models/app.state';
import { PharConfirmDialogService } from './confirm-dialog/confirm-dialog-service.service';
import { take } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PendingChangesService {
  confirmedSavedChanges$: Observable<boolean>;
  private _hasPendingChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _confirmedSavedChanges$: Subject<boolean> = new Subject<boolean>();
  private _discardChanges$: Subject<boolean> = new Subject<boolean>();
  private _triggerSave: Subject<string | number> = new Subject<string | number>();

  hasPendingChanges$: Observable<boolean> = this._hasPendingChanges$.asObservable();
  discardChanges$ = this._discardChanges$.asObservable();
  triggerSave$: Observable<string | number> = this._triggerSave.asObservable();

  constructor(
    private confirmationService: PharConfirmDialogService,
    private store: Store<AppState>
  ) {
    this.confirmedSavedChanges$ = this._confirmedSavedChanges$.asObservable();
  }

  set hasPendingChanges(hasChanges: boolean) {
    this._hasPendingChanges$.next(hasChanges);
  }

  get hasPendingChanges(): boolean {
    return this._hasPendingChanges$.value;
  }

  openPendingChangesSaveDialog(disableClose = false): void {
    this.confirmationService
      .openConfirmDialog(
        "Do you want to save your changes now?",
        `You have unsaved changes.`,
        "Save",
        "Discard",
        disableClose,
        350
      )
      .pipe(
        take(1)
      ).subscribe((confirmed: boolean) => {
      if (confirmed !== undefined) {
        this._confirmedSavedChanges$.next(confirmed);
      }
    });
  }

  saveChanges(): void {
    this._confirmedSavedChanges$.next(true);
  }

  triggerDiscardChanges(): void {
    this._discardChanges$.next(true);
  }

  triggerSave(triggerId: string | number): void {
    this._triggerSave.next(triggerId);
  }
}

import { Component, inject, Injector } from '@angular/core';
import { AppState } from '../../../store/models/app.state';
import { Store } from '@ngrx/store';
import { toSignal } from '@angular/core/rxjs-interop';
import { selectCurrentUserProfile, selectUsersList } from '../../../user/store/user.state';
import { BaseComponent } from '../../base.class';
import { IEntityComment } from '../../models/entity-comments.interface';
import { EntityCommentState } from '../entity-comment.state.enum';

@Component({
  selector: 'phar-entity-comments-base',
  template: '',
  standalone: true,
})

export class EntityCommentsBaseComponent extends BaseComponent {
  injector = inject(Injector)
  store: Store<AppState> = inject(Store);
  comments: IEntityComment[] = [];
  user = toSignal(this.store.select(selectCurrentUserProfile), { injector: this.injector });
  usersList = toSignal(this.store.select(selectUsersList), { injector: this.injector });


  getCommentsState(): EntityCommentState {
    if (!this.comments.length) {
      return EntityCommentState.NoComments;
    }

    if (this.hasUnresolvedComments()) {
      return EntityCommentState.HasUnresolved;
    }

    return EntityCommentState.Resolved;
  }

  hasUnresolvedComments(): boolean {
    return this.comments.some((comment) => !comment.isResolved);
  }
}

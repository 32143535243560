import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../core/config/app.config';
import { PharHttp } from '../../core/http/http-client.service';
import { IQuestionComment } from './question-comment.interface';

@Injectable({
  providedIn: 'root'
})
export class QuestionCommentsService {
  private readonly url: string;
  constructor(
    private httpClient: PharHttp,
    private config: AppConfig,
  ) {
    this.url = this.config.buildUri('questionComments');
  }


  getComments(questionId: number, controlId: string = ''): Observable<IQuestionComment[]> {
    return this.httpClient.get(`${this.url}/QuestionComments?questionId=${questionId}&controlId=${controlId}`);
  }

  create(comment: IQuestionComment): Observable<IQuestionComment> {
    return this.httpClient.post(`${this.url}/QuestionComments`, comment);
  }

  update(comment: IQuestionComment): Observable<IQuestionComment> {
    return this.httpClient.put(`${this.url}/QuestionComments`, comment);
  }

  delete(id: number): Observable<any> {
    return this.httpClient.delete(`${this.url}/QuestionComments?questionCommentsId=${id}`);
  }

  searchById(id: number): Observable<IQuestionComment> {
    return this.httpClient.get(`${this.url}/QuestionCommentsById?id=${id}`);
  }


  markAsResolved(id: number): Observable<IQuestionComment> {
    return this.httpClient.post(`${this.url}/MarkCommentAsResolved?questionCommentId=${id}`, null);
  }
}

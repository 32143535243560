import { ProjectModel, ProjectStatusEnum, ProjectTypeEnum, RecordFormsModel } from '../project.model';
import { ITeamMember } from '../sites-and-study-team/study-and-study-team-manage/team-member.interface';

export interface CurrentProjectManage {
  project: ProjectModel;
  projectSnapshot: ProjectModel;
  admins: ITeamMember[];
  pending: boolean;
  success: boolean;
  error: boolean;
  errors: string[];
  dispatched: boolean;
  pendingChanges: boolean;
  isLocked: boolean;
  projectMatrixCommentsCounters: {
    [key: string]: number
  }
}

export interface ProjectState {
  list: ProjectModel[];
  current: CurrentProjectManage;
  recordForms: RecordFormsModel[];

}

export interface AppProjectState {
  project: ProjectState;
}

export const PROJECT_INITIAL_STATE: ProjectModel = {
  id: null,
  datasetId: null,
  name: '',
  description: '',
  createdAt: '',
  updatedAt: '',
  userIdCreated: null,
  userIdUpdated: null,
  displayName: '',
  recordTitleField: '',
  isDeleted: null,
  userID: null,
  projectType: ProjectTypeEnum.GeneralPurpose,
  integration: {},
  agentId: null,
  isTemplate: false,
  parentTemplateId: null,
  shortName: '',
  forms: [],
  shared: [],
  organizationId: null,
  userFirstNameField: '',
  userLastNameField: '',
  customerIdentifier: null,
  projectStatus: ProjectStatusEnum.Draft,
  projectVersion: '0.1',
  amendedDate: null,
  dateSentForReview: null,
  dateReviewStarted: null,
  rejectionDate: null,
  releaseDate: null,
}

export const appStateProject: AppProjectState = {
  project: {
    list: [],
    current: {
      project: { ...PROJECT_INITIAL_STATE },
      projectSnapshot: { ...PROJECT_INITIAL_STATE },
      error: false,
      errors: [],
      pending: false,
      success: false,
      dispatched: false,
      pendingChanges: false,
      isLocked: false,
      admins: [],
      projectMatrixCommentsCounters: {}
    },
    recordForms: [],
  },
};

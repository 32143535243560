import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { INPUT_DEBOUNCE_TIME } from '../../core/config/app.constants';
import { BaseComponent } from '../base.class';

@Component({
  selector: 'phar-date-range-picker',
  templateUrl: 'date-range-picker.component.html',
  styleUrl: './date-range-picker.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true
    }
  ]
})

export class DateRangePickerComponent extends BaseComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() startPlaceholder = 'Start date';
  @Input() endPlaceholder = 'End date';
  @Input() appearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'outline';
  @Input() label = 'Enter a date range';
  disabled: boolean = false;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });


  ngOnInit() {
    this.range.valueChanges.pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      takeUntil(this.destroy$)
    ).subscribe({
      next: (value) => {
        this.updateValue(value)
      }
    })
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  writeValue(value: { start: Date | null, end: Date | null } | null): void {
    if (!value) {
      return;
    }

    this.range.patchValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateValue(value: Partial<{ start: Date | null, end: Date | null }> | null): void {
    this.onChange(value);
    this.onTouch();
  }
}

import {
  Component,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
  ChangeDetectorRef,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { parseInt, toString } from 'lodash-es';

@Component({
  selector: 'phar-timepicker-control',
  templateUrl: './phar-timepicker-control.component.html',
  styleUrls: ['./phar-timepicker-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PharTimepickerControlComponent),
    multi: true
  }]
})
export class PharTimepickerControlComponent implements ControlValueAccessor {
  @Input() min: number;
  @Input() max: number;
  @Input() placeholder: string;
  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;
  isFocused: boolean;
  disabled: boolean;
  timeControl: string = '00';

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  get timeControlValueAsString(): string {
    return this.timeControl ? this.timeControl.toString() : '';
  }

  writeValue(value: any): void {
    if (value && value.trim().length && !isNaN(value)) {
      this.timeControl = toString(Number(value));
      this.formatValue();
      this.changeDetectorRef.markForCheck();
    } else {
      this.timeControl = '';
      this.changeDetectorRef.markForCheck();

    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetectorRef.markForCheck();
  }

  onChange = (_: any) => {
  };
  onTouched = () => {
  };

  handleChanges(value: number): void {
    if (!isNaN(value)) {
      this.timeControl = toString(value);
      if (parseInt(this.timeControl) > this.max) {
        const timeString = String(value);
        this.timeControl = toString(+timeString[timeString.length - 1]);
      }

      if (parseInt(this.timeControl) < this.min) {
        this.timeControl = toString(this.min);
      }

      this.formatValue();

      this.onChange((this.timeControlValueAsString));
    }
  }

  onKeyUp(event: any): void {
    event.stopPropagation();
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        this.increase();
        break;
      case 'ArrowDown':
        event.preventDefault();
        this.decrease();
        break;
    }
  }

  increase(): void {
    if (!this.disabled) {
      const timeControlValue = this.timeControl || 0;
      let nextTime = +timeControlValue + 1;
      if (nextTime > this.max) {
        nextTime = this.min;
      }
      if (nextTime !== timeControlValue) {
        this.timeControl = toString(nextTime);
        this.formatValue();
        this.onChange((this.timeControlValueAsString));
      }
    }
  }

  decrease(): void {
    if (!this.disabled) {
      const timeControlValue = this.timeControl || 0;
      let previousTime = +timeControlValue - 1;
      if (previousTime < this.min) {
        previousTime = this.max;
      }
      if (previousTime !== timeControlValue) {
        this.timeControl = toString(previousTime);
        this.formatValue();
        this.onChange((this.timeControlValueAsString));
      }
    }
  }

  formatValue(): void {
    if (this.timeControlValueAsString.length == 1) {
      this.timeControl = '0' + this.timeControl;
    } else if (this.timeControlValueAsString[0] == '0') {
      this.timeControl = String(this.timeControl).slice(1);
    }
  }

  focusElement(): void {
    this.inputElement.nativeElement.focus();
  }
}



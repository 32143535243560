<div class="time-picker">
  <div class="time-input d-flex" [formGroup]="formGroup">
    <div class="start d-flex" (focus)="onFocusIn()" (focusout)="onFocusOut($event)">
      <phar-timepicker-control [min]="0" [max]="maxHours" formControlName="startHours"
                              #firstInput
                              placeholder="HH" />
      <phar-timepicker-control [min]="0" [max]="59" formControlName="startMinutes"
                              placeholder="mm" />

    </div>
    <span class="ms-2 me-1 align-self-center">&ndash;</span>
    <div class="end d-flex" (focus)="onFocusIn()" (focusout)="onFocusOut($event)">
      <phar-timepicker-control [min]="0" [max]="maxHours" formControlName="endHours"
                              placeholder="HH" />
      <phar-timepicker-control [min]="0" [max]="59" formControlName="endMinutes"
                              placeholder="mm" />
    </div>
  </div>

</div>

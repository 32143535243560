import { Component, Input, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { toggleSidebar } from '../../store/actions/ui.actions';
import { AppState, getState } from '../../store/models/app.state';
import { toggleInOut } from '../../animations';

export interface SecondLevelMenuLink {
  url: string;
  text: string;
  isDisabled?: boolean;
}

@Component({
  selector: 'phar-second-level-menu',
  templateUrl: './second-level-menu.component.html',
  styleUrls: ['./second-level-menu.component.scss'],
  animations: [toggleInOut],
})
export class SecondLevelMenuComponent implements OnInit {
  @Input() links: SecondLevelMenuLink[];
  sidebar: Observable<string>;
  header: Observable<boolean>;

  constructor(
    private store: Store<AppState>
  ) {
  }

  ngOnInit(): void {
    this.sidebar = this.store.select(s => s.ui.sidebar);
    this.header = this.store.select(s => s.ui.header);
  }

  toggleMenu(): void {
    getState(this.store).ui.sidebar === 'open' ?
      this.store.dispatch(toggleSidebar({ sidebar: 'close' })) :
      this.store.dispatch(toggleSidebar({ sidebar: 'open' }));
  }

}

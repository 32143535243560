<phar-base-control-template
  controlName="Numeric Rating Scale"
  [control]="control()"
  [question]="question"
  [builderMode]="builderMode"
  [isDraggable]="isDraggable"
  [isCommentsVisible]="isCommentsVisible"
  [canManageControl]="canManageControl"
  [showActionButtons]="(isControlActive$ | async) || (isControlHovered$ | async)"
  [highlightBackground]="isControlHighlighted$ | async"
  [hasConditionalRules]="hasConditionalRules()"
  (actionClick)="actionsHandler($event, control())">
  <div class="slider-wrapper w-100" [ngClass]="{ 'vertical-layout': isVerticalLayout }">
    <div class="scale-labels">
      <mat-label *ngFor="let label of control().settings.labels" [innerHTML]="label | safeHtml"></mat-label>
    </div>

    <div class="scale">
      <button
        *ngFor="let scaleNumber of getScaleNumbers()"
        [ngClass]="{'active-stroked-button': scaleNumber === selectedScaleNumber}"
        class="scale-number no-pointer-events"
        mat-stroked-button>
        {{ scaleNumber }}
      </button>
    </div>
  </div>
</phar-base-control-template>



import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TimePeriod } from '../enums/time-period.enum';

@Component({
  selector: 'phar-timepicker-period-selector',
  templateUrl: './phar-timepicker-period-selector.component.html',
  styleUrls: ['./phar-timepicker-period-selector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PharTimepickerPeriodSelectorComponent),
    multi: true
  }]
})
export class PharTimepickerPeriodSelectorComponent implements ControlValueAccessor {

  disabled: boolean;

  periodControl: TimePeriod = TimePeriod.AM

  constructor() {
  }

  writeValue(obj): void {
    if (obj && obj.length) {
      this.periodControl = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange = (_: any) => {
  };
  onTouched = () => {
  };

  togglePeriod(): void {
    if (!this.periodControl) {
      return;
    }
    this.periodControl = (this.periodControl === TimePeriod.AM) ? TimePeriod.PM : TimePeriod.AM;
    this.onChange(this.periodControl);
  }
}

import { createAction, createFeatureSelector, createSelector, props } from '@ngrx/store';
import { ProjectModel, RecordFormsModel } from '../project.model';
import { CurrentProjectManage, ProjectState } from './project.state';
import { ITeamMember } from '../sites-and-study-team/study-and-study-team-manage/team-member.interface';

const PREFIX = '[STUDY]';

export enum ActionTypes {
  DELETEPROJECT = `${PREFIX} DELETEPROJECT`,
  LOAPROJECTDLIST = `${PREFIX} LOAPROJECTDLIST`,
  UPDATEPROJECTLIST = `${PREFIX} UPDATEPROJECTLIST`,
  REMOVEPROJECT = `${PREFIX} REMOVEPROJECT`,
  RESET_CURRENT_PROJECT = `${PREFIX} RESET_CURRENT_PROJECT`,
  REFRESH_CURRENT_PROJECT = `${PREFIX} REFRESH_CURRENT_PROJECT`,
  SEARCH_PROJECT_BY_ID = `${PREFIX} SEARCH_PROJECT_BY_ID`,
  POPULATE_CURRENT_PROJECT = `${PREFIX} POPULATE_CURRENT_PROJECT`,
  POPULATE_CURRENT_PROJECT_SNAPSHOT = `${PREFIX} POPULATE_CURRENT_PROJECT_SNAPSHOT`,
  CREATE_PROJECT = `${PREFIX} CREATE_PROJECT`,
  UPDATE_PROJECT = `${PREFIX} UPDATE_PROJECT`,
  PENDING_CREATE_PROJECT = `${PREFIX} PENDING_CREATE_PROJECT`,
  DISPATCHED_CREATE_PROJECT = `${PREFIX} DISPATCHED_CREATE_PROJECT`,
  SUCCESS_CREATE_PROJECT = `${PREFIX} SUCCESS_CREATE_PROJECT`,
  UPDATE_PROJECT_IN_LIST = `${PREFIX} UPDATE_PROJECT_IN_LIST`,
  UPDATE_PROJECT_FIELD = ` ${PREFIX} UPDATE_PROJECT_FIELD`,
  UPDATE_PROJECT_FIELDS = `${PREFIX} UPDATE_PROJECT_FIELDS`,
  ADD_PROJECT = `${PREFIX} ADD_PROJECT`,
  GET_RECORD_FORMS = `${PREFIX} Get record forms`,
  POPULATE_RECORD_FORMS = `${PREFIX} Populate record forms`,
  CHECK_CURRENT_PROJECT_FOR_PENDING_CHANGES = `${PREFIX} Check current project for pending changes`,
  GET_STUDY_ADMIN_LIST = `${PREFIX} Get study admin list`,
  POPULATE_STUDY_ADMIN_LIST = `${PREFIX} Populate study admin list`,
  DELETE_STUDY_ADMIN = `${PREFIX} Delete study admin`,
  REMOVE_STUDY_ADMIN_FROM_LIST = `${PREFIX} Remove study admin from list`,
  LOAD_PROJECT_MATRIX_ITEMS_COMMENTS_COUNTERS = `${PREFIX} Load project matrix items comments counters`,
  POPULATE_PROJECT_MATRIX_ITEMS_COMMENTS_COUNTERS = `${PREFIX} Populate project matrix items comments counters`,
}

export const getRecordForms = createAction(ActionTypes.GET_RECORD_FORMS, props<{
  projectId: number,
  datasetId: number
}>());

export const populateRecordForms = createAction(ActionTypes.POPULATE_RECORD_FORMS, props<{
  recordForms: RecordFormsModel[]
}>());

export const loadProjectList = createAction(ActionTypes.LOAPROJECTDLIST);

export const loadProjectMatrixItemsCommentsCounters = createAction(ActionTypes.LOAD_PROJECT_MATRIX_ITEMS_COMMENTS_COUNTERS, props<{
  projectId: number
}>());
export const populateProjectMatrixItemsCommentsCounters = createAction(ActionTypes.POPULATE_PROJECT_MATRIX_ITEMS_COMMENTS_COUNTERS, props<{
  counters: any[]
}>());
export const getStudyAdminList = createAction(ActionTypes.GET_STUDY_ADMIN_LIST, props<{ studyId: number }>());

export const populateStudyAdminList = createAction(ActionTypes.POPULATE_STUDY_ADMIN_LIST, props<{
  admins: ITeamMember[]
}>());

export const deleteStudyAdmin = createAction(ActionTypes.DELETE_STUDY_ADMIN, props<{
  id: number
}>());
export const removeStudyAdminFromList = createAction(ActionTypes.REMOVE_STUDY_ADMIN_FROM_LIST, props<{
  id: number
}>());

export const updateProjectList = createAction(ActionTypes.UPDATEPROJECTLIST, props<{ projectList: ProjectModel[] }>());

export const deleteProject = createAction(ActionTypes.DELETEPROJECT, props<{ id: number }>());

export const removeProject = createAction(ActionTypes.REMOVEPROJECT, props<{ id: number }>());

export const resetCurrentProject = createAction(ActionTypes.RESET_CURRENT_PROJECT);

export const refreshCurrentProject = createAction(ActionTypes.REFRESH_CURRENT_PROJECT);

export const populateCurrentProject = createAction(ActionTypes.POPULATE_CURRENT_PROJECT, props<{
  project: ProjectModel
}>());

export const populateCurrentProjectSnapshot = createAction(
  ActionTypes.POPULATE_CURRENT_PROJECT_SNAPSHOT,
  props<{ project: ProjectModel }>()
);

export const searchProjectById = createAction(ActionTypes.SEARCH_PROJECT_BY_ID, props<{ id: number }>());

export const createProject = createAction(ActionTypes.CREATE_PROJECT, props<{ project: ProjectModel }>());

export const updateProject = createAction(ActionTypes.UPDATE_PROJECT, props<{ project: ProjectModel }>());

export const pendingCreateProject = createAction(ActionTypes.PENDING_CREATE_PROJECT, props<{ pending: boolean }>());

export const dispatchedCreateProject = createAction(ActionTypes.DISPATCHED_CREATE_PROJECT, props<{
  dispatched: boolean
}>());

export const successCreateProject = createAction(ActionTypes.SUCCESS_CREATE_PROJECT, props<{ success: boolean }>());

export const updateProjectInList = createAction(ActionTypes.UPDATE_PROJECT_IN_LIST, props<{ project: ProjectModel }>());

export const addProject = createAction(ActionTypes.ADD_PROJECT, props<{ project: ProjectModel }>());

export const updateProjectField = createAction(ActionTypes.UPDATE_PROJECT_FIELD, props<{
  field: string,
  value: string | number | any
}>());

export const updateProjectFields = createAction(ActionTypes.UPDATE_PROJECT_FIELDS, props<{
  partialProject: Partial<ProjectModel>
}>());

export const checkCurrentProjectForPendingChanges = createAction(
  ActionTypes.CHECK_CURRENT_PROJECT_FOR_PENDING_CHANGES,
  props<{ hasChanges: boolean }>()
);

export const selectProjectFeature = createFeatureSelector<ProjectState>('project');

export const selectProjectList = createSelector(
  selectProjectFeature,
  (state: ProjectState) => state.list,
);

export const selectCurrentProject = createSelector(
  selectProjectFeature,
  (state: ProjectState) => state.current
);

// export const selectCurrentProjectAdmins = createSelector(
//   selectCurrentProject,
//   (state: ProjectState) => state.current
// );


export const checkCurrentProjectLockedState = createSelector(
  selectCurrentProject,
  (state: CurrentProjectManage) => state.isLocked
);
export const selectCurrentProjectManageProject = createSelector(
  selectCurrentProject,
  (state: CurrentProjectManage) => state.project
);

export const selectCurrentStudyAdmins = createSelector(
  selectCurrentProject,
  (state: CurrentProjectManage) => state.admins
);
export const selectCurrentProjectManageProjectSnapshot = createSelector(
  selectCurrentProject,
  (state: CurrentProjectManage) => state.projectSnapshot
);
export const selectCurrentProjectManageProjectMatrixCommentsCounters = createSelector(
  selectCurrentProject,
  (state: CurrentProjectManage) => state.projectMatrixCommentsCounters
);


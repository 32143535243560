import { take } from 'rxjs/operators';
import { UiState } from './ui.state';
import { createFeatureSelector, createSelector, select, Store } from '@ngrx/store';

import { appStateUser, UserState } from '../../user/store/user.state';
import { appStateProject, ProjectState } from '../../project/store/project.state';
import { appStateForm, FormState } from '../../form/store/form.state';
import { appStateQuestion, QuestionState } from '../../question/store/question.state';
import { CONTROL_INITIAL_STATE, ControlState } from './control.state';
import { appStateAssign, AssignState } from '../../assign/store/assign.state';
/*import { appStatePermission, PermissionState } from '../../permission/store/permission.state';*/
import { appAuditTrailInitialState, AuditTrailState } from '../../audit/store/audit.state';
import { appStateEvent, EventState } from '../../events/store/event.state';
import { appStateDataset, DatasetState } from '../../dataset/store/dataset.state';
import { SettingsState } from './settings.state';
import { appStateSite, SiteState } from '../../site/store/site.state';
import {
  appStateRole,
  RoleState
} from '../../roles-and-permissions/store/roles-and-permissions.state';

export interface AppState {
  assign: AssignState;
  control: ControlState;
  dataset: DatasetState;
  form: FormState;

  project: ProjectState;
  event: EventState;
  question: QuestionState;
  ui: UiState;
  user: UserState;
  /*permission: PermissionState;*/
  auditTrail: AuditTrailState;
  settings: SettingsState,
  site: SiteState,
  roles: RoleState,
  // record: RecordState;
  // group: GroupState;
  // role: RoleState;
  // organization: OrganizationState;
  // datasetViews: DatasetViewState;
  // customers: CustomersState;
}

export const appState: AppState = {
  ...appStateAssign,
  control: {
    current: CONTROL_INITIAL_STATE(),
  },
  ...appStateDataset,
  ...appStateForm,

  /*...appStatePermission,*/
  ...appStateProject,
  ...appStateEvent,
  ...appStateQuestion,

  ui: {
    loading: false,
    globalLoading: false,
    listFilter: '',
    sidebar: 'open',
    mediaQuery: {
      active: 'md',
      sm: '(min-width:450px)',
      md: '(min-width:650px)',
      lg: '(min-width:950px)'
    },
    header: true,
    thirdLevelMenuElements: [],
    contextTitlesList: [],
    sorting: '-original.createdAt',
    dragged: false,
    draggedHelper: {},
    resized: false,
    redirectUrl: '',
    error: '',
    message: ''
  },
  settings: {
    countries: [],
    timezones: [],
    languages: []
  },
  ...appStateUser,
  ...appAuditTrailInitialState,
  ...appStateSite,
  ...appStateRole,
  // ...appCustomersInitialState,
  // ...appStateGroup,
  // ...appStateRole,
  // ...appStateOrganization,
  // ...appStateRecord,
  // datasetViews: datasetViewInitialState,
};

/**
 * getting synchronously state
 * by passing store as argument
 */
export const getState = (store: Store<AppState>): AppState => {
  let s: AppState;
  store.pipe(
    select(state => state),
    take(1)
  ).subscribe(o => s = o);
  return s;
};
export const selectControlFeature = createFeatureSelector<ControlState>('control');
export const selectCurrentControl = createSelector(
  selectControlFeature,
  (state: ControlState) => state.current.control
);

<phar-base-control-template
  controlName="Single Select"
  [control]="control()"
  [question]="question"
  [builderMode]="builderMode"
  [isDraggable]="isDraggable"
  [isCommentsVisible]="isCommentsVisible"
  [canManageControl]="canManageControl"
  [showActionButtons]="(isControlActive$ | async) || (isControlHovered$ | async)"
  [highlightBackground]="isControlHighlighted$ | async"
  [hasConditionalRules]="hasConditionalRules()"
  (actionClick)="actionsHandler($event, control())">
  <div class="d-flex flex-column">
    @for (value of control().values; track $index; let first = $first) {
      <div
        [class.mt-2]="first"
        [class.form-check-inline]="(control().settings && control().settings.horizontal)"
        class="form-check d-flex align-items-center">
        <input class="form-check-input no-pointer-events" type="radio" [formControl]="selectedValue"
               [value]="value.value"
        >
        <label class="form-check-label ms-2" [innerHTML]="value.label | safeHtml">
        </label>
      </div>
    }
  </div>
</phar-base-control-template>

import { Injectable } from '@angular/core';

@Injectable()
export class PharDragService {

  dragData: any;

  constructor() {
  }

  setDragData(dragData): void {
    this.dragData = dragData;
  }
}

export enum FormElementsEnum {
  // TextBlock = 'Textblock',
  RadioButtons = 'RadioButtonList',
  CheckBox = 'Checkbox',
  DropDownList = 'DropDownList',
  RepeatableGroup = 'RepeatableGroup',
  Signature = 'Signature',
  FileUpload = 'FileUpload',
  ImageUpload = 'ImageUpload',
  FormButton = 'FormButton',
  Grid = 'Grid',
  BarChart = 'BarChart',
  LineChart = 'LineChart',
  PieChart = 'PieChart',
  Float = 'Float',
  Script = 'Script',
  BoxedInput = 'BoxedInput',
  TableElement = 'TableElement',
  DataTable = 'DataTable',
  GridRadio = 'GridRadio',
  RadioImagesList = 'RadioImageList',
  ImageTagging = 'ImageTagging',
  Slider = 'Slider',
  ImageHighlighting = 'ImageHighlighting',
  UserSelect = 'UserSelect',
  DropZone = 'dropzone',
  HiddenInput = 'HiddenInput',
  Text = 'Text',
  Header = 'Header',
  Footer = 'Footer',
  QuestionGroup = 'QuestionGroup',
  VisualAnalogScale = 'VAS',
  VerbalRatingScale = 'VRS',
  NumericRatingScale = 'NRS',
  MultiSelect = 'MC',
  MultiSelectQuantity = 'MCQ',
  Boolean = 'BS',
  SingleSelect = 'SING',
  TextBlock = 'TB',
  GeneralBoolean = 'GB',
  Image = 'IMAGE',
  TextInput = 'CHAR',
  Number = 'NUM',
  DatePicker = 'DATE',
  TimePicker = 'TIME',
}

export enum ElementsEnum {
  Textblock = FormElementsEnum.TextBlock,
  FormButton = FormElementsEnum.FormButton,
  Grid = FormElementsEnum.Grid,
  BarChart = FormElementsEnum.BarChart,
  LineChart = FormElementsEnum.LineChart,
  PieChart = FormElementsEnum.PieChart,
  Script = FormElementsEnum.Script,
  GridRadio = FormElementsEnum.GridRadio,
  DropZone = FormElementsEnum.DropZone,
  // TableElement = FormElementsEnum.TableElement
}

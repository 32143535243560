<div class="comments-section_comment">
  <div class="d-flex align-center overflow-hidden">
    <phar-user-avatar class="comments-section_comment_user-avatar" [user]="comment.user"
                      [alternativeField]="'userName'"></phar-user-avatar>

    <div class="comments-section_comment_user">
      <div
        class="comments-section_comment_user_name"
        [pharTooltip]="comment.user.userName">
        {{ comment.user.userName }}
      </div>
      <div class="comments-section_comment_user_date">{{ comment.createdAt | date }}</div>
    </div>

    @if ((comment.user.userId === user.userId) && !comment.isResolved) {
      <button
        class="ms-auto"
        #trigger
        [matMenuTriggerFor]="actionsMenu"
        pharNoPropagation
        mat-icon-button>
        <mat-icon class="more">more_vert</mat-icon>
      </button>
    }
  </div>

  <div class="comments-section_comment_text" [innerHTML]="comment.comment | pharEmailMarkedText: user.userName">
  </div>

  @if (!comment.isResolved) {
    <div class="comments-section_comment_resolve" (click)="handleResolveComment(comment)">Resolve</div>
  }
</div>

<mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
  <button
    mat-menu-item
    pharNoPropagation
    (click)="handleEditComment(comment)"
    class="button-with-icon"
  >
    <mat-icon svgIcon="edit"></mat-icon>
    <span>Edit</span>
  </button>

  <button
    class="button-with-icon"
    mat-menu-item
    (click)="handleDeleteComment(comment)"
  >
    <mat-icon svgIcon="trash"></mat-icon>
    <span>Delete</span>
  </button>
</mat-menu>

<mat-divider></mat-divider>

export enum FilterType {
  String = 'string',
  Number = 'number',
  Date = 'date',
  DateRange = 'dateRange',
  Dropdown = 'dropdown',
  AutoCompleter = 'autoCompleter',
  Boolean = 'boolean',
  RadioButtons = 'radioButtons',
}

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/models/app.state';
import { PharBaseControlModelComponent } from '../phar-base-control/phar-base-control-model.component';

@Component({
  selector: 'phar-time-picker',
  templateUrl: 'time-picker.component.html',
  styleUrls: ['time-picker.component.scss'],
})
export class TimePickerComponent extends PharBaseControlModelComponent {

  constructor(
    store: Store<AppState>
  ) {
    super(store);
  }

}

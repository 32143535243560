import { Component } from '@angular/core';

@Component({
  selector: 'phar-base-card',
  templateUrl: './base-card.component.html',
  styleUrls: ['./base-card.component.scss']
})
export class BaseCardComponent {

  constructor() {
  }

}

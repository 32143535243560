/* src/app/shared/html-input/html-input.component.scss */
::ng-deep .note-editor.note-airframe .note-editing-area {
  overflow: visible;
}
::ng-deep .note-editor.note-airframe .note-editing-area .note-placeholder {
  padding: 0;
}
::ng-deep .note-editor.note-airframe .note-editing-area .note-editable {
  background-color: inherit;
  overflow: visible;
}
::ng-deep .note-editor.note-airframe .note-editing-area .note-editable p {
  margin-bottom: 0;
}
::ng-deep .note-editor.note-airframe .note-popover {
  left: 0 !important;
}
::ng-deep .note-editor.note-airframe .note-popover .note-popover-arrow {
  display: none;
}
::ng-deep .note-editor.note-airframe .note-popover .note-dropdown-menu {
  margin-left: 0;
  min-width: initial;
}
/*# sourceMappingURL=html-input.component.css.map */

import { FormModel } from '../form.model';
import { FormRenderingMode } from '../_form-settings/form-rendering-mode.enum';
import { DefaultPrintSettings } from '../_form-settings/default-print-settings';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

export const FORM_INITIAL_STATE = (): FormModel => cloneDeep({
  id: null,
  userIdCreated: null,
  userIdUpdated: null,
  organizationId: null,
  shared: [],
  name: '',
  description: '',
  datasets: [],
  datasetId: null,
  questions: [],
  type: 1,
  questionsPopulated: {},
  version: '',
  languageId: null,
  formStatus: null,
  isLocked: null,
  isArchived: false,
  isAmended: false,
  isMandatoryToSign: false,
  settings: {
    buttons: {
      exportPdf: true,
      nextPage: true,
      prevPage: true,
      save: true,
      status: true
    },
    showHeader: false,
    showFooter: false,
    renderingMode: FormRenderingMode.PAGE_BY_PAGE,
    printSettings: {
      landscape: DefaultPrintSettings.landscape,
      format: DefaultPrintSettings.format,
      margins: {
        top: DefaultPrintSettings.marginTop,
        right: DefaultPrintSettings.marginRight,
        bottom: DefaultPrintSettings.marginBottom,
        left: DefaultPrintSettings.marginLeft
      },
      marginFormat: DefaultPrintSettings.marginFormat
    }
  },
  body: {
    pages: [],
    header: {
      text: '',
      isSiteLevel: false,
    },
    footer: {
      text: '',
      isSiteLevel: false,
    },
  },
  updatedAt: '',
  createdAt: '',
  releaseDate: '',
});

export interface CurrentFormManage {
  form: FormModel;
  formSnapshot: FormModel;
  error: boolean;
  errors: string[];
  pending: boolean;
  success: boolean;
  dispatched: boolean;
  pendingChanges: boolean;

}

export interface FormState {
  list: FormModel[];
  current: CurrentFormManage;
}

export interface AppStateForm {
  form: FormState;
}

export const appStateForm: AppStateForm = {
  form: {
    list: [],
    current: {
      form: FORM_INITIAL_STATE(),
      formSnapshot: FORM_INITIAL_STATE(),
      error: false,
      errors: [],
      pending: false,
      success: false,
      dispatched: false,
      pendingChanges: false,
    }
  },
};

export const selectFormState = createFeatureSelector<FormState>('form');
export const selectFormStateFormList = createSelector(selectFormState, (state) => state.list);
export const selectCurrentFormState = createSelector(selectFormState, (state) => state.current);
export const selectCurrentFormStateForm = createSelector(selectCurrentFormState, (state) => state.form);
export const selectCurrentFormStateFormSnapshot = createSelector(selectCurrentFormState, (state) => state.formSnapshot);
export const selectCurrentFormStateFormPopulatedQuestions = createSelector(selectCurrentFormStateForm, (state) => state.questionsPopulated);
export const selectCurrentFormStatePendingChanges = createSelector(selectCurrentFormState, (state) => state.pendingChanges);



import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../../store/models/app.state";
import { PharBaseControlModelComponent } from "../phar-base-control/phar-base-control-model.component";
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'phar-single-select',
  templateUrl: 'single-select.component.html',
  styleUrls: ['single-select.component.scss'],
})
export class SingleSelectComponent extends PharBaseControlModelComponent implements OnInit {
  selectedValue = new UntypedFormControl();

  constructor(
    store: Store<AppState>
  ) {
    super(store);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}

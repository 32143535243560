import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProjectEventModel } from "../project-event.model";
import { PROJECT_INITIAL_STATE } from '../../project/store/project.state';
import { IEventNotificationModel } from '../event-notifications';
import { DateTimeUnitEnum } from '../enums/datetime-unit.enum';
import { EventAvailabilityTypeEnum } from '../enums/event-availability-type.enum';
import { ElementTypeEnum } from '../enums/element-type.enum';

export interface CurrentEventManage {
  projectEvent: ProjectEventModel;
  projectEventSnapshot: ProjectEventModel;
  error: boolean;
  errors: string[];
  pending: boolean;
  success: boolean;
  dispatched: boolean;
  pendingChanges: boolean;
}

export interface EventState {
  listProjectEvents: ProjectEventModel[];
  listEventNotifications: IEventNotificationModel[];
  eventsCommentsCounters: {},
  current: CurrentEventManage;
}

export interface AppStateEvent {
  event: EventState;
}

export const CURRENT_PROJECT_EVENT_INITIAL_STATE: ProjectEventModel = {
  id: null,
  projectId: null,
  eventId: null,
  isBaseline: false,
  orderPriority: null,
  isDeleted: null,
  dateCreated: '',
  dateUpdated: '',
  userIdCreated: null,
  userIdUpdated: null,
  roleId: null,
  organizationId: null,
  event: {
    id: null,
    organizationId: null,
    parentGroupEventID: null,
    eventName: '',
    elementType: ElementTypeEnum.ScheduledEvent,
    eventType: 1,
    eventAvailability: EventAvailabilityTypeEnum.DuringEnrollment,
    start: null,
    unit: DateTimeUnitEnum.Day,
    hasSpecificTime: false,
    specificTime: null,
    beforeDeviation: null,
    beforeDeviationUnit: 2,
    afterDeviation: null,
    afterDeviationUnit: 2,
    isRepeatable: false,
    repeatEvery: null,
    repeatEveryUnit: 2,
    endsAfter: null,
    endsAfterUnit: 2,
    userIdCreated: null,
    userIdUpdated: null,
    dateCreated: '',
    dateUpdated: '',
    repeatOnWeekDay: null,
    endsNever: false,
    endsOn: null,
    endsAfterOccurrenciesCount: null,
    repeatTitles: '',
  },
  project: { ...PROJECT_INITIAL_STATE }
};

export const appStateEvent: AppStateEvent = {
  event: {
    listProjectEvents: [],
    listEventNotifications: [],
    eventsCommentsCounters: {},
    current: {
      projectEvent: {
        ...CURRENT_PROJECT_EVENT_INITIAL_STATE,
      },
      projectEventSnapshot: {
        ...CURRENT_PROJECT_EVENT_INITIAL_STATE,
      },
      error: false,
      errors: [],
      pending: false,
      success: false,
      dispatched: false,
      pendingChanges: false,
    }
  }
};

export const selectEventState = createFeatureSelector<EventState>('event');
export const selectCurrentEventState = createSelector(selectEventState, (state) => state.current);
export const selectCurrentEventStateForm = createSelector(selectCurrentEventState, (state) => state.projectEvent);
export const selectCurrentEventProjectEvent = createSelector(selectCurrentEventStateForm, (state: ProjectEventModel) => state.event);
export const selectCurrentEventStatePendingChanges = createSelector(selectCurrentEventState, (state) => state.pendingChanges);
export const selectListProjectEventsState = createSelector(selectEventState, (state) => state.listProjectEvents);
export const selectProjectEventFromListByEventId = (props: { eventId: number }) =>
  createSelector(selectListProjectEventsState, (projectEvents) => {
    return projectEvents.find((projectEvent) => projectEvent.eventId === props.eventId);
  });

export const selectListEventNotificationsState = createSelector(selectEventState, (state) => state.listEventNotifications);


// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  auth0: {
    domain: 'dev-bgo.eu.auth0.com',
    clientId: 'XwchtneYxLtKj4OifFC8YvDvROnEau9p',
    authorizationParams: {
      audience: 'XwchtneYxLtKj4OifFC8YvDvROnEau9p',
      redirect_uri: `${window.origin}/auth-callback`,
    },
    errorPath: '/callback',
  },
  api: {
    serverUrl: 'manager.designer-dev.pharmatrail.io',
  },
  protocol: 'https',
  production: false,
};

<div
  class="list-card position-relative list-card_cursor-pointer"
  [class]="'card-' + card?.card?.color"
  [ngStyle]="cardStyle"
  (click)="cardClick()">
  <div class="list-card_header d-flex justify-content-between align-items-center">
    <div class="list-card_header_info-left">
      <!--      @if (card?.card?.type === 'customerProject') {-->
      <!--        <span [class.crop]="card?.card?.name?.length > cardTitleLength/2"-->
      <!--              [matTooltipDisabled]="card?.card?.name?.length < cardTitleLength"-->
      <!--              [matTooltip]="card?.card?.name"-->
      <!--              [ngStyle]="cardPrimaryColor"-->
      <!--              class="list-card_title"-->
      <!--              matTooltipPosition="above">-->
      <!--        <span class="three-dots"-->
      <!--        >{{ card.card.name | shorten : cardTitleLength : '...' }}</span>-->
      <!--      </span>-->
      <!--        <span [ngStyle]="cardSecondaryColor"-->
      <!--              class="d-block list-card_date">-->
      <!--          {{ card?.original?.agentFirstName }} {{ card?.original?.agentLastName }}-->
      <!--        </span>-->

      <!--      } @else if (card.card.type === 'dataset') {-->
      <!--        <b [class.crop]="card?.original?.displayName?.length > cardTitleLength/2"-->
      <!--           [matTooltipDisabled]="card?.original?.displayName?.length < cardTitleLength"-->
      <!--           [matTooltip]="card?.original?.displayName"-->
      <!--           [ngStyle]="cardPrimaryColor"-->
      <!--           class="list-card_title"-->
      <!--           matTooltipPosition="above">-->
      <!--        <span class="three-dots"-->
      <!--        >{{ card?.original?.displayName | shorten : cardTitleLength : '...' }}</span>-->
      <!--        </b>-->

      <!--      } @else if (card?.card?.type === 'assignment') {-->
      <!--        <span [class.crop]="card?.original?.displayName?.length > cardTitleLength/2"-->
      <!--              [matTooltipDisabled]="!card?.card?.name || card?.card?.name?.length < cardTitleLength"-->
      <!--              [matTooltip]="card?.card?.name"-->
      <!--              [ngStyle]="cardPrimaryColor"-->
      <!--              class="list-card_title"-->
      <!--              matTooltipPosition="above"-->
      <!--        >-->
      <!--          <span class="three-dots">{{ card?.card?.name| shorten : cardTitleLength : '...' }}</span>-->
      <!--        </span>-->
      <!--      } @else {-->

      <div
        #cardTitle
        class="list-card_title"
        pharTooltipPosition="above"
        [pharTooltip]="card?.card?.name"
        [pharTooltipDisabled]="cardTitle.scrollWidth <= cardTitle.clientWidth"
        [ngStyle]="cardPrimaryColor"
      >
        @if (card.card.nameTemplate) {
          <ng-template
            [ngTemplateOutletContext]="{$implicit: card.original}"
            [ngTemplateOutlet]="card.card.nameTemplate"
          ></ng-template>
        } @else {
          <span class="three-dots text-truncate"> {{ card?.card?.name }} </span>
        }
        <!--          @if (card?.card?.type === 'group' && card?.original?.isDefault) {-->
        <!--            <span class="list-card_default-group">(default)</span>-->
        <!--          }-->
      </div>

      <span
        [ngStyle]="cardSecondaryColor"
        class="list-card_subtitle">
                 @if (card.card.subtitleTemplate) {
                   <ng-template
                     [ngTemplateOutletContext]="{$implicit: card.original}"
                     [ngTemplateOutlet]="card.card.subtitleTemplate"
                   ></ng-template>
                 } @else {
                   {{ card?.card?.subtitle || '' }}
                 }
        </span>
      <!--      }-->

    </div>
    <div class="list-card_header_info-right">
      <ng-template
        [ngIfElse]="defaultMoreOptionTemplate"
        [ngIf]="moreOptions"
        [ngTemplateOutletContext]="{$implicit: card}"
        [ngTemplateOutlet]="moreOptions">
      </ng-template>
    </div>
    <ng-template #defaultMoreOptionTemplate>
<!--      <ng-template [ngxPermissionsOnly]="[deletePermission]">-->
        @if (!card?.original?.isDefault && card?.card?.type !== 'customerProject') {
          <button (click)="$event.stopImmediatePropagation()"
                  [matMenuTriggerFor]="cardMenu"
                  [ngStyle]="cardSecondaryColor"
                  class="ms-auto list-card_more"
                  mat-icon-button
                  pharTooltip="More">
            <mat-icon>more_vert</mat-icon>
            <mat-menu
              #cardMenu="matMenu"
              [pharTooltip]="'Delete ' + card?.card?.type"
              [overlapTrigger]="false"
              pharTooltipPosition="above">
              <button (click)="openCard(card)" mat-menu-item>
                {{ card.card.isLocked ? 'Preview' : 'Edit' }}
              </button>

              <button (click)="deleteCard($event, card)" mat-menu-item>
                <span>Delete </span>
              </button>
            </mat-menu>
          </button>

        }
<!--      </ng-template>-->
    </ng-template>

  </div>

  <div class="list-card_content">
    @if (card.card.status) {
      <phar-status-badge
        class="list-card_form-status-badge"
        [status]="card.card.status"
        [type]="card?.card?.type">
      </phar-status-badge>
    }

    <div class="d-flex">
      @if (card.original?.formDuplicateId) {
        <mat-icon
          class="mx-1 list-form-name-icon"
          svgIcon="duplicate">
        </mat-icon>
      }
    </div>
  </div>




  <!--  <ng-template #cardButtonTemplate>-->
  <!--    <ng-template-->
  <!--      [ngIfElse]="noCardButtonTemplate"-->
  <!--      [ngIf]="buttonTemplate"-->
  <!--      [ngTemplateOutletContext]="{$implicit: card}"-->
  <!--      [ngTemplateOutlet]="buttonTemplate">-->
  <!--    </ng-template>-->
  <!--  </ng-template>-->
  <!-- <ng-template #noCardButtonTemplate>
    <button mat-button
            class="list-card_view-button cursor-pointer"
            [class] = "'button-' + card.card.color"
            (click)="openCard(card)"
            (mouseenter)="mouseOver($event)"
            (mouseleave)="mouseLeave($event)"
            [ngStyle]="cardBtnStyles">
      <span>View </span>

      <ng-container *ngIf="card?.card?.type !== 'form'; else formTypesTitle">
        <ng-container *ngIf="card?.card?.type === 'customerProject'; else defaultTitle">
          <span>PROJECT</span>
        </ng-container>
        <ng-template #defaultTitle>
          <ng-container *ngIf="card?.original?.isTemplate; else defaultCardType"> Template</ng-container>
        </ng-template>
        <ng-template #defaultCardType>
          <span>{{card?.card?.type}}</span>
        </ng-template>
      </ng-container>
    </button>
  </ng-template> -->

  <div class="list-card_footer d-flex align-items-center">
    <div class="list-card_footer_info me-auto max-width-50">
      <!-- Check for template first, if not use noCardFooterInfoLeft -->
      @if (footerInfoLeft) {
        <ng-template [ngTemplateOutletContext]="{$implicit: card}"
                     [ngTemplateOutlet]="footerInfoLeft"></ng-template>
      } @else {
        <ng-template [ngTemplateOutlet]="noCardFooterInfoLeft"></ng-template>
      }

      <ng-template #noCardFooterInfoLeft>
        @if (card?.card?.type === 'group' || card?.card?.type === 'role' || card?.card?.type === 'assignment') {
          <mfm-users-avatars-list

            [users]="card?.original?.usersDetails">
          </mfm-users-avatars-list>
        }

        @if (card?.card?.type === 'project' && card?.original?.isTemplate) {
          <span
            [ngStyle]="cardPrimaryColor"
            class="list-card_footer_info_form-type">
          <span>Template</span>
        </span>
        }

        @if (card?.card?.type === 'form' || card?.card?.type === 'project') {
          <span
            [ngStyle]="cardSecondaryColor"
            class="list-card_footer_info_form-type">
          <span>V{{ card?.original?.version || card?.original?.projectVersion || '0.0.1' }}</span>
        </span>
        }
      </ng-template>

    </div>
    <div [ngStyle]="cardSecondaryColor" class="list-card_footer_info d-inline-block ms-auto max-width-50">
      <!-- Check for template first, if not use noCardFooterInfoRight template-->

      @if (footerInfoRight) {
        <ng-template [ngTemplateOutletContext]="{$implicit: card}"
                     [ngTemplateOutlet]="footerInfoRight"></ng-template>
      } @else {
        <ng-template
          [ngTemplateOutlet]="noCardFooterInfoRight"></ng-template>
      }
      <ng-template #noCardFooterInfoRight >
        {{card.original.type | pharFormType}}
<!--        @if (card?.card?.type === 'project' || card?.card?.type === 'dataset') {-->
<!--          <span>-->
<!--            @if (card?.original?.formCount > 0) {-->
<!--              <span>{{ card?.original?.formCount }}</span>-->
<!--            } @else {-->
<!--              <span>No</span>-->
<!--            }-->
<!--            Forms-->
<!--          </span>-->
<!--        }-->

        <!--        @if (card?.card?.type === 'assignment' && card?.original?.assignmentType === assignTypeEnum.GroupAssign) {-->
        <!--          <span>-->
        <!--              <span-->
        <!--                [matTooltip]="card?.original?.securityGroupType === groupTypeEnum.Anonymous ? 'Anonymous Group' : 'Authenticated Group'"-->
        <!--                class="three-dots-no-wrap"-->
        <!--                matTooltipPosition="above">-->
        <!--              {{ card.original.securityGroupName }}-->
        <!--            </span>-->
        <!--          -->
        <!--      </span>-->

        <!--        }-->
      </ng-template>

    </div>
  </div>

</div>


<!--<ng-template #formTypesTitle>-->
<!--  <ng-container [ngSwitch]="card?.original?.type">-->
<!--    <span *ngSwitchCase="formTypesEnum.Survey">Survey</span>-->
<!--    <span *ngSwitchCase="formTypesEnum.Report">Report</span>-->
<!--    <span *ngSwitchDefault>Form</span>-->
<!--  </ng-container>-->
<!--</ng-template>-->

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'pharEmailMarkedText',
})

export class EmailMarkedTextPipe implements PipeTransform {

  MENTION_REGEX: RegExp = new RegExp('(^|\\s)@([^\\s]+)', 'g')

  constructor(private sanitized: DomSanitizer) {
  }

  transform(comment: string, currentUserName: string): SafeHtml {
    const replaced = comment.replace(this.MENTION_REGEX, (match, group1, group2) => {
      const userName = group2;
      const isCurrentUser = currentUserName === userName;

      if (isCurrentUser) {
        return group1 + '<span class="email-mention email-mention_current-user">' + userName + '</span>';
      } else {
        return group1 + '<span class="email-mention">' + userName + '</span>';
      }
    });

    return this.sanitized.bypassSecurityTrustHtml(replaced);

  }
}

<div pharNoPropagation class="h-100 d-flex flex-column">
  @if (showCloseButton) {
    <button class="close-icon" mat-icon-button (click)="requestClose.emit()">
      <mat-icon class="button-block_small-icon" svgIcon="close"></mat-icon>
    </button>
  }
  <!--<div-->
  <!--  class="drag-dialog-block"-->
  <!--  cdkDrag-->
  <!--  cdkDragRootElement=".cdk-overlay-pane"-->
  <!--  cdkDragHandle>-->
  <!--</div>-->

  <h6 class="text-center my-3">
    Comments
  </h6>
  <div class="comments-section d-flex flex-column" #commentsSection (scroll)="changeDetector.markForCheck()"
       [class.justify-content-center]="!comments().length">
    @for (comment of comments(); track comment.id) {
      <phar-entity-comment
        [comment]="comment"
        [user]="currentUser()"
        (editComment)="onEditCommentClick($event)"
        (deleteComment)="deleteComment($event)"
        (resolveComment)="markCommentAsResolved($event)"
      />
    }

    @if (isLoading()) {
      <div class="text-center my-2">Loading comments...</div>
    }

    @if (!isLoading() && !comments().length) {
      <div class="text-center">No comments</div>
    }
  </div>

  <div class="comments-input-container-anchor">
    @if (autocompleteMentionUser !== null) {
      <div #commentsAutocomplete class="comments-autocomplete-helper">
        @for (user of autocompleteMentionUser.listOfUsers; track user.userName; let index = $index) {
          <div
            class="comments-autocomplete-helper_list-element"
            [ngClass]="{ 'active':  autocompleteMentionUser.activeElementIndex === index}"
            (click)="autocompleteMention(user)"
            (mouseenter)="setActiveAutocompleteUser(index)">
            <phar-user-avatar class="comments-autocomplete-helper_list-element_avatar" [user]="user"
                              [alternativeField]="'userName'"></phar-user-avatar>

            <div>
              <div class="comments-autocomplete-helper_list-element_name">
                {{ user.firstName }} {{ user.lastName }}
              </div>

              <div class="comments-autocomplete-helper_list-element_email">
                {{ user.userName }}
              </div>
            </div>
          </div>
        }
      </div>
    }

    @if (commentsSection.clientHeight < commentsSection.scrollHeight
    && commentsSection.scrollTop + commentsSection.clientHeight < commentsSection.scrollHeight - 20) {
      <button
        class="comments-scroll-to-bottom"
        mat-icon-button
        matTooltipPosition="above"
        [matTooltip]="'Scroll to the bottom'"
        (click)="scrollToCommentsBottom()">
        <mat-icon class="button-block_small-icon" svgIcon="arrow-down"></mat-icon>
      </button>
    }
  </div>

  <div class="comments-input-container">
    <mat-form-field class="comments-input-form-field">
      @if (editableComment) {
        <div class="editing-wrapper button-block_parent">
          <div class="editing-content">
            <label class="editing-content_title"><b>Editing</b></label>
            <div class="editing-content_subtitle">{{ editableComment.comment }}</div>
          </div>

          <button
            class="button-block_button"
            color="primary"
            mat-icon-button
            (click)="resetComment()">
            <mat-icon class="button-block_small-icon" svgIcon="close"></mat-icon>
          </button>
        </div>
      }

      <textarea
        #commentInput
        id="comment-input"
        class="text-area-scrollable pe-3"
        matInput
        pharInputTrim
        cdkTextareaAutosize
        [cdkAutosizeMinRows]="1"
        [cdkAutosizeMaxRows]="3"
        [formControl]="commentFormControl"
        [placeholder]="editableComment ? 'Edit comment' : comments()?.length ? 'Reply' : 'Add comment'">
      </textarea>
    </mat-form-field>

    <button
      class="comments-input-container_send-button"
      mat-icon-button
      [disabled]="isViewOnly"
      (click)="sendComment()">
      @if (editableComment) {
        <mat-icon svgIcon="check"></mat-icon>
      } @else {
        <mat-icon svgIcon="send"></mat-icon>
      }
    </button>
  </div>
</div>

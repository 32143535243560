import { createAction, props } from '@ngrx/store';
import { FormModel, FormStatusEnum } from '../form.model';
import { QuestionModel } from '../../question/question.model';

const PREFIX = '[FORM]'

export enum ActionTypes {
  LOADFORMLIST = `${PREFIX} LOADFORMLIST`,
  LOAD_FORM_LIST_BY_STATUSES = `${PREFIX} LOAD_FORM_LIST_BY_STATUSES`,
  LOAD_FORM_LIST_WITH_STUDIES = `${PREFIX} LOAD FORMS INCLUDES STUDIES`,
  LOADFORMLIST_PROJECT_CONTEXT = `${PREFIX} LOADFORMLIST_PROJECT_CONTEXT`,
  LOADFORMLIST_BY_DATASET_ID = `${PREFIX} LOADFORMLIST_BY_DATASET_ID`,
  UPDATEFORMLIST = `${PREFIX} UPDATEFORMLIST`,
  REMOVEFORM = `${PREFIX} REMOVEFORM`,
  RESET_CURRENT_FORM = `${PREFIX} RESET_CURRENT_FORM`,
  REFRESH_CURRENT_FORM = `${PREFIX} REFRESH_CURRENT_FORM`,
  SEARCH_FORM_BY_ID = `${PREFIX} SEARCH_FORM_BY_ID`,
  POPULATE_CURRENT_FORM = `${PREFIX} POPULATE_CURRENT_FORM`,
  POPULATE_CURRENT_FORM_SNAPSHOT = `${PREFIX} POPULATE_CURRENT_FORM_SNAPSHOT`,
  UPDATE_FORM_FIELD = `${PREFIX} UPDATE_FORM_FIELD`,
  CREATE_FORM = `${PREFIX} CREATE_FORM`,
  UPDATE_FORM = `${PREFIX} UPDATE_FORM`,
  PENDING_CREATE_UPDATE_FORM = `${PREFIX} PENDING_CREATE_UPDATE_FORM`,
  DISPACTHED_CREATE_UPDATE_FORM = `${PREFIX} DISPACTHED_CREATE_UPDATE_FORM`,
  SUCCESS_CREATE_UPDATE_FORM = `${PREFIX} SUCCESS_CREATE_UPDATE_FORM`,
  UPDATE_FORM_IN_LIST = `${PREFIX} UPDATE_FORM_IN_LIST`,
  FORM_ADD_QUESTION = `${PREFIX} FORM_ADD_QUESTION`,
  FORM_UPDATE_QUESTION = `${PREFIX} FORM_UPDATE_QUESTION`,
  FORM_REMOVE_QUESTION = `${PREFIX} FORM_REMOVE_QUESTION`,
  CHECK_CURRENT_FORM_FOR_PENDING_CHANGES = `[FORM] CHECK_CURRENT_FORM_FOR_PENDING_CHANGES`,
  DUPLICATE_FORM = `[FORM] DUPLICATE_FORM`,
  DELETEFORM = `${PREFIX} DELETEFORM`,
}

export const loadFormList = createAction(ActionTypes.LOADFORMLIST);
export const loadFormListByStatuses = createAction(ActionTypes.LOAD_FORM_LIST_BY_STATUSES, props<{
  statuses: FormStatusEnum[]
}>());

export const loadFormListWithStudies = createAction(ActionTypes.LOAD_FORM_LIST_WITH_STUDIES);
export const loadProjectContextFormList = createAction(
  ActionTypes.LOADFORMLIST_PROJECT_CONTEXT,
  props<{ ids: number[] }>()
);
export const loadFormListByDatasetId = createAction(ActionTypes.LOADFORMLIST_BY_DATASET_ID, props<{ id: number }>());
export const updateFormList = createAction(ActionTypes.UPDATEFORMLIST, props<{ formList: FormModel[] }>());
export const deleteForm = createAction(ActionTypes.DELETEFORM, props<{ id: number }>());
export const duplicateForm = createAction(ActionTypes.DUPLICATE_FORM, props<{ id: number }>());
export const removeForm = createAction(ActionTypes.REMOVEFORM, props<{ id: number }>());
export const resetCurrentForm = createAction(ActionTypes.RESET_CURRENT_FORM, props<{ formType: number }>());
export const refreshCurrentForm = createAction(ActionTypes.REFRESH_CURRENT_FORM);
export const searchFormById = createAction(ActionTypes.SEARCH_FORM_BY_ID, props<{ id: number }>());
export const populateCurrentForm = createAction(ActionTypes.POPULATE_CURRENT_FORM, props<{ form: FormModel }>());
export const populateCurrentFormSnapshot = createAction(ActionTypes.POPULATE_CURRENT_FORM_SNAPSHOT, props<{
  form: FormModel
}>());
export const updateFormField = createAction(ActionTypes.UPDATE_FORM_FIELD, props<{ field: string, value: any }>());
export const checkCurrentFormForPendingChanges = createAction(ActionTypes.CHECK_CURRENT_FORM_FOR_PENDING_CHANGES, props<{
  changes: boolean
}>());
export const createForm = createAction(ActionTypes.CREATE_FORM, props<{ form: FormModel }>());
export const updateForm = createAction(ActionTypes.UPDATE_FORM, props<{ form: FormModel }>());
export const pendingCreateUpdateForm = createAction(ActionTypes.PENDING_CREATE_UPDATE_FORM, props<{
  pending: boolean
}>());
export const dispatchedCreateUpdateForm = createAction(ActionTypes.DISPACTHED_CREATE_UPDATE_FORM, props<{
  dispatched: boolean
}>());
export const successCreateUpdateForm = createAction(ActionTypes.SUCCESS_CREATE_UPDATE_FORM, props<{
  success: boolean
}>());

export const updateFormInList = createAction(ActionTypes.UPDATE_FORM_IN_LIST, props<{ form: FormModel }>());
export const formAddQuestion = createAction(ActionTypes.FORM_ADD_QUESTION, props<{ question: QuestionModel }>());
export const formUpdateQuestion = createAction(ActionTypes.FORM_UPDATE_QUESTION, props<{ question: QuestionModel }>());
export const formRemoveQuestion = createAction(ActionTypes.FORM_REMOVE_QUESTION, props<{ questionId: string }>());



import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  ElementRef,
  Host,
  HostBinding, Inject,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges, TemplateRef,
  ViewChild
} from '@angular/core';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, NgControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
  MatOption
} from '@angular/material/autocomplete';
import { isArray, isObject } from 'lodash-es';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { BaseComponent } from '../base.class';

@Component({
  selector: 'phar-autocompleter',
  templateUrl: './phar-autocompleter.component.html',
  styleUrls: ['./phar-autocompleter.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: PharAutocompleterComponent
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,

  imports: [
    ReactiveFormsModule,
    MatAutocompleteTrigger,
    NgClass,
    MatAutocomplete,
    MatOption,
    AsyncPipe,
    MatSelect,
    MatInput,
    NgTemplateOutlet,
  ]
})


export class PharAutocompleterComponent extends BaseComponent implements MatFormFieldControl<PharAutocompleterComponent>,
  ControlValueAccessor, OnDestroy, OnInit, DoCheck, OnChanges, AfterViewInit {
  constructor(
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Inject(MAT_FORM_FIELD) @Host() private formField: MatFormField,
  ) {
    super();
    this.customInput = new UntypedFormControl('');
    focusMonitor.monitor(elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  // tslint:disable-next-line:variable-name
  static ngAcceptInputType_disabled: boolean | string | null | undefined;
  // tslint:disable-next-line:variable-name
  static ngAcceptInputType_required: boolean | string | null | undefined;
  static nextId = 0;
  customInput: UntypedFormControl;
  stateChanges = new Subject<void>();
  focused = false;
  errorState = false;
  controlType = 'custom-autocompleter';
  id = `custom-autocompleter-${PharAutocompleterComponent.nextId++}`;
  describedBy = this.placeholder;
  valueToPatch;
  pendingValue = false;

  get disabledItem(): boolean {
    return this.customInput.value && this.customInput.value.hasOwnProperty('enabled') && !this.customInput.value.enabled;
  }


  get empty(): boolean {
    return !this.customInput.value;
  }


  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @ViewChild(MatAutocompleteTrigger)
  private trigger: MatAutocompleteTrigger;
  @ViewChild('selectedOptionLabels') selectedOptionLabels: ElementRef<HTMLInputElement>;
  @Input() autoCompleterMinLength = 2;
  @Input() autoCompleter = false;
  @Input() showAll = true;
  @Input() noDataText = 'No data found';
  @Input({ required: true }) data;
  @Input({ required: true }) displayValue: string;
  @Input({ required: true }) filterValue: string | string[];
  @Input({ required: true }) returnValue: string;
  @Input() displayTemplate: TemplateRef<any>

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.customInput.disable({ emitEvent: false }) : this.customInput.enable({ emitEvent: false });
    this.stateChanges.next();
  }

  @Input()
  get value(): any {
    return this.customInput.value;
  }

  set value(value) {
    this.customInput.setValue(value);
    this.stateChanges.next();
  }


  public filteredData$: Observable<any[]>;

  // tslint:disable-next-line:variable-name
  private _placeholder: string;
  // tslint:disable-next-line:variable-name
  private _disabled = false;
  // tslint:disable-next-line:variable-name
  private _required = false;


  ngOnInit(): void {
    if (!this.returnValue || !this.displayValue) {
      throw new TypeError('Please provide all needed inputs to the component!!!');
    }
    if (!this.filterValue) {
      this.filterValue = this.displayValue;
    }

    this.customInput.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(100),
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      if (isObject(value)) {
        if (!(value as Object).hasOwnProperty(this.returnValue)) {
          throw new TypeError('Selected return value doesnt persist in the value object');
        }
        this.onChange(value[this.returnValue]);
      } else {
        if (this.ngControl.value) {
          this.onChange(null);
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.reAssignFilteredData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('data')) {
      this.reAssignFilteredData();
      if (this.valueToPatch !== null) {
        this.applyValueByGivenReturnValue();
      }
    }
  }

  onChange = (_: any) => {
  };
  onTouched = () => {
  };

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
    super.ngOnDestroy();
  }

  openPanel($event): void {
    $event.stopPropagation();
    $event.preventDefault();
    if (this.disabled) {
      return;
    }
    if (this.formField) {
      this.trigger.connectedTo = { elementRef: this.formField.getConnectedOverlayOrigin() };
    }
    this.trigger.openPanel();

  }

  preventPanelOpening($event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.trigger.closePanel();
  }

  reAssignFilteredData(): void {
    this.filteredData$ = this.customInput.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(100),
      startWith(''),
      filter((value) => !isObject(value)),
      map((value) => this._filter(value))
    );
  }


  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      if (this.autoCompleter) {
        // tslint:disable-next-line:no-non-null-assertion
        this.elementRef.nativeElement.querySelector('input')!.focus();
      } else {
        this.elementRef.nativeElement.focus();
      }

    }
  }

  writeValue(value): void {
    if (value !== null && value !== '' && value !== undefined) {
      this.valueToPatch = value;
      this.applyValueByGivenReturnValue();
    } else {
      this.customInput.patchValue(null, { emitEvent: false, onlySelf: true });
    }
  }


  applyValueByGivenReturnValue(): void {
    let notFound = true;
    if (this.data && this.data.length) {
      for (const item of this.data) {
        if ((item.hasOwnProperty(this.returnValue) && item[this.returnValue] !== null && item[this.returnValue] !== '') && (item[this.returnValue] === this.valueToPatch)) {
          this.customInput.patchValue(item, { emitEvent: this.pendingValue, onlySelf: false });
          if (this.pendingValue) {
            this.pendingValue = false;
          }
          notFound = false;
          break;
        }
      }
      if (notFound && this.valueToPatch && this.valueToPatch !== '') {
        // values that need to patch doesnt persist in the given data so emits null;
        this.pendingValue = true;
        this.onChange(null);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  displayFn = (el) => {
    return el ? el[this.displayValue] : '';
  };

  compareFn = (o1, o2) => {
    return o1 && o2 && o1[this.returnValue] === o2[this.returnValue];
  };


  private _filter(value): any[] {
    if (value === null || value === undefined || value === '') {
      if (!this.data) {
        return [];
      } else {
        return this.showAll ? this.data : [];
      }
    }
    if (value.length < this.autoCompleterMinLength) {
      if (!this.data) {
        return [];
      } else {
        return this.showAll ? this.data : [];
      }
    }
    value = value.toLowerCase();
    if (isArray(this.filterValue)) {
      return this.data.filter((item) => {
        return (this.filterValue as string[]).some(filterValue => {
          return item.hasOwnProperty(filterValue) && item[filterValue] !== null &&
            item[filterValue] !== '' && item[filterValue] !== undefined && item[filterValue].toLowerCase().includes(value);
        });
      });
    } else {
      return this.data.filter((option) => option[(this.filterValue as string)].toLowerCase().includes(value));
    }
  }
}

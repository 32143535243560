<phar-entity-comments
  [isLoading]="isLoading"
  [users]="usersList()"
  [currentUser]="user()"
  [comments]="comments"
  [isViewOnly]="isViewOnly"
  (commentCreated)="createComment($event)"
  (commentUpdated)="editComment($event)"
  (commentResolved)="markCommentAsResolved($event)"
  (commentDeleted)="deleteComment($event)"
/>



import { Injectable } from "@angular/core";

import { PharConfirmDialogService } from "../confirm-dialog/confirm-dialog-service.service";
import { Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";

export interface PendingChangesControl {
  hasPendingChanges$: Observable<boolean>,
  saveAndCheckPendingChanges: () => Observable<boolean>,
}

@Injectable()
export class PendingChangesGuard {
  constructor(private confirmationService: PharConfirmDialogService) {
  }

  canDeactivate(
    component: PendingChangesControl
  ): Observable<boolean> | boolean {
    const { hasPendingChanges$ } = component;

    if (
      !hasPendingChanges$
      && !(hasPendingChanges$ instanceof Observable)
      && !component.saveAndCheckPendingChanges
    ) {
      return true;
    }

    return hasPendingChanges$.pipe(
      take(1),
      switchMap((hasPendingChanges) => {
        if (!hasPendingChanges) {
          return of(true);
        }

        return this.confirmationService
          .openConfirmDialog(
            '',
            'Are you sure you want to exit without saving?',
            'Save',
            'Discard',
          ).pipe(switchMap((isSavingConfirmed) => {
            if (!isSavingConfirmed) {
              return of(true);
            }

            return component.saveAndCheckPendingChanges().pipe(map((hasPendingChanges) => !hasPendingChanges));
          }));
      }),
      take(1),
    );
  }
}

import { Component, inject, Input } from '@angular/core';
import { UserModel } from '../../user/user.model';
import { NgIf } from '@angular/common';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'phar-user-avatar',
  templateUrl: 'user-avatar.component.html',
  styleUrls: ['user-avatar.component.scss'],
  standalone: true,
  imports: [
    NgIf,
  ]
})
export class UserAvatarComponent {
  @Input({ required: true }) user: UserModel;
  @Input() alternativeField: string;
  private sanitizer = inject(DomSanitizer);

  constructor() {
  }

  getImageUrl(imageUrl: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(`url(${imageUrl})`);
  }
}

import { FormRenderingMode } from './_form-settings/form-rendering-mode.enum';
import { BaseCardSettings } from '../shared/card/card.model';
import { PageOrientationEnum } from './_form-settings/page-orientation.enum';
import { PageSizeEnum } from './_form-settings/page-size.enum';
import { MarginsFormatEnum } from './_form-settings/margins-format.enum';

export enum FormTypeEnum {
  All = 0,
  DataCapture = 1,
  Questionnaire = 2,
  Report = 3,
  EObsRO = 4,
  InformedConsent = 5,
}

export enum FormStatusEnum {
  Draft = 'Draft',
  PendingReview = 'PendingReview',
  UnderReview = 'UnderReview',
  Released = 'Released',
  Rejected = 'Rejected',
  Amended = 'Amended',
}

export type FormModel = BaseCardSettings & {
  id: number;
  organizationId: number;
  body: {
    pages: any[];
    header: any;
    footer: any;
  };
  name: string;
  description: string;
  userIdCreated: number;
  userIdUpdated: number;
  shared: string[];
  datasets: any[];
  datasetId: number;
  questions: any[];
  questionsPopulated: any;
  type: FormTypeEnum;
  version: string;
  languageId: number;
  studyId?: number;
  formStatus: FormStatusEnum;
  formDuplicateId?: number;
  formAmendId?: number;
  isLocked: boolean;
  isArchived: boolean;
  isAmended: boolean;
  isMandatoryToSign: boolean;
  settings?: {
    buttons: {
      nextPage: boolean,
      prevPage: boolean,
      save: boolean,
      status: boolean,
      exportPdf: boolean,
    },
    showHeader: boolean,
    showFooter: boolean,
    renderingMode: FormRenderingMode,
    printSettings: {
      landscape: PageOrientationEnum;
      format: PageSizeEnum;
      margins: {
        top: number;
        right: number;
        bottom: number;
        left: number;
      };
      marginFormat: MarginsFormatEnum
    }
  };
  createdAt: string;
  updatedAt: string;
  releaseDate: string;
  projects?: {id: number, name: string}[];
};

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { QuestionModel } from '../question.model';

export interface CurrentQuestionManage {
  question: QuestionModel;
  questionSnapshot: QuestionModel;
  pending: boolean;
  success: boolean;
  error: boolean;
  errors: string[];
  dispatched: boolean;
  pendingChanges: boolean;
}

export interface QuestionState {
  list: {
    dataset: QuestionModel[],
    form: QuestionModel[]
  };
  current: CurrentQuestionManage;
}

export interface AppStateQuestion {
  question: QuestionState;
}

export const QUESTION_INITIAL_STATE = {
  id: null,
  datasetId: null,
  formId: null,
  title: '',
  userIdCreated: null,
  userIdUpdated: null,
  organizationId: null,
  pageIndex: null,
  layout: {
    rows: '',
    columns: '',
    areas: ''
  },
  controls: [],
  updatedAt: '',
  createdAt: '',
  info: '',
  model: {},
  dependencies: {},
  isGroup: false,
  isRepeatable: false
}

export const appStateQuestion: AppStateQuestion = {
  question: {
    list: {
      dataset: [],
      form: []
    },
    current: {
      question: { ...QUESTION_INITIAL_STATE },
      questionSnapshot: { ...QUESTION_INITIAL_STATE },
      error: false,
      errors: [],
      pending: false,
      success: false,
      dispatched: false,
      pendingChanges: false,
    }
  }
};

export const selectQuestionFeature = createFeatureSelector<QuestionState>('question');
export const selectQuestionFeatureCurrentQuestion = createSelector(selectQuestionFeature, (state: QuestionState) => state.current);
export const selectCurrentQuestion = createSelector(
  selectQuestionFeatureCurrentQuestion,
  (state: CurrentQuestionManage) => state.question
);

export const selectCurrentQuestionPendingChanges = createSelector(selectQuestionFeatureCurrentQuestion, (state) => state.pendingChanges)

import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { CardModel } from '../card/card.model';

@Component({
  selector: 'phar-cards-list',
  templateUrl: './cards-list.component.html',
  styleUrls: ['./cards-list.component.scss']
})
export class PharCardsListComponent implements OnInit {
  @Input() cards: CardModel<any>[];
  @Input() cardsType: string;
  @Input() uniqueIdentifier: string;
  @Input() moreOptions: TemplateRef<any>;
  @Input() footerInfoLeft: TemplateRef<any>;
  @Input() footerInfoRight: TemplateRef<any>;
  @Input() noDataMessage: string = 'No Data.'
  showAddButton = true;
  permissionDelete = '';

  constructor(
    public activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) {
  }

  trackFn(index, element) {
    if (this.uniqueIdentifier && element.original.hasOwnProperty(this.uniqueIdentifier)) {
      return element[this.uniqueIdentifier];
    }
    return undefined;
  }

  ngOnInit(): void {
    this.activatedRoute.data
      .subscribe((data: { isContext: boolean }) => {
        if (data.isContext) {
          this.showAddButton = false;
        }
      });

    this.setPermissionCreate();
  }

  setPermissionCreate(): void {
    switch (this.cardsType) {
      case 'project':
        this.permissionDelete = 'ProjectDelete';
        break;
      case 'dataset':
        this.permissionDelete = 'DatasetDelete';
        break;
      case 'form':
        this.permissionDelete = 'FormDelete';
        break;
      case 'group':
        this.permissionDelete = 'DeleteSecurityGroup';
        break;
      case 'role':
        this.permissionDelete = 'DeleteRole';
        break;
      case 'assignment':
        this.permissionDelete = 'AssignmentDelete';
        break;
    }
  }

  addCart(event): void {
    if (this.cardsType === 'assignment') {
      // event.stopImmediatePropagation();
      // const dialogRef = this.dialog.open(AssignFormsComponent, {
      //   width: '980px',
      //   minWidth: 980,
      //   backdropClass: 'assign-form_backdrop',
      //   panelClass: 'assign-form-dialog',
      //   data: {
      //     mode: 'add'
      //   },
      //   autoFocus: false,
      //   restoreFocus: false
      // });
      // dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      // });
    } else {
      this.router.navigate([`../new`], { relativeTo: this.activatedRoute });
    }
  }

}

<phar-base-control-template
  controlName="Image"
  [control]="control()"
  [question]="question"
  [builderMode]="builderMode"
  [isDraggable]="isDraggable"
  [isCommentsVisible]="isCommentsVisible"
  [canManageControl]="canManageControl"
  [showActionButtons]="(isControlActive$ | async) || (isControlHovered$ | async)"
  [highlightBackground]="isControlHighlighted$ | async"
  [hasConditionalRules]="hasConditionalRules()"
  (actionClick)="actionsHandler($event, control())">
  <div class="position-relative text-center">
    <ng-container *ngIf="control().settings.backgroundImage as img; else imgHolder">
      <img [src]="img" class="w-100 h-100" alt="image"/>
    </ng-container>
    <ng-template #imgHolder>
      <img src="../../../../assets/images/icon-imageupload.png" alt="image"/>
    </ng-template>
  </div>
</phar-base-control-template>

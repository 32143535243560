import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { distinctUntilChanged, shareReplay, take, tap } from 'rxjs/operators';
import { cloneDeep, filter as _filter, find as _find, findIndex as _findIndex, groupBy, maxBy } from 'lodash-es';

import { ControlModel } from '../../question/control.model';
import { PharConfirmDialogService } from '../confirm-dialog/confirm-dialog-service.service';
import { AppState, getState } from '../../store/models/app.state';
import { PharDragService } from '../drag.service';
import { FormElementsEnum } from '../../form/form-elements.enum';
import { setDragged, setDraggedHelper } from '../../store/actions/ui.actions';
import { QuestionModel } from '../../question/question.model';
import { QuestionService } from '../../question/question.service';
import { DataFieldBindingService } from '../../question/editors/bind-data-field-on-label-change/data-field-binding.service';
import { UtilsService } from '../../core/utils.service';
import { EntityCommentState } from '../entity-comments/entity-comment.state.enum';

@Component({
  selector: 'phar-question-drop-zone',
  templateUrl: './question-drop-zone.component.html',
  styleUrls: ['./question-drop-zone.component.scss']
})
export class QuestionDropZoneComponent implements OnInit, OnChanges {

  @Input() questionControls: any[] = [];

  @Input() builderMode = true;
  @Input() isCommentsVisible = true;
  @Input() isVerticalLayout = false;

  @Input() question: QuestionModel;

  @Input() inRepeatable = false;

  @Input() disableQuestionGroup = false;

  @Input() allowMultipleControls = false;

  @Input() parentDataField = '';

  @Output() selectEditMode: EventEmitter<any> = new EventEmitter();

  @Output() controlsUpdated: EventEmitter<any> = new EventEmitter();

  controls: any[] = [];

  CONTROL_SEPARATOR = '-';

  NOT_ALLOWED_CONTROLS = [FormElementsEnum.QuestionGroup]

  FormElementsEnum = FormElementsEnum;

  elementDragged: Observable<boolean>;

  elementDraggedHelper$: Observable<any>;

  elementResized: Observable<boolean>;

  selectedControl: Observable<ControlModel>;

  dragStart: any = {};

  dropZonesNotInitiated = true;

  canDropElement = true;

  draggedControl: any = {};
  private activeDropzoneClass = 'question-view_drop-zone_new-line--highlighted--drag-over'
  protected readonly CommentsState = EntityCommentState;

  constructor(
    public dialog: MatDialog,
    public dragService: PharDragService,
    private confirmDialog: PharConfirmDialogService,
    private store: Store<AppState>,
    private questionService: QuestionService,
    private dataFieldBindingService: DataFieldBindingService,
    private utilsService: UtilsService,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(setDragged({ dragged: false }));
    this.elementDraggedHelper$ = this.store.select(state => state.ui.draggedHelper)
    this.elementDragged = this.store.select(state => state.ui.dragged).pipe(
      distinctUntilChanged(),
      tap((isDragged) => {
        const dragData = this.dragService.dragData;
        if (!dragData || !isDragged) {
          this.canDropElement = true;
          return;
        }

        if (dragData.control) {
          if (this.NOT_ALLOWED_CONTROLS.includes(dragData.control.controlType)) {
            this.canDropElement = false;
            return;
          }
          if (dragData.control.inGroup && !this.controlIsInSameGroup(dragData.control.controlID)) {
            // allow dragging control that is in same group
            this.canDropElement = false;
            return;
          }

        }

        if (dragData.question) {
          //disabled dragging questions
          this.canDropElement = false;
          return;
        }
        // question is group but allow multiple controls is still not allowed
        if (this.question.isGroup && !this.allowMultipleControls) {
          this.canDropElement = false;
          return;
        }

        this.canDropElement = true;
      }),
      shareReplay(1)
    )

    this.elementResized = this.store.select(state => state.ui.resized);
    this.selectedControl = this.store.select(state => state.control.current.control);
  }

  ngOnChanges(): void {
    this.controls = [...this.questionControls];
    this.dropZonesNotInitiated = !this.controls.some(c => c.controlType === 'dropzone');
    if (this.builderMode && this.controls.length && this.dropZonesNotInitiated) {
      this.initiateDropZones();
      this.dropZonesNotInitiated = false;
    }
  }

  initiateDropZones(): void {
    if (!this.allowMultipleControls) {
      return;
    }
    const groupedByRow = groupBy(this.controls, c => c.grid.rowStart);
    for (const row in groupedByRow) {
      if (groupedByRow[row]) {
        this.fillRowDropZones(groupedByRow[row], row);
        this.addDropZone(parseInt(row, 10), 1, 'span 4', true);
      }
    }

  }

  dragOverNewLine($event: DragEvent, action: 'start' | 'end' = 'start') {
    if (!this.canDropElement) {
      return;
    }
    try {
      if (action === 'start') {
        ($event.target as HTMLElement).classList.add(this.activeDropzoneClass);
      } else {
        ($event.target as HTMLElement).classList.remove(this.activeDropzoneClass);
      }
    } catch (e) {
    }

  }

  onDrop($event, dropNode: any = false, forceUpdate = false): void {
    $event.preventDefault();
    //reset the drag helper. We have a situations that the helper is not reset when element is dropped
    this.store.dispatch(setDraggedHelper({ draggedHelper: {} }));
    if (!this.canDropElement) {
      return;
    }
    let dragData;
    dragData = this.dragService.dragData;
    this.dragOverNewLine($event, 'end')
    if (!dragData) {
      return;
    }

    if (dragData.reorder) {
      this.reorderElements(dragData, dropNode);
    } else {
      this.addElementOndrop(dragData, dropNode, forceUpdate);
    }
  }

  reorderElements(dragData, dropNode): void {
    const control = dragData.control;
    this.store.dispatch(setDragged({ dragged: false }));
    if (dropNode && dropNode.grid.rowStart === control.grid.rowStart) {
      if (dropNode.betweenLinesDrop) {
        const controlsOnSameRow = _filter(this.controls, c => c.controlType !== 'dropzone' && c.grid.rowStart === control.grid.rowStart && c.controlID !== control.controlID);
        if (controlsOnSameRow.length > 0) {
          this.remove(control);
          this.addElementOndrop(dragData, dropNode);
        }
      } else {

        this.addElementOndrop(dragData, dropNode);
        this.remove(control);
      }
    } else {
      this.remove(control);
      this.addElementOndrop(dragData, dropNode);
    }
  }

  addElementOndrop(dragData, dropNode, forceUpdate = false): void {
    let element = dragData.control;
    let querySelector = '';
    if (dragData.textblock) {
      element = dragData.textblock;
      element.controlID = `T${this.CONTROL_SEPARATOR}` + new Date().getTime();
    } else if (dragData.repeatableGroup) {
      element = dragData.repeatableGroup;
      element.controlID = `RG${this.CONTROL_SEPARATOR}` + new Date().getTime();
    } else if (dragData.Script) {
      element = dragData.Script;
      element.controlID = `SC${this.CONTROL_SEPARATOR}` + new Date().getTime();
    } else if (dragData.tableElement) {
      element = dragData.tableElement;
      element.controlID = `TE${this.CONTROL_SEPARATOR}` + new Date().getTime();
    } else if (dragData.dataTable) {
      element = dragData.dataTable;
      element.controlID = `DT${this.CONTROL_SEPARATOR}` + new Date().getTime();
    } else if (dragData.gridRadio) {
      element = dragData.gridRadio;
      element.controlID = `GR${this.CONTROL_SEPARATOR}` + new Date().getTime();
    } else {
      element = dragData.control;
      element.controlID = `C${this.CONTROL_SEPARATOR}` + new Date().getTime();
    }

    querySelector = `#${element.controlID} .button-block_parent`;
    element.inRepeatable = this.inRepeatable;

    if (element.controlType === FormElementsEnum.Script && element.inRepeatable) {
      element['pathToParent'] = this.parentDataField;
    }

    if (this.allowMultipleControls) {
      element['inGroup'] = true;
    }
    element.question_uuid = this.utilsService.generateUUID();
    let rowStart = 1;
    let columnStart = 1;
    let columnEnd = 'span 4';
    if (dropNode) {
      rowStart = dropNode.grid.rowStart;
      columnStart = dropNode.grid.columnStart;
      columnEnd = dropNode.grid.columnEnd;
      if (dropNode.betweenLinesDrop) {
        this.changeElementsRow(rowStart, false);
        this.addDropZone(rowStart, 1, 'span 4', true);
      } else {
        this.removeElement(dropNode);
      }
    } else {
      const lastLineControl = maxBy(this.controls, control => control.grid.rowStart);
      if (lastLineControl) {
        rowStart += lastLineControl.grid.rowStart;
      }
      this.addDropZone(rowStart, 1, 'span 4', true);

    }

    element = {
      ...element,
      grid: {
        columnStart,
        columnEnd,
        rowStart
      },
    };
    this.controls.push(element);
    this.emitControlsUpdated(forceUpdate);
    if (querySelector && !dragData.skipEdit) {
      setTimeout(function (elId) {
        const target: HTMLElement = document.querySelector(querySelector) as HTMLElement;
        if (target) {
          target.click();
        }
      }.bind(null, element.questionID), 1000);
    }
  }

  allowDrop(event, control?) {

    event.stopImmediatePropagation();

    if (!this.canDropElement) {
      this.dropZonesNotInitiated = false;
      return;
    }

    const draggedHelper = getState(this.store).ui.draggedHelper;
    // preventDefault() allow element to be dropped
    if (
      (draggedHelper.reorder && draggedHelper.parent === this.parentDataField && !(control && draggedHelper.type === FormElementsEnum.RepeatableGroup && control.grid.columnEnd !== 'span 4')) ||
      (!draggedHelper.reorder && ((control && draggedHelper.type === FormElementsEnum.RepeatableGroup && control.grid.columnEnd === 'span 4') ||
        (control && draggedHelper.type !== FormElementsEnum.RepeatableGroup) ||
        !control))
    ) {
      event.preventDefault();
    }
    this.dropZonesNotInitiated = false;
  }

  // onResizerDragStart(event, control, leftResizer = true): void {
  //   event.stopImmediatePropagation();
  //   this.store.dispatch(setResized({ resized: true }));
  //   this.dragService.setDragData(false);
  //
  //   const grid = document.getElementById('question-view_drop-zone');
  //   const start = control.grid.columnStart;
  //   const end = control.grid.columnEnd.slice(5);
  //   this.dragStart = {
  //     x: event.x,
  //     changeSizeAfter: (grid.offsetWidth - 60) / 8 + 10,
  //     originalColumnSize: end - start,
  //     grid: {
  //       columnWidth: (grid.offsetWidth - 60) / 4
  //     }
  //   };
  // }
  //
  // onResizerDrag(event, control, leftResizer = true): void {
  //   if (event.x === 0 && event.y === 0) {
  //     return;
  //   }
  //   const start = control.grid.columnStart;
  //   const columnSize = parseInt(control.grid.columnEnd.slice(5), 10);
  //   const difference = event.x - this.dragStart.x;
  //   const differenceAbsolute = Math.abs(difference);
  //   if (differenceAbsolute < this.dragStart.changeSizeAfter) {
  //     return;
  //   }
  //
  //   if (columnSize === 1 && ((leftResizer && difference > 0) || (!leftResizer && difference < 0))) {
  //     return;
  //   }
  //
  //   let newEnd = columnSize - 1;
  //   if (leftResizer) {
  //     if (difference < 0) {
  //       if (start > 1 && this.resizeSibling(control)) {
  //         newEnd = columnSize + 1;
  //         control.grid.columnStart = start - 1;
  //         control.grid.columnEnd = 'span ' + newEnd;
  //         this.dragStart.x -= this.dragStart.grid.columnWidth + 20;
  //       }
  //     } else {
  //       control.grid.columnStart = start + 1;
  //       control.grid.columnEnd = 'span ' + newEnd;
  //       this.dragStart.x += this.dragStart.grid.columnWidth + 20;
  //       this.manageDropZone(control);
  //     }
  //   } else {
  //     if (difference > 0) {
  //       if (columnSize < 4 && this.resizeSibling(control, false)) {
  //         newEnd = columnSize + 1;
  //         control.grid.columnEnd = 'span ' + newEnd;
  //         this.dragStart.x += this.dragStart.grid.columnWidth + 20;
  //       }
  //     } else {
  //       control.grid.columnEnd = 'span ' + newEnd;
  //       this.dragStart.x -= this.dragStart.grid.columnWidth + 20;
  //       this.manageDropZone(control, false);
  //     }
  //   }
  // }
  //
  // onResizerDragEnd(): void {
  //   this.emitControlsUpdated();
  //   this.dragStart = {};
  //   this.store.dispatch(setResized({ resized: false }));
  // }

  // resizeSibling(control, leftDirection = true): boolean {
  //   let columnSize;
  //   const nextSibling = _find(this.controls, function (c) {
  //     columnSize = parseInt(c.grid.columnEnd.slice(5), 10);
  //     const end = c.grid.columnStart + columnSize;
  //     const controlEnd = control.grid.columnStart + parseInt(control.grid.columnEnd.slice(5), 10);
  //     return leftDirection ?
  //       c.grid.rowStart === control.grid.rowStart && end === control.grid.columnStart :
  //       c.grid.rowStart === control.grid.rowStart && controlEnd === c.grid.columnStart;
  //   });
  //
  //   if (nextSibling && leftDirection) {
  //     if (columnSize > 1) {
  //       const newSize = columnSize - 1;
  //       nextSibling.grid.columnEnd = 'span ' + newSize;
  //       return true;
  //     } else if (nextSibling.controlType === 'dropzone') {
  //       this.removeElement(nextSibling);
  //       return true;
  //     }
  //     return false;
  //   } else if (nextSibling && !leftDirection) {
  //     if (columnSize > 1) {
  //       nextSibling.grid.columnStart++;
  //       const newEnd = parseInt(nextSibling.grid.columnEnd.slice(5), 10) - 1;
  //       nextSibling.grid.columnEnd = 'span ' + newEnd;
  //       return true;
  //     } else if (nextSibling.controlType === 'dropzone') {
  //       this.removeElement(nextSibling);
  //       return true;
  //     }
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  removeElement(element): any {
    // We don't have reliable way to update the bindDataField until we reach element editor AfterViewInit hook
    // This causes difference in the object between rendered control and current editor control
    // That's why will search by controlID.
    let index;
    if (element.controlType === 'dropzone') {
      index = _findIndex(this.controls, control => control.grid.rowStart === element.grid.rowStart);
    } else {
      index = _findIndex(this.controls, control => control?.controlID === element.controlID);
    }
    const grid = this.controls[index].grid;
    this.controls.splice(index, 1);
    this.dataFieldBindingService.removeControlDataFields(element);
    return grid;
  }

  // manageDropZone(control, leftSide = true): void {
  //   const start = control.grid.columnStart;
  //   const columnSize = parseInt(control.grid.columnEnd.slice(5), 10);
  //   const columnEnd = start + columnSize;
  //
  //   if (leftSide) {
  //     if (start === 2) {
  //       this.addDropZone(control.grid.rowStart, 1);
  //     } else {
  //       const sibling = _find(this.controls, function (c) {
  //         const end = c.grid.columnStart + parseInt(c.grid.columnEnd.slice(5), 10);
  //         return c.grid.rowStart === control.grid.rowStart && end === control.grid.columnStart - 1;
  //       });
  //       if (sibling && sibling.controlType === 'dropzone') {
  //         const newEnd = parseInt(sibling.grid.columnEnd.slice(5), 10) + 1;
  //         sibling.grid.columnEnd = 'span ' + newEnd;
  //       } else {
  //         this.addDropZone(control.grid.rowStart, control.grid.columnStart - 1);
  //       }
  //     }
  //   } else {
  //     if (start === 1 && columnSize === 3) {
  //       this.addDropZone(control.grid.rowStart, 4);
  //     } else {
  //       const sibling = _find(this.controls, function (c) {
  //         return c.grid.rowStart === control.grid.rowStart && c.grid.columnStart === columnEnd + 1;
  //       });
  //       if (sibling && sibling.controlType === 'dropzone') {
  //         const newEnd = parseInt(sibling.grid.columnEnd.slice(5), 10) + 1;
  //         sibling.grid.columnEnd = 'span ' + newEnd;
  //         sibling.grid.columnStart--;
  //       } else {
  //         this.addDropZone(control.grid.rowStart, columnEnd);
  //       }
  //     }
  //   }
  // }

  addDropZone(rowStart: number, columnStart: number, columnEnd = 'span 1', betweenLinesDrop = false): void {
    if (!this.controls) {
      this.controls = [];
    }

    this.controls.push({
      controlType: 'dropzone',
      controlID: this.utilsService.generateUniqueNumber(),
      grid: {
        columnStart,
        columnEnd,
        rowStart,
      },
      betweenLinesDrop
    });
  }

  removeEvent(element): void {
    this.confirmDialog.openConfirmDialog('Do you want to remove the component from the page?').pipe(take(1)).subscribe(result => {
      if (result) {
        this.remove(element, true);
      }
    });
  }

  remove(element, forceUpdate = false): void {
    const grid = this.removeElement(element);
    if (grid.columnEnd !== 'span 4') {
      const groupedByRow = groupBy(this.controls, c => c.grid.rowStart);
      const elements = _filter(groupedByRow[grid.rowStart], function (c) {
        return c.controlType !== 'dropzone';
      });
      if (elements.length === 0) {
        for (const index in groupedByRow[grid.rowStart]) {
          if (groupedByRow[grid.rowStart][index]) {
            this.removeElement(groupedByRow[grid.rowStart][index]);
          }
        }
        this.changeElementsRow(grid.rowStart + 1);
      } else {
        this.fixRowElements(groupedByRow[grid.rowStart], grid);
      }
    } else {
      const lineDropZone = _filter(this.controls, c => c.betweenLinesDrop === true && c.grid.rowStart === element.grid.rowStart)[0];
      if (lineDropZone) {
        this.removeElement(lineDropZone);
      }
      this.changeElementsRow(grid.rowStart + 1, grid);
    }

    this.emitControlsUpdated(forceUpdate);
    this.selectEditMode.emit({}); // send empty object to reset the editor
  }

  changeElementsRow(startRow: number, up = true): void {
    const groupedByRow = groupBy(this.controls, c => c.grid.rowStart);
    for (const row in groupedByRow) {
      if (groupedByRow[row] && parseInt(row, 10) >= startRow) {

        for (let i = 0; i < groupedByRow[row].length; i++) {


          if (up) {
            groupedByRow[row][i].grid.rowStart--;
          } else {
            groupedByRow[row][i].grid.rowStart++;
          }
        }
      }
    }
  }

  fixRowElements(elements, grid): void {
    // if (elements.length === 1) {
    //   this.addDropZone(grid.rowStart, grid.columnStart, grid.columnEnd);
    // } else {
    //   const dropzones = _filter(elements, function (c) {
    //     return c['controlType'] === 'dropzone';
    //   });
    //   elements = _filter(elements, function (c) {
    //     return c['controlType'] !== 'dropzone';
    //   });
    //   for (let i = 0; i < dropzones.length; i++) {
    //     if (!dropzones[i]['betweenLinesDrop']) {
    //       this.removeElement(dropzones[i]);
    //     }
    //   }
    //   this.fillRowDropZones(elements, grid.rowStart);
    // }
  }

  fillRowDropZones(elements, row): void {
    const rowElementsByStart = groupBy(elements, c => c['grid']['columnStart']);
    let count = 0;
    for (let i = 1; i <= 4; i++) {
      if (rowElementsByStart[i]) {
        count = -parseInt(rowElementsByStart[i][0]['grid']['columnEnd'].slice(5), 10) + 1;
      } else {
        if (count >= 1) {
          this.resizePreiousDropZone(parseInt(row, 10), i);
          count++;
        } else if (count < 0) {
          count++;
        } else {
          this.addDropZone(parseInt(row, 10), i);
          count++;
        }
      }
    }
  }

  resizePreiousDropZone(row: number, column: number): void {
    let columnSize;
    const previousDropZone = _find(this.controls, function (c) {
      columnSize = parseInt(c.grid.columnEnd.slice(5), 10);
      const end = c.grid.columnStart + columnSize;
      return c.grid.rowStart === row && end === column && c.controlType === 'dropzone';
    });
    const newEnd = columnSize + 1;
    previousDropZone.grid.columnEnd = 'span ' + newEnd;
  }

  onControlDragStart(event, control): void {
    event.stopImmediatePropagation();
    const dragData = {
      control,
      reorder: true
    };
    this.store.dispatch(setDraggedHelper({
      draggedHelper: {
        type: control.controlType,
        parent: this.parentDataField,
        reorder: true
      }
    }));
    this.dragService.setDragData(dragData);
    this.store.dispatch(setDragged({ dragged: true }));
  }

  onControlDragEnd(): void {
    this.store.dispatch(setDragged({ dragged: false }));
    this.store.dispatch(setDraggedHelper({ draggedHelper: {} }));
    this.dragService.setDragData(false);
    this.draggedControl = {};
  }

  editQuestionElement(type, control?): void {
    const parentDataField = this.parentDataField;
    const skipUpdateCurrentQuestion = getState(this.store).question.current.question.id === this.question.id;
    this.selectEditMode.emit({ control, type, parentDataField, skipUpdateCurrentQuestion });
  }

  duplicateQuestion(ctrl: ControlModel): void {
    const controlCopy = cloneDeep(ctrl);
    const { title, label } = this.utilsService.generateNewControlTitleAndLabel(ctrl, this.store)
    const controlID = controlCopy.controlID.split(this.CONTROL_SEPARATOR)
      .map((el, index) => (index === 0 ? el : new Date().getTime()
      )).join(this.CONTROL_SEPARATOR);
    let duplicatedControl: ControlModel = {
      ...controlCopy,
      title,
      label,
      controlID,
      bindDataField: '',
      question_uuid: this.utilsService.generateUUID(),
      commentsState: EntityCommentState.NoComments,
    };
    const controlWithBoundDataField = this.dataFieldBindingService.bindDataFieldToTheControl(duplicatedControl, false);
    if (controlWithBoundDataField) {
      duplicatedControl = controlWithBoundDataField;
    }
    // start simulating the control drag and drop
    const dragData = {
      control: duplicatedControl,
      reorder: false,
      skipEdit: true,
    };
    this.store.dispatch(setDraggedHelper({
      draggedHelper: {
        type: duplicatedControl.controlType,
        parent: this.parentDataField,
        reorder: false
      }
    }));
    this.dragService.setDragData(dragData);
    // searching for next dropzone, if no dropzone, then it will be added to the end of the question group;
    const nextRow = controlCopy.grid.rowStart + 1;
    const dropNode = this.controls.find((c: ControlModel) => c.controlType === 'dropzone' && (c.grid.rowStart === nextRow));
    this.onDrop(new Event('fakeEvent'), dropNode, true);
    this.store.dispatch(setDraggedHelper({ draggedHelper: {} }));
  }

  // setSelectEditMode(data): void {
  //   this.selectEditMode.emit(data);
  // }
  //
  // repeatableControlsUpdated(controls, repeatableGroup): void {
  //   repeatableGroup.controls = controls;
  //   this.emitControlsUpdated();add .
  // }

  afterControlUpdate(control: ControlModel): void {
    const index = _findIndex(this.controls, el => el.controlID === control.controlID);
    this.controls[index] = control;
    this.emitControlsUpdated();
  }

  private controlIsInSameGroup(controlID: string): boolean {
    return this.controls.some(ctrl => ctrl?.controlID === controlID)
  }

  private emitControlsUpdated(forceUpdateQuestion = false): void {
    // emit controls as its with dropzones, they will be removed when we are sending request to the BE
    this.controlsUpdated.emit({ controls: this.controls, forceUpdateQuestion });
  }

}

import { animate, state, style, transition, trigger } from '@angular/animations';

export const toggleInOut = trigger('toggleInOut', [
  state('open', style({
    width: '200px'
  })),
  state('close', style({
    width: '56px',
  })),
  transition('open <=> close', animate('.2s ease-in-out'))
]);

import {
  Component,
  DoCheck,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild
} from '@angular/core';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl, ValidationErrors,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { FocusMonitor } from '@angular/cdk/a11y';
import { FormControls } from '../types/form-controls.type';

export interface INumberRange {
  start: number | null,
  end: number | null;
}

export type NumberRangeForm = FormControls<INumberRange>;

@Component({
  selector: 'phar-number-range',
  templateUrl: 'number-range.component.html',
  styleUrl: 'number-range.component.scss',
  providers: [{ provide: MatFormFieldControl, useExisting: NumberRangeComponent }],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
})


export class NumberRangeComponent implements ControlValueAccessor, MatFormFieldControl<{
  start: number | null,
  end: number | null
}>, OnDestroy, DoCheck {
  static nextId = 0;
  @ViewChild('startInput') startInput: HTMLInputElement;
  @ViewChild('endInput') endInput: HTMLInputElement;
  @Input() startPlaceholder = 'Start';
  @Input() endPlaceholder = 'End';
  formGroup: FormGroup<NumberRangeForm>;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'number-range-input';
  id = `number-range-input-${NumberRangeComponent.nextId++}`;
  errorState = false;

  onChange = (_: any) => {
  };
  onTouched = () => {
    this.formGroup.markAllAsTouched();
    this.formGroup.controls.start.markAsTouched();
    this.formGroup.controls.end.markAsTouched();
    this.formGroup.controls.start.markAsDirty()
    this.formGroup.controls.end.markAsDirty()
  };

  get empty() {
    const {
      value: { start, end },
    } = this.formGroup;

    return !this.isValid(start) && !this.isValid(end);
  }


  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input('aria-describedby') userAriaDescribedBy: string; //eslint-disable-line @angular-eslint/no-input-rename

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formGroup.disable() : this.formGroup.enable();
    this.stateChanges.next();
  }

  private _disabled = false;

  @Input()
  get value(): { start: number | null; end: number | null } | null {
    if (this.formGroup.valid) {
      const {
        value: { start, end },
      } = this.formGroup;
      return { start, end };
    }
    return null;
  }

  set value(range: { start: number; end: number } | null) {
    const { start, end } = range || { start: null, end: null };
    this.formGroup.patchValue({ start, end });
    this.stateChanges.next();

  }

  constructor(
    formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.formGroup = formBuilder.group({
      start: new FormControl<number | null>(null, [Validators.required]),
      end: new FormControl<number | null>(null, [Validators.required]),
    }, {
      validators: this.rangeValidator
    });
  }

  rangeValidator(group: FormGroup<NumberRangeForm>): ValidationErrors | null {
    const { start, end } = group.value

    if (start === null || end === null) {
      return null;
    }

    return start >= end ? { 'rangeValidator': 'End date should be after Start Date' } : null;
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
      this.onTouched();
    }
  }


  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    // if (this.formGroup.controls.start.valid) {
    //   this._focusMonitor.focusVia(this.endInput, 'program');
    // } else {
    //   this._focusMonitor.focusVia(this.startInput, 'program');
    //
    // }
  }

  writeValue(range: { start: number, end: number } | null): void {
    this.value = range;

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  _handleInput(): void {
    this.onChange(this.value);
  }


  isValid(val: number): boolean {
    return val !== null && val !== undefined;
  }

  readonly autofilled: boolean;


}

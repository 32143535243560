<phar-base-control-template
  controlName="Date Picker"
  [control]="control()"
  [question]="question"
  [builderMode]="builderMode"
  [isDraggable]="isDraggable"
  [isCommentsVisible]="isCommentsVisible"
  [canManageControl]="canManageControl"
  [showActionButtons]="(isControlActive$ | async) || (isControlHovered$ | async)"
  [highlightBackground]="isControlHighlighted$ | async"
  [hasConditionalRules]="hasConditionalRules()"
  (actionClick)="actionsHandler($event, control())">
  <mat-form-field class="no-pointer-events w-100">
    <div class="d-flex">
      <input [matDatepicker]="picker" matInput placeholder="yyyy.mm.dd"> <!--//@TODO format have to be in sync with the site-->
      <mat-icon (click)="picker.open()">today</mat-icon>
      <mat-datepicker #picker></mat-datepicker>
    </div>
  </mat-form-field>
</phar-base-control-template>



import { AfterViewInit, Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';

@Directive({
  selector: '[pharMaxLengthTooltip]',
})
export class MaxInputLengthTooltipDirective extends MatTooltip implements AfterViewInit, OnDestroy {
  @Input() pharMaxLengthTooltip: number;
  @Input() pharMaxLengthTooltipDisplayTime: number = 2500;
  private pharTooltipPosition: TooltipPosition = 'above';
  private pharShowTooltip$ = new Subject<any>();

  @Input()
  set pharMaxLengthTooltipPosition(position: TooltipPosition) {
    this.position = position;
    this.pharTooltipPosition = position;
  };

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.pharShowTooltip$.complete();
  }

  ngAfterViewInit(): void {
    //super.ngAfterViewInit();

    this.disabled = true;
    this.position = this.pharTooltipPosition;
    this.message = `Limit is ${this.pharMaxLengthTooltip} characters`;

    this.pharShowTooltip$
      .pipe(
        tap(() => {
          this.disabled = false;
          this.show();
        }),
        debounceTime(this.pharMaxLengthTooltipDisplayTime),
      )
      .subscribe(() => {
        this.disabled = true;
      })
  }

  @HostListener('input', ['$event.target'])
  onInput(target: HTMLInputElement): void {
    const value: string | undefined = target.value || target.innerText;

    if (value && value.length >= this.pharMaxLengthTooltip) {
      this.pharShowTooltip$.next(true);
    }
  }

}

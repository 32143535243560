import { createAction, props } from '@ngrx/store';
import { QuestionModel } from '../question.model';
import { ControlModel } from '../control.model';

const PREFIX = '[QUESTION]';

export enum ActionTypes {
  DELETEQUESTION = `${PREFIX} DELETEQUESTION`,
  REMOVE_QUESTION = `${PREFIX} REMOVE_QUESTION`,
  LOADQUESTIONLISTBYDATASET = `${PREFIX} LOADQUESTIONLISTBYDATASET`,
  LOADQUESTIONLISTBYFORM = `${PREFIX} LOADQUESTIONLISTBYFORM`,
  UPDATEQUESTIONLISTBYDATASET = `${PREFIX} UPDATEQUESTIONLISTBYDATASET`,
  UPDATEQUESTIONLISTBYFORM = `${PREFIX} UPDATEQUESTIONLISTBYFORM`,
  RESET_CURRENT_QUESTION = `${PREFIX} RESET_CURRENT_QUESTION`,
  SEARCH_QUESTION_BY_ID = `${PREFIX} SEARCH_QUESTION_BY_ID`,
  CREATE_QUESTION = `${PREFIX} CREATE_QUESTION`,
  UPDATE_QUESTION = `${PREFIX} UPDATE_QUESTION`,
  UPDATE_QUESTIONS = `${PREFIX} UPDATE_QUESTIONS`,
  PENDING_CREATE_UPDATE_QUESTION = `${PREFIX}PENDING_CREATE_UPDATE_QUESTION`,
  DISPACTHED_CREATE_UPDATE_QUESTION = `${PREFIX}DISPACTHED_CREATE_UPDATE_QUESTION`,
  SUCCESS_CREATE_UPDATE_QUESTION = `${PREFIX}SUCCESS_CREATE_UPDATE_QUESTION`,
  UPDATE_QUESTION_IN_LIST = `${PREFIX}UPDATE_QUESTION_IN_LIST`,
  UPDATE_QUESTIONS_IN_LIST = `${PREFIX}UPDATE_QUESTIONS_IN_LIST`,
  POPULATE_CURRENT_QUESTION = `${PREFIX}POPULATE_CURRENT_QUESTION`,
  UPDATE_QUESTION_FIELD = `${PREFIX}UPDATE_QUESTION_FIELD`,
  UPDATE_QUESTION_FIELDS = `${PREFIX} UPDATE_QUESTION_FIELDS`,
  ADD_QUESTION_IN_LIST_DATASET = `${PREFIX} ADD_QUESTION_IN_LIST_DATASET`,
  ADD_QUESTION_IN_LIST_FORM = `${PREFIX} ADD_QUESTION_IN_LIST_FORM`,
  UPDATE_CONTROL_IN_QUESTION = `${PREFIX} UPDATE_CONTROL_IN_QUESTION`,
  REFRESH_CURRENT_QUESTION = `${PREFIX} Refresh  current question`,
  CHECK_CURRENT_QUESTION_FOR_PENDING_CHANGES = `${PREFIX} Update  current question pending changes`,
  POPULATE_CURRENT_QUESTION_SNAPSHOT = `${PREFIX} Populate  current question snapshot`,
  AUTOCREATE_QUESTION_SUCCESS = `${PREFIX} AUTOCREATE_QUESTION_SUCCESS`,
  CONTROL_VALUE_CHANGED = `${PREFIX} CONTROL_VALUE_CHANGED`,
  SHOW_SAVE_CONTROL_BEFORE_SAVING_QUESTION_DIALOG = `${PREFIX} SHOW_SAVE_CONTROL_BEFORE_SAVING_QUESTION_DIALOG`,
  DELETE_MULTIPLE_QUESTIONS = `${PREFIX} Delete  multiple questions`,

}

export const loadQuestionListByDataset = createAction(ActionTypes.LOADQUESTIONLISTBYDATASET, props<{
  datasetId: number
}>());


export const loadQuestionListByForm = createAction(ActionTypes.LOADQUESTIONLISTBYFORM, props<{ formId: number }>());

export const updateQuestionListByDataset = createAction(ActionTypes.UPDATEQUESTIONLISTBYDATASET, props<{
  questionList: QuestionModel[]
}>());


export const updateQuestionListByForm = createAction(ActionTypes.UPDATEQUESTIONLISTBYFORM, props<{
  questionList: QuestionModel[]
}>());


export const deleteQuestion = createAction(ActionTypes.DELETEQUESTION, props<{
  id: number,
  isFormQuestion: boolean
}>());


export const removeQuestion = createAction(ActionTypes.REMOVE_QUESTION, props<{ id: number | number[] }>());


export const resetCurrentQuestion = createAction(ActionTypes.RESET_CURRENT_QUESTION);


export const searchQuestionById = createAction(ActionTypes.SEARCH_QUESTION_BY_ID, props<{ id: number }>());

export const populateCurrentQuestion = createAction(ActionTypes.POPULATE_CURRENT_QUESTION, props<{
  question: QuestionModel
}>());


export const createQuestion = createAction(ActionTypes.CREATE_QUESTION, props<{
  question: QuestionModel,
  isAutoCreate?: boolean
}>());


export const updateQuestion = createAction(ActionTypes.UPDATE_QUESTION, props<{
  question: QuestionModel,
  isAutoCreate?: boolean
}>());
export const updateQuestions = createAction(ActionTypes.UPDATE_QUESTIONS, props<{
  questions: QuestionModel[],
}>());


export const updateQuestionField = createAction(ActionTypes.UPDATE_QUESTION_FIELD, props<{
  field: string,
  value: any,
}>());

export const updateQuestionFields = createAction(ActionTypes.UPDATE_QUESTION_FIELDS, props<{
  fields: Partial<QuestionModel>
}>());


export const pendingCreateUpdateQuestion = createAction(ActionTypes.PENDING_CREATE_UPDATE_QUESTION, props<{
  pending: boolean
}>());

export const dispatchedCreateUpdateQuestion = createAction(ActionTypes.DISPACTHED_CREATE_UPDATE_QUESTION, props<{
  dispatched: boolean
}>());


export const successCreateUpdateQuestion = createAction(ActionTypes.SUCCESS_CREATE_UPDATE_QUESTION, props<{
  success: boolean,
  response?: QuestionModel,
  isAutoCreate?: boolean
}>());


export const updateQuestionInList = createAction(ActionTypes.UPDATE_QUESTION_IN_LIST, props<{
  question: QuestionModel
}>());

export const updateQuestionsInList = createAction(ActionTypes.UPDATE_QUESTIONS_IN_LIST, props<{
  questions: QuestionModel[]
}>());


export const addQuestionInListDataset = createAction(ActionTypes.ADD_QUESTION_IN_LIST_DATASET, props<{
  question: QuestionModel
}>());


export const addQuestionInListForm = createAction(ActionTypes.ADD_QUESTION_IN_LIST_FORM, props<{
  question: QuestionModel
}>());


export const refreshCurrentQuestion = createAction(ActionTypes.REFRESH_CURRENT_QUESTION);


export const checkCurrentQuestionForPendingChanges = createAction(ActionTypes.CHECK_CURRENT_QUESTION_FOR_PENDING_CHANGES, props<{
  hasChanges: boolean
}>());


export const populateCurrentQuestionSnapshot = createAction(ActionTypes.POPULATE_CURRENT_QUESTION_SNAPSHOT, props<{
  question: QuestionModel
}>());

export const autocreateQuestionSuccess = createAction(ActionTypes.AUTOCREATE_QUESTION_SUCCESS);

export const controlValueChanged = createAction(ActionTypes.CONTROL_VALUE_CHANGED, props<{
  control: ControlModel,
  shouldUpdateDataField: boolean
}>());

export const deleteMultipleQuestions = createAction(ActionTypes.DELETE_MULTIPLE_QUESTIONS, props<{
  ids: number[],
}>());


export const showSaveControlBeforeSavingQuestionDialog = createAction(ActionTypes.SHOW_SAVE_CONTROL_BEFORE_SAVING_QUESTION_DIALOG);

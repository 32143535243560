import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/models/app.state';
import { PharBaseControlModelComponent } from '../phar-base-control/phar-base-control-model.component';

@Component({
  selector: 'phar-text-block',
  templateUrl: 'text-block.component.html',
  styleUrls: ['text-block.component.scss'],
})
export class TextBlockComponent extends PharBaseControlModelComponent {

  constructor(
    store: Store<AppState>
  ) {
    super(store);
  }

}

import { createAction, props } from '@ngrx/store';
import { DatasetModel } from '../dataset.model';
import { DatasetDataTypeModel } from '../dataset-data-type.model';
import { DatasetVersion } from './dataset.state';
import { IDatasetList } from '../../_old/_dataset/_dataset-lists/dataset-list.interface';
// import { IDatasetListValues } from '../dataset-lists/dataset-list-values.interface';
// import { CustomersState } from '../../_customers/_store/customer.state';
// import { selectCustomer } from '../../_customers/_store/customers.reducer';

export enum ActionTypes {
  DELETE = 'DELETE',
  LOADLIST = 'LOADLIST',
  UPDATELIST = 'UPDATELIST',
  UPDATE_DATASET_IN_LIST = 'UPDATE_DATASET_IN_LIST',
  REMOVEDATASET = 'REMOVEDATASET',
  UPDATE_DATASET_FIELD = 'UPDATE_DATASET_FIELD',
  ADD_DATASET = 'ADD_DATASET',
  CREATE_DATASET = 'CREATE_DATASET',
  UPDATE_DATASET = 'UPDATE_DATASET',
  PENDING_CREATE_DATASET = 'PENDING_CREATE_DATASET',
  DISPATCHED_CREATE_DATASET = 'DISPATCHED_CREATE_DATASET',
  SUCCESS_CREATE_DATASET = 'SUCCESS_CREATE_DATASET',
  POPULATE_CURRENT_DATASET = 'POPULATE_CURRENT_DATASET',
  RESET_CURRENT_DATASET = 'RESET_CURRENT_DATASET',
  SEARCH_BY_ID = 'SEARCH_BY_ID',
  LOAD_DATASET_TYPES = 'LOAD_DATASET_TYPES',
  UPDATE_DATASET_TYPES = 'UPDATE_DATASET_TYPES',
  REFRESH_CURRENT_DATASET = '[Dataset] REFRESH_CURRENT_DATASET',
  CHECK_CURRENT_DATASET_FOR_PENDING_CHANGES = '[Dataset] Check current dataset for pending changes',
  POPULATE_CURRENT_DATASET_SNAPSHOT = '[Dataset] POPULATE_CURRENT_DATASET_SNAPSHOT',
  LOAD_BY_ID_AND_VERSION = '[Dataset] Load Dataset By ID and version',
  LOAD_ALL_VERSIONS = '[Dataset] Load All Dataset versions',
  FETCH_ALL_VERSIONS = '[Dataset] Fetch All Dataset versions',
  LOAD_DATASET_BY_LATEST_VERSION = '[Dataset] Load Dataset by latest version',
  GET_DATASET_DATA_LISTS = '[Dataset Lists] Get dataset lists',
  LOAD_DATASET_DATA_LISTS = '[Dataset Lists] Load dataset lists',
  DELETE_DATASET_DATA_LIST = '[Dataset Lists] Delete dataset list',
  UPDATE_DATASET_DATA_LIST_FIELD = '[Dataset Lists] Update dataset list field',
  UPDATE_DATASET_DATA_LIST = '[Dataset Lists] Update dataset list',
  CREATE_DATASET_DATA_LIST = '[Dataset Lists] Create dataset list',
  GET_DATASET_DATA_LIST_VALUES = '[Dataset Lists] Get dataset list values',
  LOAD_CURRENT_DATASET_DATA_LIST = '[Dataset Lists] Load current dataset data list',
  LOAD_CURRENT_DATASET_DATA_LIST_BY_ID = '[Dataset Lists] Load current dataset data list by id',
  RESET_CURRENT_DATASET_DATA_LIST = '[Dataset Lists] Reset current dataset data list',
  LOAD_DATASET_DATA_LIST_VALUES = '[Dataset Lists] Load dataset list values',
  DELETE_DATASET_DATA_LIST_VALUE = '[Dataset Lists] Delete dataset list value',
  UPDATE_DATASET_DATA_LIST_VALUE = '[Dataset Lists] Update dataset list value',
  ADD_DATASET_DATA_LIST_VALUE = '[Dataset Lists] Add dataset list value',

}

export const loadDatasetList = createAction(ActionTypes.LOADLIST);
export const updateDatasetList = createAction(ActionTypes.UPDATELIST, props<{ datasetList: DatasetModel[] }>());
export const updateDatasetInList = createAction(ActionTypes.UPDATE_DATASET_IN_LIST, props<{ dataset: DatasetModel }>());
export const deleteDataset = createAction(ActionTypes.DELETE, props<{ id: number }>());
export const removeDataset = createAction(ActionTypes.REMOVEDATASET, props<{ id: number }>());
export const updateDatasetField = createAction(ActionTypes.UPDATE_DATASET_FIELD, props<{
  field: string,
  value: string | number | any
}>());
export const createDataset = createAction(ActionTypes.CREATE_DATASET, props<{ dataset: DatasetModel }>());
export const updateDataset = createAction(ActionTypes.UPDATE_DATASET, props<{
  dataset: DatasetModel,
  success?: boolean
}>());
export const pendingCreateDataset = createAction(ActionTypes.PENDING_CREATE_DATASET, props<{ pending: boolean }>());
export const dispatchedCreateDataset = createAction(ActionTypes.DISPATCHED_CREATE_DATASET, props<{
  dispatched: boolean
}>());
export const successCreateDataset = createAction(ActionTypes.SUCCESS_CREATE_DATASET, props<{ success: boolean }>());
export const addDataset = createAction(ActionTypes.ADD_DATASET, props<{ dataset: DatasetModel }>());
export const populateCurrentDataset = createAction(ActionTypes.POPULATE_CURRENT_DATASET, props<{
  dataset: DatasetModel
}>());
export const searchDatasetById = createAction(ActionTypes.SEARCH_BY_ID, props<{ id: number }>());
export const loadDatasetTypes = createAction(ActionTypes.LOAD_DATASET_TYPES);
export const updateDatasetTypes = createAction(ActionTypes.UPDATE_DATASET_TYPES, props<{
  types: DatasetDataTypeModel[]
}>());
export const refreshCurrentDataset = createAction(ActionTypes.REFRESH_CURRENT_DATASET);
export const checkCurrentDatasetForPendingChanges = createAction(ActionTypes.CHECK_CURRENT_DATASET_FOR_PENDING_CHANGES, props<{
  hasChanges: boolean
}>());
export const populateCurrentDatasetSnapshot = createAction(ActionTypes.POPULATE_CURRENT_DATASET_SNAPSHOT, props<{
  dataset: DatasetModel
}>());
export const loadByIdAndVersion = createAction(ActionTypes.LOAD_BY_ID_AND_VERSION, props<{
  id: number,
  version: number
}>());
export const fetchAllDatasetVersions = createAction(ActionTypes.FETCH_ALL_VERSIONS, props<{ datasetId: number }>());
export const loadAllDatasetVersions = createAction(ActionTypes.LOAD_ALL_VERSIONS, props<{
  versions: DatasetVersion[]
}>());
export const loadDatasetByLatestVersion = createAction(ActionTypes.LOAD_DATASET_BY_LATEST_VERSION);
export const resetCurrentDataset = createAction(ActionTypes.RESET_CURRENT_DATASET);
// DATA LISTS
export const getDatasetDataLists = createAction(ActionTypes.GET_DATASET_DATA_LISTS, props<{ datasetId: number }>());
export const loadDatasetDataLists = createAction(ActionTypes.LOAD_DATASET_DATA_LISTS, props<{
  lists: IDatasetList[]
}>());
export const loadCurrentDatasetDataList = createAction(ActionTypes.LOAD_CURRENT_DATASET_DATA_LIST, props<{
  list: IDatasetList
}>());
export const loadCurrentDatasetDataListById = createAction(ActionTypes.LOAD_CURRENT_DATASET_DATA_LIST_BY_ID, props<{
  id: number
}>());
export const resetCurrentDatasetDataList = createAction(ActionTypes.RESET_CURRENT_DATASET_DATA_LIST);
export const deleteDatasetDataList = createAction(ActionTypes.DELETE_DATASET_DATA_LIST, props<{
  list: IDatasetList
}>());
export const updateDatasetDataListField = createAction(ActionTypes.UPDATE_DATASET_DATA_LIST_FIELD, props<{
  field: string,
  value: any
}>());
export const updateDatasetDataList = createAction(ActionTypes.UPDATE_DATASET_DATA_LIST);
export const createDatasetList = createAction(ActionTypes.CREATE_DATASET_DATA_LIST, props<{
  title: string,
  datasetId: number
}>());
/// DATA LIST VALUES
export const addDatasetDataListValue = createAction(ActionTypes.ADD_DATASET_DATA_LIST_VALUE, props<{
  value: string,
  label: string
}>());
export const updateDatasetDataListValue = createAction(ActionTypes.UPDATE_DATASET_DATA_LIST_VALUE, props<{
  oldValue: string,
  oldLabel: string,
  newValue: string,
  newLabel: string
}>());
export const deleteDatasetDataListValue = createAction(ActionTypes.DELETE_DATASET_DATA_LIST_VALUE, props<{
  value: string,
  label: string
}>());

<button (click)="onCloseClick()" class="close-icon" mat-icon-button>
  <mat-icon class="button-block_small-icon" svgIcon="close"></mat-icon>
</button>

<h1 class="text-center" mat-dialog-title>{{ data.title }}</h1>

<div mat-dialog-content>
  <p class="text-center" [innerHTML]="data.description | safeHtml"></p>
</div>
<div align="center" mat-dialog-actions>
  <button (click)="onCancelClick()" *ngIf="data.cancelText" mat-stroked-button>{{ data.cancelText }}</button>
  <button (click)="onConfirmClick()" *ngIf="data.confirmText" class="button-success" mat-raised-button>
    <mat-icon [svgIcon]="'check'"></mat-icon>
    {{ data.confirmText }}
  </button>
</div> 

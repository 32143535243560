import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, computed, effect,
  ElementRef,
  EventEmitter,
  HostListener, inject, Injector, input,
  Input, InputSignal,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { UserModel } from '../../user/user.model';
import { BaseComponent } from '../base.class';
import { IEntityComment } from '../models/entity-comments.interface';
import { INPUT_DEBOUNCE_TIME } from '../../core/config/app.constants';

interface AutocompleteMentionUser {
  searchTerm: string,
  startPos: number,
  endPos: number,
  listOfUsers: UserModel[],
  activeElementIndex: number,
}


@Component({
  selector: 'phar-entity-comments',
  templateUrl: 'entity-comments.component.html',
  styleUrls: ['entity-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityCommentsComponent extends BaseComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  @ViewChild('commentInput', { static: true }) commentInputElement: ElementRef;
  @ViewChild('commentsSection') commentsSectionElement: ElementRef;
  @ViewChild('commentsAutocomplete') commentsAutocompleteElement: ElementRef;
  @Input() isViewOnly: boolean = false;
  @Output() requestClose: EventEmitter<any> = new EventEmitter();
  @Output() commentDeleted: EventEmitter<IEntityComment> = new EventEmitter();
  @Output() commentUpdated: EventEmitter<IEntityComment> = new EventEmitter();
  @Output() commentCreated: EventEmitter<IEntityComment> = new EventEmitter();
  @Output() commentResolved: EventEmitter<IEntityComment> = new EventEmitter();
  comments: InputSignal<IEntityComment[]> = input<IEntityComment[]>();
  showCloseButton: InputSignal<boolean> = input<boolean>(false);
  currentUser = input<UserModel>();
  users = input<UserModel[]>([]);
  isLoading = input<boolean>(false);
  commentFormControl = new FormControl<string>('');
  editableComment: IEntityComment | null = null;
  autocompleteMentionUser: AutocompleteMentionUser | null = null;
  injector = inject(Injector);
  listOfUsers = computed(() => {
    if (!this.users()?.length) {
      return [];
    }
    return this.users().filter((u) => u.userName !== this.currentUser().userName);
  });

  constructor(
    protected readonly changeDetector: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnChanges(): void {
    if (this.isViewOnly && this.commentFormControl.enabled) {
      this.commentFormControl.disable();
    }

    if (!this.isViewOnly && this.commentFormControl.disabled) {
      this.commentFormControl.enable();
    }
  }

  ngOnInit(): void {
    this.handleMentionAutocomplete();
    const scrollToBottomEffect = effect(() => {
      const commentsLength = this.comments().length;

      if (commentsLength) {
        this.scrollToCommentsBottom();
        scrollToBottomEffect.destroy();
      }
    }, {
      injector: this.injector,
      manualCleanup: true
    });
  }

  ngAfterViewInit() {
    this.commentInputElement.nativeElement.focus();

  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }


  onEditCommentClick(comment: IEntityComment): void {
    this.resetComment();
    this.editableComment = comment;
    this.commentFormControl.patchValue(comment.comment);

    this.scrollToCommentInputBottom();
    this.changeDetector.detectChanges();
    this.commentInputElement.nativeElement.focus();
  }

  sendComment(): void {
    if (this.editableComment) {
      this.editComment();
    } else {
      this.createComment();
    }
  }

  createComment(): void {
    const commentText = this.commentFormControl.getRawValue();
    if (!commentText) {
      return;
    }
    const comment: IEntityComment = {
      userId: this.currentUser().userId,
      comment: commentText,
      isResolved: false,
    };

    this.commentCreated.emit(comment);
  }

  deleteComment(comment: IEntityComment): void {
    this.commentDeleted.emit(comment);
  }

  editComment(): void {
    const commentText = this.commentFormControl.getRawValue();
    const updatedComment = {
      ...this.editableComment,
      comment: commentText,
    };
    this.commentUpdated.emit(updatedComment);
    this.resetComment();
  }

  markCommentAsResolved(comment: IEntityComment): void {
    this.commentResolved.emit(comment);
  }

  resetComment(): void {
    this.editableComment = null;
    this.autocompleteMentionUser = null;
    this.commentFormControl.reset('');
  }

  autocompleteMention(user: UserModel): void {
    if (!user) {
      this.autocompleteMentionUser = null;
      return;
    }
    const userName = user.userName + ' ';
    const comment = this.commentFormControl.getRawValue();

    const { startPos, endPos } = this.autocompleteMentionUser;
    const posAfterAtSymbol = startPos + 1;
    const newComment = comment.slice(0, posAfterAtSymbol) + userName + comment.slice(endPos);
    const newCaretPos = posAfterAtSymbol + userName.length;

    this.autocompleteMentionUser = null;
    this.commentFormControl.setValue(newComment);
    this.commentInputElement.nativeElement.setSelectionRange(newCaretPos, newCaretPos);
    this.commentInputElement.nativeElement.focus();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydownEvent(event: KeyboardEvent) {
    if (!this.isCommentInputFocus()) {
      return;
    }
    if (this.autocompleteMentionUser) {
      if (event.key === 'Enter') {
        event.preventDefault();
        const listOfUsers = this.autocompleteMentionUser.listOfUsers;
        const activeElementIndex = this.autocompleteMentionUser.activeElementIndex;
        const user = listOfUsers[activeElementIndex];
        this.autocompleteMention(user);
      }

      //autocomplete selection move using arrows
      if ((event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
        event.preventDefault();
        const currentActiveElementIndex = this.autocompleteMentionUser.activeElementIndex;
        const listOfUsersLength = this.autocompleteMentionUser.listOfUsers.length;
        const activeElementIndex = (() => {
          switch (event.key) {
            case 'ArrowUp':
              return Math.max(currentActiveElementIndex - 1, 0);
            case 'ArrowDown':
              return Math.min(currentActiveElementIndex + 1, listOfUsersLength - 1);
          }
        })();


        this.setActiveAutocompleteUser(activeElementIndex);
        this.scrollIntoAutocompleteUser(activeElementIndex);
      }
    } else if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      this.sendComment();
    }
  }

  setActiveAutocompleteUser(index: number) {
    this.autocompleteMentionUser = {
      ...this.autocompleteMentionUser,
      activeElementIndex: index,
    };
  }

  scrollToCommentsBottom(): void {
    const commentsSectionNative = this.commentsSectionElement.nativeElement;
    if (commentsSectionNative.clientHeight < commentsSectionNative.scrollHeight) {
      commentsSectionNative.scrollTop = commentsSectionNative.scrollHeight;
    }
  }

  private scrollToCommentInputBottom(): void {
    const textarea = this.commentInputElement.nativeElement;
    if (textarea.scrollHeight > textarea.clientHeight) {
      textarea.scrollTop = textarea.scrollHeight;
    }
  }

  private scrollIntoAutocompleteUser(index: number): void {
    const parent = this.commentsAutocompleteElement?.nativeElement;
    const activeElement = parent?.querySelector(
      `.comments-autocomplete-helper_list-element:nth-of-type(${index + 1})`
    );

    activeElement?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }

  private isCommentInputFocus(): boolean {
    return document.activeElement === this.commentInputElement.nativeElement;
  }

  private handleMentionAutocomplete(): void {
    // NOTE: also will be good to add listening to cursor movement using the keyboard
    // to show autocomplete when cursor on the word with @ symbol
    this.commentFormControl.valueChanges
      .pipe(
        debounceTime(INPUT_DEBOUNCE_TIME),
        filter(() => this.isCommentInputFocus()),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const textarea = this.commentInputElement.nativeElement;
        const text = textarea.value;
        const caretPos = textarea.selectionStart;

        // find the beginning of the word before caret
        let start = caretPos;
        while (start > 0 && /\S/.test(text[start - 1])) {
          start--;
        }

        // get the word before caret
        const word = text.substring(start, caretPos);
        const isTaggingWord = word.startsWith('@');
        const searchTerm = word.slice(1);

        const listOfUsers = isTaggingWord ? this.getFilteredUsers(searchTerm) : null;

        if (listOfUsers?.length) {
          const activeElementIndex = 0;
          this.autocompleteMentionUser = {
            searchTerm,
            listOfUsers,
            activeElementIndex,
            startPos: start,
            endPos: caretPos,
          };

          this.scrollIntoAutocompleteUser(activeElementIndex);
        } else {
          this.autocompleteMentionUser = null
        }
      });
  }

  private getFilteredUsers(searchTerm: string): UserModel[] {
    if (!searchTerm) {
      return this.listOfUsers();
    }

    const searchTermLowerCase = searchTerm.toLowerCase();
    return this.listOfUsers().filter((user) => {
      return user.userName?.toLowerCase().includes(searchTermLowerCase)
        || user.firstName?.toLowerCase().includes(searchTermLowerCase)
        || user.lastName?.toLowerCase().includes(searchTermLowerCase);
    });
  }
}

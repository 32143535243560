export enum FieldType {
  TextBlock,
  Group,
}

export interface IGroupModel {
  type: FieldType;
  id: number;
  children?: IGroupModel[];
  isExpanded?: boolean;
  parentId?: number;
}

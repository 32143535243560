<phar-base-control-template
  controlName="Number Input"
  [control]="control()"
  [question]="question"
  [builderMode]="builderMode"
  [isDraggable]="isDraggable"
  [isCommentsVisible]="isCommentsVisible"
  [canManageControl]="canManageControl"
  [showActionButtons]="(isControlActive$ | async) || (isControlHovered$ | async)"
  [highlightBackground]="isControlHighlighted$ | async"
  [hasConditionalRules]="hasConditionalRules()"
  (actionClick)="actionsHandler($event, control())">
  <div class="number-input-content w-100">
    <mat-form-field class="no-pointer-events flex-1">
      <!--<input [placeholder]="getPlaceholder(control.settings?.inputs[0].numberOfDecimals)" matInput type="number">-->
      <input [placeholder]="'1234.0'" matInput type="number">
    </mat-form-field>
    @if (control().settings?.inputs[1] && control().settings?.operator) {

      <span
        class="number-input-content_operator">{{ control().settings?.operator }}
    </span>
    }

    @if (control().settings?.inputs[1]) {
      <mat-form-field class="no-pointer-events flex-1">
        <input [placeholder]="getPlaceholder(control().settings.inputs[1].numberOfDecimals)" matInput type="number">
      </mat-form-field>
    }

    @if (control().settings?.descriptor) {
      <span
        [innerHTML]="control().settings.descriptor | safeHtml"
        class="number-input-content_descriptor">
    </span>
    }
  </div>
</phar-base-control-template>

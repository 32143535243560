import { createAction, props } from '@ngrx/store';
import { AssignModel } from '../assign.model';

export enum ActionTypes {
  GET_ASSIGNS_BY_PROJECT_ID = 'GET_ASSIGNS_BY_PROJECT_ID',
  UPDATE_ASSIGN_LIST = 'UPDATE_ASSIGN_LIST',
  RESET_ASSIGN_LIST = 'RESET_ASSIGN_LIST',
  GET_ASSIGN_BY_ID = 'GET_ASSIGN_BY_ID',
  UPDATE_CURRENT_ASSIGN = 'UPDATE_CURRENT_ASSIGN',
  RESET_CURRENT_ASSIGN = 'RESET_CURRENT_ASSIGN',
  UPDATE_ASSIGN_FIELD = 'UPDATE_ASSIGN_FIELD',
  DELETE_ASSIGN = 'DELETE_ASSIGN',
  REMOVE_ASSIGN_FROM_LIST = 'REMOVE_ASSIGN_FROM_LIST',
  UPDATE_ASSIGN_ORDER = 'UPDATE_ASSIGN_ORDER',
  LOAD_COMMENTS_COUNTERS = 'LOAD_COMMENTS_COUNTERS',
  POPULATE_COMMENTS_COUNTERS = 'POPULATE_COMMENTS_COUNTERS',
}

export const getAssignsByProjectId = createAction(ActionTypes.GET_ASSIGNS_BY_PROJECT_ID, props<{
  projectId: number
}>());
export const updateAssignList = createAction(ActionTypes.UPDATE_ASSIGN_LIST, props<{ assigns: AssignModel[] }>());
export const resetAssignList = createAction(ActionTypes.RESET_ASSIGN_LIST);
export const getAssignById = createAction(ActionTypes.GET_ASSIGN_BY_ID, props<{ id: number }>());
export const updateCurrentAssign = createAction(ActionTypes.UPDATE_CURRENT_ASSIGN, props<{ assign: AssignModel }>());
export const resetCurrentAssign = createAction(ActionTypes.RESET_CURRENT_ASSIGN);
export const updateAssignField = createAction(ActionTypes.UPDATE_ASSIGN_FIELD, props<{
  field: string,
  value: string | number | any
}>());
export const deleteAssign = createAction(ActionTypes.DELETE_ASSIGN, props<{ id: number, deleteGroup?: boolean }>());
export const removeAssignFromList = createAction(ActionTypes.REMOVE_ASSIGN_FROM_LIST, props<{ id: number }>());
export const updateAssignOrder = createAction(ActionTypes.UPDATE_ASSIGN_ORDER, props<{
  items: any[]
}>());
export const loadAssignmentsCommentsCounters = createAction(ActionTypes.LOAD_COMMENTS_COUNTERS);
export const populateCommentsCounters = createAction(ActionTypes.POPULATE_COMMENTS_COUNTERS, props<{
  counters: any[]
}>());



/* src/app/shared/phar-controls/phar-timepicker-field/phar-timepicker-control/phar-timepicker-control.component.scss */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.mfm-timepicker-control {
  position: relative;
  display: flex;
  width: 45px;
  height: 24px;
  padding: 0 5px;
  box-sizing: border-box;
}
.mfm-timepicker-control--active:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: deepskyblue;
}
.mfm-timepicker-control__input {
  width: 100%;
  height: 100%;
  padding: 0 5px 0 0;
  border: 0;
  font-size: 1rem;
  color: inherit;
  outline: none;
  text-align: left;
}
.mfm-timepicker-control__input:disabled {
  background-color: transparent;
  opacity: 0.6;
}
.mfm-timepicker-control__arrows {
  position: absolute;
  right: 2px;
  top: 1px;
  display: flex;
  flex-direction: column;
}
.mfm-timepicker-control__arrow {
  font-size: 8px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: color 0.2s;
  -webkit-user-select: none;
  user-select: none;
}
.mfm-timepicker-control__arrow:hover {
  color: rgba(0, 0, 0, 0.9);
}
.mfm-timepicker-control.disabled .mfm-timepicker-control__arrows {
  opacity: 0.5;
}
.mfm-timepicker-control.disabled .mfm-timepicker-control__arrows:hover {
  opacity: 0.5;
}
.mfm-timepicker-control.disabled .mfm-timepicker-control__arrows .mfm-timepicker-control__arrow:hover {
  cursor: default !important;
  opacity: 0.5 !important;
}
/*# sourceMappingURL=phar-timepicker-control.component.css.map */

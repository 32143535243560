import { MediaQuerySizes } from '../models/ui.state';
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  LOADING = 'LOADING',
  GLOBAL_LOADING = 'GLOBAL_LOADING',
  SETLISTFILTER = 'SETLISTFILTER',
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
  SET_MEDIA_QUERY = 'SET_MEDIA_QUERY',
  ACTIVATE_MEDIA_QUERY = 'ACTIVATE_MEDIA_QUERY',
  TOGGLE_HEADER = 'TOGGLE_HEADER',
  SET_THIRD_LEVEL_MENU_ELEMENTS = 'SET_THIRD_LEVEL_MENU_ELEMENTS',
  SET_CONTEXT_TITLE = 'SET_CONTEXT_TITLE',
  REMOVE_CONTEXT_TITLE = 'REMOVE_CONTEXT_TITLE',
  UPDATE_CONTEXT_TITLE = 'UPDATE_CONTEXT_TITLE',
  SET_SORTING = 'SET_SORTING',
  SET_DRAGGED = 'SET_DRAGGED',
  SET_DRAGGED_HELPER = 'SET_DRAGGED_HELPER',
  SET_RESIZED = 'SET_RESIZED',
  SET_REDIRECT_URL = 'SET_REDIRECT_URL',
  ERROR_POPUP = 'ERROR_POPUP',
  SET_MSG_POPUP = 'SET_MSG_POPUP'
}

export const loading = createAction(ActionTypes.LOADING, (loading: boolean) => ({ loading }));
export const globalLoading = createAction(ActionTypes.GLOBAL_LOADING, (loading: boolean) => ({ loading }));
export const errorPopup = createAction(ActionTypes.ERROR_POPUP, props<{ error: string }>());
export const setMediaQuery = createAction(ActionTypes.SET_MEDIA_QUERY, props<{ mediaQuery: string, size: string }>());
export const activateMediaQuery = createAction(ActionTypes.ACTIVATE_MEDIA_QUERY, props<{ size: MediaQuerySizes }>());
export const setListFilter = createAction(ActionTypes.SETLISTFILTER, props<{ filter: string }>());
export const toggleSidebar = createAction(ActionTypes.TOGGLE_SIDEBAR, props<{ sidebar: string }>());
export const toggleHeader = createAction(ActionTypes.TOGGLE_HEADER, props<{ header: boolean }>());
export const setThirdLevelMenuElements = createAction(ActionTypes.SET_THIRD_LEVEL_MENU_ELEMENTS, props<{
  menuElements: any[]
}>());
export const setContextTitle = createAction(ActionTypes.SET_CONTEXT_TITLE, props<{
  title: string,
  position: number
}>());
export const removeContextTitle = createAction(ActionTypes.REMOVE_CONTEXT_TITLE, props<{ position: number }>());
export const updateContextTitle = createAction(ActionTypes.UPDATE_CONTEXT_TITLE, props<{
  title: string,
  position: number
}>());
export const setSorting = createAction(ActionTypes.SET_SORTING, props<{ sorting: string }>());
export const setDragged = createAction(ActionTypes.SET_DRAGGED, props<{ dragged: boolean }>());
export const setDraggedHelper = createAction(ActionTypes.SET_DRAGGED_HELPER, props<{ draggedHelper: any }>());
export const setResized = createAction(ActionTypes.SET_RESIZED, props<{ resized: boolean }>());
export const setRedirectUrl = createAction(ActionTypes.SET_REDIRECT_URL, props<{ url: string }>());
export const messagePopup = createAction(ActionTypes.SET_MSG_POPUP, props<{ message: string }>());

import { Pipe, PipeTransform } from '@angular/core';
import { IListFilter, IListFilterDisplayOption } from './list-filter.interface';

@Pipe({
  name: 'manageFilters',
  standalone: true,
})

export class ManageFiltersPipe implements PipeTransform {
  transform(filter: IListFilterDisplayOption[], activeFilters: IListFilter[]): IListFilterDisplayOption[] {
    if (!filter) {
      return [];
    }
    return filter.map((filter: IListFilterDisplayOption) => ({
      ...filter,
      disabled: activeFilters.some((activeFilter: IListFilter) => activeFilter.field === filter.field)
    }))
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ListViewConfigModel } from './list-view-model';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

@Component({
  selector: 'phar-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit {
  @Input() dataSource: any[] = [];
  @Input() virtualScroll = true;
  @Input() config: ListViewConfigModel = {} as ListViewConfigModel;
  @Input() noDataMessage = 'No Data';
  @Input() parentHeight: number;
  @Input() itemHeight: number = 55;
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  @Output() afterActionClicked: EventEmitter<any> = new EventEmitter();

  @Output() afterClick: EventEmitter<any> = new EventEmitter();

  @Output() afterSelect: EventEmitter<any> = new EventEmitter();
  gridColumns = '';
  needRestartSorting = false;

  constructor() {
  }

  ngOnInit(): void {
    this.cleanOrder();
    this.gridColumns = this.config.columns.map(item => item.size).join(' ');
  }

  onClickHandler(dataItem: any): void {
    this.afterClick.emit({ dataItem });
  }

  onOrder(column: any): void {
    if (!column) {
      return;
    }

    const orderDescriptor = column.order;
    this.config.columns.map(c => {
      if (c.order !== undefined) {
        c.order = false;
      }
      return c;
    });
    column.order = !orderDescriptor;
    const newColumnOrder = column.order ? column.field : `-${column.field}`;
    if (this.config.order === column.field && newColumnOrder === `-${column.field}`) {
      if (this.needRestartSorting) {
        column.order = false;
        delete this.config.order;
        this.needRestartSorting = false;
        return;
      } else {
        column.order = true;
        return;
      }

    }
    if (!this.config.order) {
      this.needRestartSorting = true;
      column.order = false;
      this.config.order = `-${column.field}`
      return;
    }
    this.config.order = newColumnOrder;

  }

  cleanOrder() {
    this.config.columns.map(column => {
      if (column.order !== undefined) {
        column.order = column.order;
      }
      return column;
    });
  }

}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input, OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../base.class';
import { FilterType } from './filter-item.interface';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { INPUT_DEBOUNCE_TIME } from '../../core/config/app.constants';
import { IListFilter, IListFilterDisplayOption, IListFilterOptions, ListFilter } from './list-filter.interface';
import { FieldType } from '../draggable-list-view/models/group-model';

@Component({
  selector: 'phar-list-filter',
  templateUrl: 'list-filter.component.html',
  styleUrl: 'list-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ListFilterComponent extends BaseComponent implements OnInit, OnDestroy {

  @Input() searchPlaceholder = 'Search';
  @Input({ required: true }) searchField: string;
  @Input() enableFilters = true;
  @Input() displayedFilterOptions: IListFilterDisplayOption[];
  @Input() activeFilters: ListFilter[];
  @Input() resultCounter: number;
  @Input() showCounter = false;
  @Input() mainFilterOptions: IListFilterOptions = null;

  @Output() filterAdd = new EventEmitter<{ filter: IListFilter, mainFilter: boolean }>();
  @Output() filterUpdate: EventEmitter<{
    action: 'update' | 'delete',
    filter: ListFilter | ListFilter[]
  }> = new EventEmitter<{
    action: 'update' | 'delete',
    filter: ListFilter | ListFilter[]
  }>();
  searchFormCtrl: FormControl<string> = new FormControl<string>('');

  constructor() {
    super();
  }

  clearValue(): void {
    this.searchFormCtrl.setValue('');
  }

  ngOnInit() {
    this.searchFormCtrl.valueChanges.pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      takeUntil(this.destroy$),
    ).subscribe({
      next: (value) => {
        if (value) {
          //emulate selected string filter
          this.filterAdd.emit({
            filter: {
              field: this.searchField,
              value,
              type: FilterType.String,
              label: '',
              options: this.mainFilterOptions
            },
            mainFilter: true
          });
          return;
        }

        const searchFilter = this.getSearchFilter();
        if (searchFilter) {
          this.filterUpdate.emit({ action: 'delete', filter: searchFilter });
        }
      }
    });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  addFilter(filter: IListFilterDisplayOption): void {
    this.filterAdd.emit({
      filter: { field: filter.field, value: null, type: filter.type, label: filter.label, options: filter.options },
      mainFilter: this.searchField === filter.field,
    })
  }

  clearAllFilters(): void {
    this.clearValue();
    this.filterUpdate.emit({ action: 'delete', filter: this.activeFilters });
  }

  getSearchFilter(): ListFilter | null {
    if (!this.activeFilters.length) {
      return null;
    }

    const searchFilter = this.activeFilters.find((activeFiler) => {
      return activeFiler.field === this.searchField;
    });

    return searchFilter ? searchFilter : null;
  }

}

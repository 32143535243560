import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ListFilter } from '../list-filter.interface';
import { FilterType } from '../filter-item.interface';
import { FormControl } from '@angular/forms';
import { BaseComponent } from '../../base.class';
import { debounceTime, filter, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { INPUT_DEBOUNCE_TIME } from '../../../core/config/app.constants';
import { isNumber, isObject } from 'lodash-es';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'phar-filter-item',
  templateUrl: 'filter-item.component.html',
  styleUrl: './filter-item.component.scss'
})

export class FilterItemComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input({ required: true }) filter: ListFilter;
  @ViewChild('autoCompleteInput') autoCompleteInput: ElementRef<HTMLInputElement>;
  @Output() filterUpdate: EventEmitter<{
    action: 'update' | 'delete',
    filter: ListFilter | ListFilter[]
  }> = new EventEmitter<{
    action: 'update' | 'delete',
    filter: ListFilter
  }>();
  filterFormCtrl = new FormControl();
  autoCompleteFormCtrl = new FormControl();
  showDelete = false;

  filteredValues$: Observable<{ id: string | number, label: string }[]>;
  tempData: any;
  dataConfirmed = false;
  protected readonly FilterType = FilterType;

  ngOnInit() {
    this.filterFormCtrl.valueChanges.pipe(
      debounceTime(INPUT_DEBOUNCE_TIME),
      filter(() => !this.isManualFilterUpdate()), // when multiple choices are presented we should manually call filterUpdate
      filter((value) => this.validValue(value)),
      takeUntil(this.destroy$),
    ).subscribe({
      next: (value: any) => {
        this.filterUpdate.emit({ action: 'update', filter: { ...this.filter, value } as ListFilter });
      }
    });
    if (this.filter.type === FilterType.AutoCompleter) {
      this.filteredValues$ = this.autoCompleteFormCtrl.valueChanges.pipe(
        startWith(null),
        debounceTime(INPUT_DEBOUNCE_TIME),
        withLatestFrom(this.filter.options.data),
        map(([value, data]) => {
            return value && !isObject(value) ? this._filter(value, data) : data.slice()
          }
        ),
        map((elements) => {
          const selected = this.filterFormCtrl.value || [];
          if (!selected.length) {
            return elements
          }
          return elements.filter(item => !(selected.find(selectedItem => selectedItem.id === item.id)));
        }),
      )
    }


    if (this.filter.options?.value !== undefined) {
      this.filterFormCtrl.setValue(this.filter.options.value);
      if (this.isMultipleChoice()) {
        this.applyValues();
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  applyValues(select?: MatSelect) {
    this.filterUpdate.emit({
      action: 'update',
      filter: { ...this.filter, value: this.filterFormCtrl.value } as ListFilter
    });
    this.dataConfirmed = true;
    select?.close();

  }


  panelOpen() {
    this.dataConfirmed = false;
    if (!this.isMultipleChoice()) {
      return;
    }
    this.tempData = this.filterFormCtrl.value;
  }

  panelClose(): void {
    if (!this.isMultipleChoice()) {
      return;
    }
    if (!this.dataConfirmed) {
      this.filterFormCtrl.setValue(this.tempData, { emitEvent: false });
    }
    this.tempData = null;
  }

  deleteFilter(): void {
    this.filterUpdate.emit({ action: 'delete', filter: this.filter });
  }

  isManualFilterUpdate(): boolean {
    return this.isMultipleChoice() && this.filter.type === FilterType.Dropdown;
  }

  isMultipleChoice(): boolean {
    return !!this.filter.options?.multiple
  }

  validValue(value: any): boolean {
    switch (this.filter.type) {
      case FilterType.Number:
        return this.isValidNumber(value);
      default:
        return true;
    }
  }

  removeAutoCompleteValue(value: { id: string | number, label: string }): void {
    const values = this.filterFormCtrl.value;
    this.filterFormCtrl.setValue(values.filter(x => x.id !== value.id));
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    const currentValues = this.filterFormCtrl.value ?? [];
    const alreadyExist = currentValues.some(item => item.id === event.option.value.id);
    if (alreadyExist) {
      return;
    }
    this.filterFormCtrl.setValue([...this.filterFormCtrl.value ?? [], event.option.value]);
    this.autoCompleteFormCtrl.setValue(null)
    this.autoCompleteInput.nativeElement.value = '';
  }


  private _filter(value: string, options: { id: string | number, label: string }[]): {
    id: string | number,
    label: string
  }[] {
    const filterValue = value.toLowerCase();

    return options.filter(item => item.label.toLowerCase().includes(filterValue));
  }

  private isValidNumber(value: any): boolean {
    const val = Number(value);
    if (val === undefined || val === null) {
      return true;
    }

    return !isNaN(val) && isNumber(val);
  }

}

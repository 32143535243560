import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, DoCheck,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self, ViewChild,
} from '@angular/core';
import {
  NgControl, Validators, FormGroup, FormControl,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { TimePeriod } from './enums/time-period.enum';
import { TimeUnit } from './enums/time-unit.enum';
import { omitBy, isEmpty, isNull } from 'lodash-es';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { BaseComponent } from '../../base.class';
import { FormControls } from '../../types/form-controls.type';
import { PharTimepickerControlComponent } from './phar-timepicker-control/phar-timepicker-control.component';

type SpecificTime = {
  hours: string;
  minutes: string;
  seconds: string;
  period: string;
}

type SpecificTimeFormGroup = FormControls<SpecificTime>;

@Component({
  selector: 'phar-timepicker-field',
  templateUrl: './phar-timepicker-field.component.html',
  styleUrls: ['./phar-timepicker-field.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: PharTimepickerFieldComponent,
    }
  ],
  /* eslint-disable-next-line*/
  host: {
    '[class.example-floating]': 'shouldLabelFloat',
    '[id]': 'id',
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PharTimepickerFieldComponent extends BaseComponent implements MatFormFieldControl<string>, OnInit, OnDestroy, DoCheck {
  static ngAcceptInputType_disabled: BooleanInput;
  static ngAcceptInputType_required: BooleanInput;
  static nextId = 0;
  @ViewChild('minutesElement') minutesElement: PharTimepickerControlComponent;
  form: FormGroup<SpecificTimeFormGroup> = new FormGroup<SpecificTimeFormGroup>({
    hours: new FormControl<string>('', [Validators.required, Validators.min(0)]),
    minutes: new FormControl<string>('', [Validators.required, Validators.min(0)]),
    seconds: new FormControl<string>(''),
    period: new FormControl<string>({ value: TimePeriod.AM as string, disabled: true })
  });

  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'mfm-timepicker-field';
  id = `mfm-timepicker-field-${PharTimepickerFieldComponent.nextId++}`;

  timeUnit = TimeUnit;
  minHour = 1;
  maxHour = 12;

  get empty(): boolean {
    return this.form.invalid;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input() autoNavigateToMinutes = false;

  /* eslint-disable @angular-eslint/no-input-rename */
  @Input('aria-describedby') userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }


  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }


  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.form.disable() : this.form.enable();
    this.togglePeriod(this.withPeriod);
    this.stateChanges.next();
  }

  format = 24;
  withSeconds = false;

  @Input() set showSeconds(value: boolean) {
    this.withSeconds = value;
    if (value) {
      this.form.get('seconds').setValidators([Validators.required, Validators.min(0)]);
      this.form.get('seconds').updateValueAndValidity();
    }
  };

  @Input({ required: true }) set withPeriod(value: boolean) {
    this.format = value ? 12 : 24;
    this.minHour = value ? 1 : 0;
    this.maxHour = value ? 12 : 23;
    this.togglePeriod(value);
  }

  @Input()
  get value(): string | null {
    return this.convertValueToString(this.form.value);
  }

  set value(value: string) {
    this.updateTime(value);
    this.stateChanges.next();
  }


  errorState = false;

  private _required = false;
  private _disabled = false;
  private _placeholder: string;

  onChange = (_: any) => {
  };
  onTouched = () => {
    this.form.markAllAsTouched();
  };

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl) {
    super();

    _focusMonitor.monitor(_elementRef, true).pipe(
      takeUntil(this.destroy$)
    ).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(100),
        takeUntil(this.destroy$),
      )
      .subscribe((v) => {
        if (!this.form.valid) {
          this.onChange(null);
          return;
        }
        const value = this.convertValueToString(v);
        this.onChange(value);
      });

    if (this.autoNavigateToMinutes) {
      this.form.controls.hours.valueChanges.pipe(
        debounceTime(300),
        takeUntil(this.destroy$),
      ).subscribe(() => {
        this.minutesElement.focusElement();
      })
    }
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  private convertValueToString(data: Partial<SpecificTime>): string {
    const onlyValues = omitBy(omitBy(data, isNull), isEmpty);
    return Object.values(onlyValues).join(':');
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement
      .querySelector('.mfm-timepicker')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(): void {
    this._elementRef.nativeElement.focus();
  }

  writeValue(value: string | null): void {
    if (value) {
      this.updateTime(value);
    } else {
      this.resetTime();
    }
    this.changeDetectorRef.markForCheck();

  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changePeriod(period: TimePeriod): void {
    this.form.get('period').setValue(period);
  }

  private updateTime(timeString: string): void {
    const spitTimeValues = timeString.split(':');
    if (spitTimeValues[0]) {
      this.form.get('hours').setValue(spitTimeValues[0]);
    }
    if (spitTimeValues[1]) {
      this.form.get('minutes').setValue(spitTimeValues[1]);
    }
    if (spitTimeValues[2]) {
      this.form.get('seconds').setValue(spitTimeValues[2]);
    }
    if (spitTimeValues[3]) {
      this.form.get('period').setValue(spitTimeValues[3]);
    }
  }


  private resetTime(): void {
    this.form.setValue({
      minutes: null,
      seconds: null,
      hours: null,
      period: null
    });
    this.stateChanges.next()
  }

  private togglePeriod(enable: boolean): void {
    if (enable) {
      this.form.get('period').enable();
    } else {
      this.form.get('period').disable();
    }
  }
}

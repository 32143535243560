import { UiState } from '../models/ui.state';
import { AppState, appState } from '../models/app.state';
import { Action, createReducer, createSelector, on } from '@ngrx/store';
import * as uiActions from '../actions/ui.actions';

const ui = createReducer(appState.ui,
  on(uiActions.loading, (state, { loading }) => ({ ...state, loading })),
  on(uiActions.globalLoading, (state, { loading }) => ({ ...state, globalLoading: loading })),
  on(uiActions.setListFilter, (state, { filter }) => ({ ...state, listFilter: filter })),
  on(uiActions.toggleSidebar, (state, { sidebar }) => ({ ...state, sidebar })),
  on(uiActions.setMediaQuery, (state, { mediaQuery, size }) => ({
    ...state,
    mediaQuery: { ...state.mediaQuery, [size]: mediaQuery }
  })),
  on(uiActions.activateMediaQuery, (state, { size }) => ({
    ...state,
    mediaQuery: { ...state.mediaQuery, active: size }
  })),
  on(uiActions.toggleHeader, (state, { header }) => ({ ...state, header })),
  on(uiActions.setThirdLevelMenuElements, (state, { menuElements }) => ({
    ...state,
    thirdLevelMenuElements: menuElements
  })),
  on(uiActions.setContextTitle, (state, { title, position }) => {
    return {
      ...state, contextTitlesList: [
        ...[...state.contextTitlesList.slice(0, position - 1)],
        title
      ]
    };
  }),
  on(uiActions.removeContextTitle, (state, { position }) => ({
    ...state,
    contextTitlesList: [...[...state.contextTitlesList.slice(0, position - 1)]]
  })),
  on(uiActions.updateContextTitle, (state, { position, title }) => ({
    ...state, contextTitlesList: [
      ...[...state.contextTitlesList.slice(0, position - 1)],
      title,
      ...[...state.contextTitlesList.slice(position)]
    ]
  })),
  on(uiActions.setSorting, (state, { sorting }) => ({ ...state, sorting })),
  on(uiActions.setDragged, (state, { dragged }) => ({ ...state, dragged })),
  on(uiActions.setDraggedHelper, (state, { draggedHelper }) => ({ ...state, draggedHelper })),
  on(uiActions.setResized, (state, { resized }) => ({ ...state, resized })),
  on(uiActions.setRedirectUrl, (state, { url }) => ({ ...state, redirectUrl: url })),
  on(uiActions.errorPopup, (state, { error }) => ({ ...state, error })),
  on(uiActions.messagePopup, (state, { message }) => ({ ...state, message })),
);

export function uiReducer(state: UiState, action: Action): UiState {
  return ui(state, action);
}

export const selectUi = (state: AppState) => state.ui;
export const selectListFilter = createSelector(
  selectUi,
  (state: UiState) => state.listFilter
);
export const selectHeader = createSelector(
  selectUi,
  uiState => uiState.header
);


// default:
// // fix error in development mode. contextTitlesList was undefined
// state.contextTitlesList = state.contextTitlesList || appState.ui.contextTitlesList;
// return state;


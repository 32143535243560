import { Injectable } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { fromPairs } from 'lodash-es';

import { AppState, getState } from '../../../store/models/app.state';
import { updateControlField } from '../../../store/actions/control.actions';
import { updateDataset, updateDatasetField } from '../../../dataset/store/dataset.actions';

import { DatasetDataTypeEnum } from '../../../dataset/dataset-data-type.enum';
import { DatasetFieldTypeEnum } from '../../../dataset/dataset-field-type.enum';
import { FormElementsEnum } from '../../../form/form-elements.enum';
import { DatasetFieldModel } from '../../../dataset/dataset-field.model';
import { ControlModel } from '../../control.model';

import { UtilsService } from '../../../core/utils.service';

@Injectable()
export class DataFieldBindingService {
  private readonly valuesBindingControlTypes = [
    FormElementsEnum.MultiSelect,
    FormElementsEnum.MultiSelectQuantity,
    FormElementsEnum.CheckBox,
  ];

  constructor(
    private store: Store<AppState>,
    private utilsService: UtilsService,
  ) {
  }

  bindDataFieldToTheControl(control: ControlModel, shouldUpdateControlState: boolean = true): ControlModel | null {
    if (!control) {
      return null;
    }

    if (this.isValuesBindingControl(control)) {
      const controlType = control.controlType as FormElementsEnum;
      const values = this.bindDataFieldsToTheControlValues(controlType, control.values, shouldUpdateControlState);

      return {
        ...control,
        values,
      };
    }

    const currentDataset = getState(this.store).dataset.current.dataset;
    const currentDataFields = { ...currentDataset.data } as Record<string, DatasetFieldModel>;
    const currentDataField = Object.values(currentDataFields)
      .find(({ dataFieldId }) => control.bindDataField === dataFieldId);

    const newDataFieldName = `${control.label.trim().split(' ').join('_')}`;
    const newDataField: DatasetFieldModel | null = (() => {
      if (!currentDataField) {
        return this.createDataField(newDataFieldName, control.controlType);
      }

      if (newDataFieldName !== currentDataField.displayName) {
        delete currentDataFields[currentDataField.name];
        return {
          ...currentDataField,
          name: this.getNameWithTimestamp(newDataFieldName),
          displayName: newDataFieldName,
        };
      }

      return null;
    })();

    if (!newDataField) {
      return null;
    }

    const newDataFields = {
      ...currentDataFields,
      [newDataField.name]: newDataField,
    };
    this.updateDataFieldsState(newDataFields);

    if (control.bindDataField === newDataField.dataFieldId) {
      return null;
    }

    if (shouldUpdateControlState) {
      this.updateControlBindDataFieldState(newDataField);
    }

    return {
      ...control,
      bindDataField: newDataField.dataFieldId,
    };
  }

  bindDataFieldsToTheControlValues(
    controlType: string,
    values: { label: string, value: string }[],
    shouldUpdateControlState: boolean = true
  ): { label: string, value: string }[] {
    const currentDataset = getState(this.store).dataset.current.dataset;
    const currentDataFields = { ...currentDataset.data } as Record<string, DatasetFieldModel>;

    const newControlValueDataFieldsArray: DatasetFieldModel[] = values.map(({ label, value }) => {
      const currentDataField = Object.values(currentDataFields)
        .find(({ dataFieldId }) => value === dataFieldId);

      const newDataFieldName = `${label.trim().split(' ').join('_')}`;
      const newDataField: DatasetFieldModel | null = (() => {
        if (!currentDataField) {
          return this.createDataField(newDataFieldName, controlType);
        }

        delete currentDataFields[currentDataField.name];
        if (newDataFieldName === currentDataField.displayName) {
          return currentDataField;
        }

        return {
          ...currentDataField,
          name: this.getNameWithTimestamp(newDataFieldName),
          displayName: newDataFieldName,
        };
      })();

      return newDataField;
    });

    const newControlValueDataFields = fromPairs(
      newControlValueDataFieldsArray.map(dataField => [dataField.name, dataField])
    );
    const newDataFields = {
      ...currentDataFields,
      ...newControlValueDataFields,
    };
    const newValues = values.map((value, index) => ({
      ...value,
      value: newControlValueDataFieldsArray[index].dataFieldId,
    }));

    this.updateDataFieldsState(newDataFields);
    if (shouldUpdateControlState) {
      this.updateControlValuesState(newValues);
    }

    return newValues;
  }

  removeControlsDataFields(controls: ControlModel[]): void {
    const dataFieldIds = this.getControlsDataFieldIds(controls);
    this.removeDataFields(dataFieldIds);
  }

  removeControlDataFields(control: ControlModel): void {
    const dataFieldIds = this.getControlsDataFieldIds([ control ]);
    this.removeDataFields(dataFieldIds);
  }

  getControlsDataFieldIds(controls: ControlModel[]): string[] {
    const nestedControlDataFieldIds: Array<string | string[]> = controls.map((control) => {
      if (control?.bindDataField) {
        return  control.bindDataField;
      }

      if (control?.values?.length && this.isValuesBindingControl(control)) {
        return  control.values.map(({ value }) => value);
      }

      return '';
    });
    const controlDataFieldIds = [].concat(...nestedControlDataFieldIds).filter(id => !!id);

    return controlDataFieldIds;
  }

  removeDataFields(dataFieldIds: string[]): void {
    if (!dataFieldIds ) {
      return;
    }

    const dataFields = { ...getState(this.store).dataset.current.dataset.data } as Record<string, DatasetFieldModel>;
    const dataFieldsToRemove = Object.values(dataFields)
      .filter(({ dataFieldId }) => dataFieldIds.includes(dataFieldId));

    dataFieldsToRemove.forEach((dataField) => {
      delete dataFields[dataField.name];
    });

    if (dataFieldsToRemove.length) {
      this.updateDataFieldsState(dataFields);
    }
  }

  saveDataFields(): void {
    this.store.select(state => state.dataset.current)
      .pipe(
        take(1),
        filter(({ pendingChanges }) => pendingChanges),
        map(({ dataset }) => dataset),
      )
      .subscribe(dataset => {
        this.store.dispatch(updateDataset({ dataset, success: false }));
      });
  }

  isValuesBindingControl(control: ControlModel): boolean {
    const controlType = control.controlType as FormElementsEnum;

    return this.valuesBindingControlTypes.includes(controlType);
  }

  private createDataField(dataFieldName: string, controlType: string): DatasetFieldModel | null {
    const dataset = getState(this.store).dataset.current.dataset;
    const dataFields = dataset.data;
    const controlTypes = this.getControlDataFieldAndDataSetType(controlType);

    if (!controlTypes) {
      return null;
    }
    const [ValueType, type] = controlTypes;

    let dataFieldOrder = 1
    Object.values(dataFields).forEach(({ order }) => {
      dataFieldOrder = order >= dataFieldOrder ? order + 1 : dataFieldOrder;
    });

    return {
      dataFieldId: this.utilsService.generateUUID(),
      displayName: dataFieldName,
      name: this.getNameWithTimestamp(dataFieldName),
      order: dataFieldOrder,
      ValueType,
      type,
      description: '',
      isRepeatable: false,
      parent: dataset.name,
      externalSettings: {
        system: {
          text: '',
          value: '',
        },
        write: false,
        config: '',
      }
    };
  }

  private getNameWithTimestamp(dataFieldName: string): string {
    return `${dataFieldName}_${new Date().getTime()}`;
  }

  private updateControlBindDataFieldState(dataField: DatasetFieldModel): void {
    this.store.dispatch(updateControlField({
      field: 'bindDataField',
      value: `${dataField.dataFieldId}`
    }));
  }

  private updateControlValuesState(values: { label: string, value: string }[]): void {
    this.store.dispatch(updateControlField({
      field: 'values',
      value: values
    }));
  }

  private updateDataFieldsState(dataFields: Record<string, DatasetFieldModel>): void {
    const dataset = getState(this.store).dataset.current.dataset;

    this.store.dispatch(updateDatasetField({
      field: 'data',
      value: dataFields,
    }));
    const valueTypes: { Name: string, ValueType: DatasetDataTypeEnum }[] = [];
    const datasetSchema = this.utilsService.createDatasetSchema(dataFields, dataset.name, valueTypes);
    this.store.dispatch(updateDatasetField({ field: 'datasetSchema', value: datasetSchema }));
    this.store.dispatch(updateDatasetField({ field: 'datasetFieldTypes', value: valueTypes }));
  }

  private getControlDataFieldAndDataSetType(controlType: string): [DatasetFieldTypeEnum, DatasetDataTypeEnum] | null {
    switch (controlType) {
      case FormElementsEnum.Number:
      case FormElementsEnum.NumericRatingScale:
        return [DatasetFieldTypeEnum.number, DatasetDataTypeEnum.Number];
      case FormElementsEnum.TimePicker:
      case FormElementsEnum.TextInput:
      case FormElementsEnum.SingleSelect:
      case FormElementsEnum.VerbalRatingScale:
      case FormElementsEnum.TextBlock:
        return [DatasetFieldTypeEnum.string, DatasetDataTypeEnum.String];
      case FormElementsEnum.GeneralBoolean:
      case FormElementsEnum.Boolean:
        return [DatasetFieldTypeEnum.boolean, DatasetDataTypeEnum.Boolean];
      case FormElementsEnum.VisualAnalogScale:
        return [DatasetFieldTypeEnum.float, DatasetDataTypeEnum.Float];
      case FormElementsEnum.DatePicker:
        return [DatasetFieldTypeEnum.date, DatasetDataTypeEnum.Date];
      case FormElementsEnum.MultiSelect:
      case FormElementsEnum.MultiSelectQuantity:
      case FormElementsEnum.CheckBox:
        return [DatasetFieldTypeEnum.boolean, DatasetDataTypeEnum.Boolean];
      default:
        return null;
    }
  }
}

import { Injectable } from '@angular/core';
import { AppConfig } from '../core/config/app.config';
import { Store } from '@ngrx/store';
import { AppState, getState } from '../store/models/app.state';
import { FormModel, FormStatusEnum, FormTypeEnum } from './form.model';
import { Observable, of } from 'rxjs';
import { PharHttp } from '../core/http/http-client.service';
import { ProjectModel } from '../project/project.model';

@Injectable()
export class FormService {
  constructor(
    private http: PharHttp,
    private config: AppConfig,
    private store: Store<AppState>
  ) {
  }

  create(form: FormModel) {
    const formBody = { ...form };
    delete formBody.id;
    delete formBody.datasetId;
    formBody.organizationId = getState(this.store).user.profile.organizationId;
    formBody.userIdCreated = getState(this.store).user.profile.userId;
    return this.http.post(this.config.buildUri('form') + '/CreateFormWithDS', formBody);
  }

  update(form) {
    form.userIdUpdated = getState(this.store).user.profile.userId;
    return this.http.put(this.config.buildUri('form') + '/Form', form);
  }

  getFormList(statuses?: FormStatusEnum[]): Observable<FormModel[]> {
    return this.http.post(this.config.buildUri('form') + '/Forms', statuses || []);
  }

  getFormListWithStudies(): Observable<FormModel[]> {
    return this.http.post(this.config.buildUri('form') + '/FindStudiesWithAssignedForms');
  }

  getFormListInProjectContext(ids: number[]): Observable<FormModel[]> {
    return this.getFormListByIds(ids);
  }

  getFormListByIds(ids: number[]): Observable<FormModel[]> {
    return this.http.post(this.config.buildUri('form') + '/FormsByIds', ids);
  }

  getFormListByDatasetId(id: number): Observable<FormModel[]> {
    return this.http.get(this.config.buildUri('form') + '/FormsPerDataset?datasetId=' + id);
  }

  searchById(id: number): Observable<FormModel> {
    return this.http.get(this.config.buildUri('form') + '/FormById/?id=' + id);
  }

  deleteForm(id: number): Observable<any> {
    return this.http.delete(this.config.buildUri('form') + '/Form/?id=' + id);
  }

  assignFormToUser(data) {
    return this.http.post(this.config.buildUri('form') + '/AssignFormToUsers', data);
  }

  getExportFormJson(id: number): Observable<string> {
    return this.http.get(this.config.buildUri('form') + '/ExportJson/?formId=' + id);
  }

  duplicateForm(id: number): Observable<FormModel> {
    return this.http.post(this.config.buildUri('form') + '/DuplicateForm?formId=' + id, {});
  }

  archiveForm(id: number): Observable<boolean> {
    return this.http.post(this.config.buildUri('form') + '/ArchiveForm?formId=' + id, {});
  }

  getProjectsShorts(): Observable<Partial<ProjectModel>[]> {
    return this.http.get(this.config.buildUri('project') + '/GetAllProjectsShort');
  }

  amendForm(formId: number) {
    return this.http.post(this.config.buildUri('form') + `/AmendForm?formId=` + formId, {});
  }


  getFormTypes(): Observable<{ id: FormTypeEnum, label: string }[]> {
    return of([
      { id: FormTypeEnum.DataCapture, label: 'ePRO' },
      { id: FormTypeEnum.Questionnaire, label: 'eClinRO' },
      // { id: FormTypeEnum.EObsRO, label: 'eObsRO' },
      { id: FormTypeEnum.InformedConsent, label: 'Informed Consent' },
    ]);
  }
}

import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[pharNoDataField]'
})
export class NoDataFieldDirective implements OnChanges {
  @Input() dataField: string;
  @Input() noDataFieldClass: string = 'no-data-field';


  constructor(private el: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('dataField')) {
      this.toggleInvalidClass();
    }
  }

  private toggleInvalidClass() {
    if (!this.dataField || this.dataField === '') {
      this.el.nativeElement.classList.add(this.noDataFieldClass);
    } else {
      this.el.nativeElement.classList.remove(this.noDataFieldClass);
    }
  }
}

/* src/app/shared/number-range/number-range.component.scss */
.input-container {
  display: flex;
}
.input-element {
  width: 50%;
  border: none;
  background: none;
  padding: 0;
  outline: none;
  font: inherit;
  text-align: center;
  color: currentcolor;
}
/*# sourceMappingURL=number-range.component.css.map */

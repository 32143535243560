import { Pipe, PipeTransform } from '@angular/core';
import { FormTypeEnum } from '../form.model';

@Pipe({
  name: 'pharFormType',
  standalone: true,
})

export class FormTypePipe implements PipeTransform {
  transform(formType: number): any {
    switch (formType) {
      case FormTypeEnum.DataCapture:
        return 'ePRO';
      case FormTypeEnum.Questionnaire:
        return 'eClinRO';
      case FormTypeEnum.EObsRO:
        return 'eObsRo';
      case FormTypeEnum.InformedConsent:
        return 'Informed Consent';
      case FormTypeEnum.All:
        return 'All';
      case FormTypeEnum.Report:
        return 'Report';
      default:
        return formType;
    }
  }
}

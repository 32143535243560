<div
  class="p-3 button-block_parent"
  [ngClass]="{ 'highlight-background': isHighlighted, 'pb-4': hasConditionalRules }"
  [id]="'ctrl-' + control.controlID"
  (click)="actionsHandler(ControlActionsEnum.Edit, $event)">
  <div class="form-group">
    <label class="form-text">
      <span [innerHTML]="control?.label | safeHtml"></span>
      @if (isRequired) {
        <span>*</span>
      }
    </label>

    @if (control?.info) {
      <div
        class="custom-hint"
        [innerHTML]="control.info | safeHtml">
      </div>
    }
  </div>

  <ng-content></ng-content>

  @if (hasConditionalRules) {
    <div class="position-absolute b-0 r-0 d-flex align-center p-1 pe-2">
      <span class="custom-hint me-1">Conditional</span>
      <phar-help-icon helpText="This question has conditional rules" position="above"/>
    </div>
  }

  @if (builderMode && showActionButtons) {
    <div class="button-block_holder">
      @if (isDraggable) {
        <button
          mat-icon-button
          class="button-block_button cursor-move me-auto"
          draggable="true"
          pharNoPropagation
          (dragstart)="actionsHandler(ControlActionsEnum.DragStart, $event)"
          (dragend)="actionsHandler(ControlActionsEnum.DragEnd, $event)">
          <mat-icon class="button-block_small-icon" svgIcon="handle"></mat-icon>
        </button>
      }

      @if (canManageControl) {
        <button
          class="button-block_button"
          mat-icon-button
          matTooltipPosition="above"
          pharNoPropagation
          [matTooltip]="'Duplicate Question'"
          (click)="actionsHandler(ControlActionsEnum.Duplicate, $event)"
        >
          <mat-icon class="button-block_small-icon" svgIcon="duplicate"></mat-icon>
        </button>

<!--        <button-->
<!--          class="button-block_button button-block_button_edit hidden"-->
<!--          mat-icon-button-->
<!--          matTooltipPosition="above"-->
<!--          pharNoPropagation-->
<!--          [matTooltip]="'Save ' + controlName"-->
<!--          (click)="actionsHandler(ControlActionsEnum.Save, $event)">-->
<!--          <mat-icon class="button-block_small-icon" svgIcon="save"></mat-icon>-->
<!--        </button>-->

        <button
          class="button-block_button"
          mat-icon-button
          matTooltipPosition="above"
          pharNoPropagation
          [matTooltip]="'Remove ' + controlName"
          (click)="actionsHandler(ControlActionsEnum.Remove, $event)">
          <mat-icon class="button-block_small-icon" svgIcon="trash"></mat-icon>
        </button>
      }


    </div>
  }

  @if (isCommentsVisible) {
    <button
      class="button-block_button view-comments-button cursor-pointer"
      mat-icon-button
      pharTooltipPosition="above"
      pharNoPropagation
      [matMenuTriggerFor]="commentsMenu"
      (click)="$event.stopImmediatePropagation()"
      (menuOpened)="highlight()"
      (menuClosed)="highlight(false)"
      [pharTooltip]="'Comments'">
      <mat-icon
        class="button-block_small-icon"
        svgIcon="flag-1"
        [color]="colorMap[control.commentsState]">
      </mat-icon>
    </button>
  }
</div>

<mat-menu #commentsMenu="matMenu" [class]="'comments-container'" xPosition="before">
  <ng-template matMenuContent>
    <phar-question-comments-dialog
      [control]="control"
      [question]="question"
      [isViewOnly]="viewCommentsOnly$ | async"
      (requestClose)="handleCloseCommentsDialog()"/>
  </ng-template>
</mat-menu>

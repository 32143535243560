import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideInOut = trigger('slideInOut', [
  state('open', style({
    marginLeft: '200px',
    width: 'calc(100vw - 200px)'
  })),
  state('close', style({
    marginLeft: '56px',
    width: 'calc(100vw - 56px)'
  })),
  transition('open <=> close', animate('.2s ease-in-out'))
]);

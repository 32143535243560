import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';

import { PharBaseControlModelComponent } from '../phar-base-control/phar-base-control-model.component';
import { AppState } from '../../../store/models/app.state';

/**
 * PharMultiselectComponent component
 */
@Component({
  selector: 'phar-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
/**
 * PharMultiselectComponent class
 * @extends {PharBaseControlModelComponent}
 */
export class MultiSelectComponent extends PharBaseControlModelComponent {
  constructor(
    store: Store<AppState>,
  ) {
    super(store);
  }

  // get bindDataFields(): string {
  //   if (this.control.values && this.control.values.length) {
  //     const dataFields = this.control.values.filter(x => x.value !== null && x.value.trim() !== '').map(x => x.value);
  //     if (!dataFields.length) {
  //       return ''
  //     }
  //     return dataFields.join(', ');
  //   } else {
  //     return '';
  //   }
  // }

}

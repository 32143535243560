<div [ngClass]="{ 'mfm-tlm-wrapper': bottomBordered }" class="mfm-tlm d-flex p-0" id="mfm-tlm">
  <div [id]="(links | async)?.length ? 'mfm-tlm-wrapper-btn-group' : ''">
    <ng-container *ngFor="let link of (links | async)">
      <button [class.active]="link.url === url" [routerLink]="[link.url]" class="mfm-tlm_link" mat-button
              routerLinkActive="active">
      <span class="d-flex align-items-center">
        {{ link.text }}
      </span>
      </button>
    </ng-container>
  </div>
  <ng-content>
  </ng-content>
</div>

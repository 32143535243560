/* src/app/shared/phar-controls/phar-timepicker-field/phar-timepicker-field.component.scss */
.mfm-timepicker {
  display: flex;
  align-items: center;
  height: 100%;
}
.mfm-timepicker__time-colon {
  margin-left: 10px;
}
.mfm-timepicker__control--first {
  order: 1;
}
.mfm-timepicker__control--second {
  order: 2;
}
.mfm-timepicker__control--third {
  order: 3;
}
.mfm-timepicker__control--forth {
  order: 4;
}
.mfm-timepicker__control--fifth {
  order: 5;
}
.mfm-timepicker__control--sixth {
  order: 6;
}
/*# sourceMappingURL=phar-timepicker-field.component.css.map */

import { IAuditTrailItem } from '../audit-trail-item.interface';
import { IAuditDataParams } from '../audit-data-params.interface';

export interface AuditTrailState {
  data: IAuditTrailItem[];
  filter: {
    userAction: string,
    fromDate: string,
    toDate: string
  };
  metaData: IAuditDataParams;
  actionTypes: string[];
}

export interface AppAuditTrailState {
  auditTrail: AuditTrailState;
}

export const appAuditTrailInitialState: AppAuditTrailState = {
  auditTrail: {
    data: [],
    filter: {
      userAction: '',
      fromDate: '',
      toDate: ''
    },
    metaData: {
      pageIndex: 0,
      pageSize: 10,
      length: null
    },
    actionTypes: []
  }
};


import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Self
} from '@angular/core';

import { MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher, mixinErrorState } from '@angular/material/core';
import { FocusMonitor } from '@angular/cdk/a11y';
import { Subject } from 'rxjs';
import { coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { UtilsService } from '../../core/utils.service';
import { environment } from '../../../environments/environment';

declare const jQuery: any;

class _HtmlInputComponent {
  stateChanges = new Subject<void>();

  constructor(
    public _defaultErrorStateMatcher: ErrorStateMatcher,
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl,
  ) {
  }

}

@Component({
  selector: 'phar-html-input',
  templateUrl: 'html-input.component.html',
  styleUrls: ['html-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: HtmlInputComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlInputComponent extends mixinErrorState(_HtmlInputComponent) implements DoCheck, OnDestroy, AfterViewInit, ControlValueAccessor, MatFormFieldControl<string> {
  @HostBinding()
  id: string;
  summernoteId: string;
  focused = false;
  @HostBinding("attr.aria-describedBy")
  describedBy: string = "";
  summernoteConfig: any;


  private summernoteElement: any; // jquery wrapped element
  private summernoteEditor: any; // editor element
  private summernoteFunction: (...args: any[]) => any;
  private summernoteInitialized: boolean = false;


  private _value: string = '';
  private _disabled: boolean = false;
  private _required: boolean = false;
  private _placeholder: string = "";
  private onChange?: (value: string) => void;
  private onTouched?: () => void;
  private static nextId: number = 0;


  constructor(
    @Optional() @Self() readonly ngControl: NgControl,
    @Optional() readonly _parentForm: NgForm,
    @Optional() readonly _parentFormGroup: FormGroupDirective,
    readonly _defaultErrorStateMatcher: ErrorStateMatcher,
    private readonly _focusMonitor: FocusMonitor,
    private readonly _elementRef: ElementRef,
    private utilsService: UtilsService,
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    this.id = `phar-html-input-${HtmlInputComponent.nextId++}`
    this.summernoteId = `${this.id}-summernote`
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }

    _focusMonitor
      .monitor(this._elementRef.nativeElement, true)
      .subscribe(origin => {
        this.focused = !!origin;
        this.stateChanges.next();
      });

    this.summernoteConfig = {
      airMode: true,
      shortcuts: false,
      placeholder: null,
      maxTextLength: null,
      disableDragAndDrop: true,
      inheritPlaceholder: true,
      overrideContextMenu: true,
      blockquoteBreakingLevel: 0,
      popover: {
        air: [
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          /*['fontsize', ['fontsize']],
          ['color', ['color']],*/
        ],
      },
      callbacks: {
        onChange: (value: string) => {
          this._value = value;
          if (this.onChange) {
            this.onChange(value);
          }
        },
        onFocus: (event: Event) => {
          if (this.disabled) {
            event.preventDefault();
          }
        },
        onPaste: (event: any) => {
          const clipboardData = (event.originalEvent || event).clipboardData;
          const text = clipboardData.getData('text');
          event.preventDefault();
          document.execCommand('insertText', true, text);
        },
        onKeydown: this.restrictSpaceAtFirstPosition,
      }
    };
  }

  @Input()
  set value(value: string) {
    if (value === this._value) {
      return;
    }

    this._value = value;
    this.updateEditor(value);
    if (this.onChange) {
      this.onChange(value);
    }
  }

  get value(): string {
    return this._value;
  }

  @Input()
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.summernoteConfig.placeholder = placeholder;
    this.stateChanges.next();
  }

  get placeholder() {
    return this._placeholder;
  }

  @Input()
  set maxLength(maxLength: any) {
    this.summernoteConfig.maxTextLength = coerceNumberProperty(maxLength);
  }

  @Input()
  set required(required: any) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  get required() {
    return this._required;
  }

  @Input()
  set disabled(disabled: any) {
    this._disabled = coerceBooleanProperty(disabled);

    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }

    if (this.summernoteInitialized) {
      if (disabled) {
        this.summernoteFunction('disable');
      } else {
        this.summernoteFunction('enable');
      }
    }
  }

  get disabled() {
    if (this.ngControl && this.ngControl.disabled !== null) {
      return this.ngControl.disabled;
    }
    return this._disabled;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  get empty(): boolean {
    return !this._value;
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngAfterViewInit(): void {
    this.initializeSummernote();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
    this.destroySummernote();
  }

  @HostListener("focusout")
  onBlur() {
    this.focused = false;
    if (this.onTouched) {
      this.onTouched();
    }
    this.stateChanges.next();
  }

  focus(): void {
    if (this.summernoteInitialized) {
      this.summernoteFunction('focus');
    }
  }

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(" ");
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.value = value;
  }

  onContainerClick(): void {
    if (!this.focused) {
      this.focus();
    }
  }

  private updateEditor(content: string) {
    if (this.summernoteInitialized) {
      this.summernoteFunction('code', content);
    } else {
      this.summernoteElement?.html(content);
    }
  }

  private initializeSummernote(): void {
    try {
      this.utilsService.waitForElementToExist(`#${this.summernoteId}`, 50, 50).then((element: Element) => {
        this.summernoteElement = jQuery(`#${this.summernoteId}`);

        this.summernoteFunction = (...args: any[]) => {
          return this.summernoteElement.summernote(...args);
        };

        this.summernoteEditor = this.summernoteFunction(this.summernoteConfig).data('summernote')?.$note;
        this.summernoteInitialized = true;

        this.updateEditor(this.value || '');

        if (this.disabled) {
          this.summernoteFunction('disable');
        }
      })
    } catch (e) {
      if (!environment.production) {
        console.warn(e);
      }
    }

  }

  private destroySummernote(): void {
    if (this.summernoteInitialized && this.summernoteEditor) {
      this.summernoteEditor.off('keyup');
      this.summernoteFunction('destroy');
      this.summernoteInitialized = false;
    }
  }

  private restrictSpaceAtFirstPosition(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;
    const value: string = target.innerText;
    const keyCode = event.keyCode;

    if (keyCode === 32 && value.length === 0) {
      event.preventDefault();
    }
  }
}

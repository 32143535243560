<phar-base-control-template
  controlName="Visual Analog Scale"
  [control]="control()"
  [question]="question"
  [builderMode]="builderMode"
  [isDraggable]="isDraggable"
  [isCommentsVisible]="isCommentsVisible"
  [canManageControl]="canManageControl"
  [showActionButtons]="(isControlActive$ | async) || (isControlHovered$ | async)"
  [highlightBackground]="isControlHighlighted$ | async"
  [hasConditionalRules]="hasConditionalRules()"
  (actionClick)="actionsHandler($event, control())">
  <div [ngClass]="{'slider-vertical-layout': isVerticalLayout}" class="slider-wrapper">

    <div class="scale-labels">
      <mat-label *ngFor="let label of control().settings.labels" [innerHTML]="label | safeHtml"
                 class="control-label"></mat-label>
    </div>

    <div class="slider">
      <div #slider class="thumb-borders">
        <div
          *ngIf="value !== null"
          [style.left.%]="isVertical() ? 50 : value"
          [style.top.%]="isVertical() ? value : 50"
          class="thumb slider-thumb">
          <mat-icon class="thumb-icon" svgIcon="sort-arrow"></mat-icon>
        </div>
      </div>
    </div>

    <div class="scale-labels">
      <mat-label class="control-label">{{ control().settings.min }}</mat-label>
      <mat-label class="control-label">{{ control().settings.max }}</mat-label>
    </div>


  </div>
</phar-base-control-template>



import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/models/app.state';
import { PharBaseControlModelComponent } from '../phar-base-control/phar-base-control-model.component';

@Component({
  selector: 'phar-text-input',
  templateUrl: 'text-input.component.html',
  styleUrls: ['text-input.component.scss'],
})
export class TextInputComponent extends PharBaseControlModelComponent {

  constructor(
    store: Store<AppState>
  ) {
    super(store);
  }

}

import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from '../../../../store/models/app.state';
import { FormElementsEnum } from '../../../../form/form-elements.enum';
import { PharDragService } from '../../../drag.service';
import { setDragged, setDraggedHelper } from '../../../../store/actions/ui.actions';

@Component({
  selector: 'phar-general-boolean-widget',
  templateUrl: './general-boolean-widget.component.html',
  styleUrls: ['./general-boolean-widget.component.scss']
})
export class GeneralBooleanWidgetComponent {
  @Input() draggable = true;
  readonly type = FormElementsEnum.Boolean;

  get dragData(): any {
    return {
      control: {
        controlID: '',
        label: '',
        info: '',
        controlType: this.type,
        validators: {},
        bindDataField: '',
        dependencies: {},
        values: [
          {
            label: "NO",
            value: "false",
          },
          {
            label: "YES",
            value: "true",
          },
        ],
        settings: {
          horizontal: false,
        }
      }
    };
  }

  constructor(
    private readonly store: Store<AppState>,
    private readonly dragService: PharDragService,
  ) {
  }

  onDragStart(event): void {
    event.stopImmediatePropagation();
    this.dragService.setDragData(this.dragData);
    this.store.dispatch(setDragged({ dragged: true }));
    this.store.dispatch(setDraggedHelper({ draggedHelper: { type: this.type, reorder: false } }));
  }

  onDragEnd(): void {
    this.store.dispatch(setDragged({ dragged: false }));
    this.store.dispatch(setDraggedHelper({ draggedHelper: {} }));
  }

}

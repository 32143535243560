import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  EventEmitter,
  inject,
  Injector,
  input,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { DraggableListViewConfigModel, ReorderedItem } from './draggable-list-view-model';
import { CdkDragDrop, CdkDropList, copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { asapScheduler } from 'rxjs';
import { FieldType, IGroupModel } from './models/group-model';
import { cloneDeep, isEmpty, orderBy } from 'lodash-es'
import { ColumnsChangeNotifier } from '../list-column-selection/list-column-selection.component';

@Component({
  selector: 'phar-draggable-list-view',
  templateUrl: './draggable-list-view.component.html',
  styleUrls: ['./draggable-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// Based on https://stackblitz.com/edit/angular-ivy-gdyesu?file=src%2Fapp%2Fapp.component.ts,src%2Fapp%2Fapp.component.css
export class DraggableListViewComponent implements OnInit, OnChanges, ColumnsChangeNotifier {
  @Input() dataSource: any[] = [];
  @Input() idClassMap: {} = {}
  @Input() groupClass: string = ''
  @Input() connectedListId: string;
  @Input() config: DraggableListViewConfigModel = {} as DraggableListViewConfigModel;
  @Input() noDataMessage = 'Drag and drop from the list to assign assessments to the study </br> Or select Add group above to create a group of assessments';
  @Input() groupField: string;  // key to check agains if it is a group
  @Input() groupFieldValue: string | number;  // the actual value that is considered as group
  @Input() selectedRecordId: number; // the current selected record that is open for editing
  @Input() highlightedRecordId: number; // base record - color of record in table does not change
  @Input() draggingDisabled: boolean;
  @Input() isRowSelectable: boolean = true;
  @Input() orderField: string | undefined;
  @Input() disableMoveToParent: boolean = false;
  @Input() footer: string | undefined;
  initiallyExpandedItems = input<{ [key: number]: boolean }>()
  injector: Injector = inject(Injector);
  @Output() afterActionClicked: EventEmitter<any> = new EventEmitter();
  @Output() afterClick: EventEmitter<any> = new EventEmitter();
  @Output() afterSelect: EventEmitter<any> = new EventEmitter();
  @Output() afterReorder: EventEmitter<{ item: ReorderedItem, reorderData: any[] }> = new EventEmitter();
  @Output() elementAdded: EventEmitter<{
    item: any,
    index: number,
    parentId: number | undefined,
    reorderData: any[]
  }> = new EventEmitter();

  gridColumns = '';
  gridColumnsNested = '';
  selectedItem: any;
  expandedItemsState: { [key: number]: boolean } = {};

  readonly rootDragDropName: string = 'root-dragdrop';
  draggableListData: IGroupModel[];

  fieldType = FieldType;
  public dls: CdkDropList[] = [];
  @ViewChildren(CdkDropList)
  private dlq: QueryList<CdkDropList>;

  constructor(private changeDetectionRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.setupGridColumns();
    this.draggableListData = this.createNestedList(this.dataSource);

    effect(() => {
      if (this.initiallyExpandedItems() && !isEmpty(this.initiallyExpandedItems())) {
        this.expandedItemsState = { ...this.expandedItemsState, ...this.initiallyExpandedItems() };
      }
    }, {
      injector: this.injector,
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    // ngOnchanges is introduced because reloading the full list causes flickering effect.
    if (changes.dataSource && changes.dataSource.currentValue) {
      const nestedData = this.createNestedList(changes.dataSource.currentValue);
      this.draggableListData = [...nestedData];
      this.changeDetectionRef.detectChanges();
      this.initDragZones();
    }
  }

  onColumnsChange(): void {
    this.setupGridColumns();
    this.changeDetectionRef.markForCheck();
  }

  dropItem(event: CdkDragDrop<IGroupModel[] | undefined>, parent?: any) {
    let containerData: IGroupModel[] = event?.container?.data;
    if (!containerData) return;

    let previousContainerData = event?.previousContainer?.data;
    if (this.connectedListId && event.previousContainer.id === this.connectedListId) {
      // coming from outer list
      const copyContainerData = cloneDeep(containerData);

      copyArrayItem(previousContainerData, copyContainerData, event.previousIndex, event.currentIndex)

      this.elementAdded.emit({
        item: event.item.data,
        index: event.currentIndex,
        parentId: parent?.id,
        reorderData: copyContainerData.map((item, index) => ({ //sync order priority
            ...item,
            orderPriority: index,
            parentId: parent?.id,
          })
        ),
      });
      return;

    }
    if (!previousContainerData) return;

    // Stop if we're a Group, and we're being moved to a different container.
    if (
      previousContainerData[event.previousIndex].type === FieldType.Group &&
      event.previousContainer != event.container
    )
      return;

    if (event.previousContainer === event.container) {
      // same container
      moveItemInArray(containerData, event.previousIndex, event.currentIndex);

      delete event.item.data.children;
      delete event.item.data.isExpanded;
      delete event.item.data.original;
      delete event.item.data.userCount;

      this.afterReorder.emit({
        item: {
          order: event.currentIndex,
          value: event.item.data,
          parentId: parent?.id,
        },
        reorderData: containerData.map((item, index) => ({ //sync order priority
          ...item,
          orderPriority: index,
          parentId: parent?.id,
        }))
      });
    } else {
      // from somewhere to a new container
      transferArrayItem(
        previousContainerData,
        containerData,
        event.previousIndex,
        event.currentIndex
      );
      // Remove UI Grouping properties from entity.
      delete event.item.data.children;
      delete event.item.data.isExpanded;
      delete event.item.data.original;
      delete event.item.data.userCount;

      const reorderData = [
        ...containerData.map((item, index) => ({ //sync order priority
          ...item,
          orderPriority: index,
          parentId: parent?.id
        })),
        ...previousContainerData.map((item, index) => ({ //sync order priority
          ...item,
          orderPriority: index,
        }))

      ];
      this.afterReorder.emit({
        item: {
          order: event.currentIndex,
          value: event.item.data,
          parentId: parent?.id,
        },
        reorderData
      });
    }
  }

  onSelectHandler(dataItem: any): void {
    this.selectedItem = dataItem;
    this.afterSelect.emit({ dataItem });
  }

  manageExpanded(item: IGroupModel): void {
    item.isExpanded = !item.isExpanded;
    this.expandedItemsState[item.id] = item.isExpanded;
  }

  private setupGridColumns(): void {
    const visibleColumns = this.config.columns.filter(col => col.show);
    this.gridColumns = visibleColumns.map(item => item.size).join(' ');
    this.gridColumnsNested = visibleColumns.map(item => (item.sizeNested || item.size)).join(' ');
  }

  private createNestedList(list: any[]) {
    if (!list.length) {
      return [];
    }
    const nestedList: IGroupModel[] = [];
    list.forEach((item, index) => {
      const children = this.getChildElements(item, list);
      if (this.groupField && this.groupFieldValue && item[this.groupField] === this.groupFieldValue) {
        nestedList.push({
          ...item,
          children,
          type: FieldType.Group,
          isExpanded: this.expandedItemsState[item.id] || false
        });
      } else if (!item.parentId) {
        nestedList.push({ ...item });
      }
    });
    return this.orderElements(nestedList)
  }

  private orderElements(items: IGroupModel[]) {
    if (this.orderField) {
      return orderBy(items, this.orderField, 'asc').map(item => {
        if (item.children) {
          return {
            ...item,
            children: this.orderElements(item.children)
          }
        }

        return item;
      })
    }

    return items;
  }


  private getChildElements(parentItem: IGroupModel, list: any[]): IGroupModel[] {
    return list.filter(item => parentItem.id === item.parentId);
  }

  private initDragZones() {
    let ldls: CdkDropList[] = [];

    this.dlq.forEach((dl) => {
      if (this.disableMoveToParent && dl.id !== this.rootDragDropName) {
        // this means that we can move elements only through the groups
        ldls.push(dl);
      } else {
        ldls.push(dl);
      }
    });

    ldls = ldls.reverse();

    asapScheduler.schedule(() => {
      this.dls = ldls;
      // one array of siblings (shared for a whole tree)
      const siblings = this.dls.map((dl) => dl?._dropListRef);
      // overwrite _getSiblingContainerFromPosition method
      this.dlq.forEach((dl) => {
        dl._dropListRef._getSiblingContainerFromPosition = (item, x, y) =>
          siblings.find((sibling) => sibling._canReceive(item, x, y));
      });
    });
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UserModel } from '../../../user/user.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { IEntityComment } from '../../models/entity-comments.interface';


@Component({
  selector: 'phar-entity-comment',
  templateUrl: 'entity-comment.component.html',
  styleUrl: './entity-comment.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class EntityCommentComponent {
  @Input() comment: IEntityComment;
  @Input() user: UserModel;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Output() deleteComment: EventEmitter<IEntityComment> = new EventEmitter<IEntityComment>();
  @Output() editComment: EventEmitter<IEntityComment> = new EventEmitter<IEntityComment>();
  @Output() resolveComment: EventEmitter<IEntityComment> = new EventEmitter<IEntityComment>();

  handleEditComment(comment: IEntityComment): void {
    this.trigger?.closeMenu();
    this.editComment.emit(comment)
  }

  handleDeleteComment(comment: IEntityComment): void {
    this.trigger?.closeMenu();
    this.deleteComment.emit(comment);
  }

  handleResolveComment(comment: IEntityComment): void {
    this.trigger?.closeMenu();
    this.resolveComment.emit(comment);
  }


}

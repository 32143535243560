import { Pipe, PipeTransform } from '@angular/core';
import { FormElementsEnum } from '../../form/form-elements.enum';

@Pipe({
  name: 'pharElementType',
  standalone: true,
})

export class ElementTypePipe implements PipeTransform {
  transform(value: FormElementsEnum | string): string {
    switch (value) {

      case FormElementsEnum.VisualAnalogScale:
        return 'Visual Analog Scale';
      case FormElementsEnum.NumericRatingScale:
        return 'Numeric Rating Scale';
      case FormElementsEnum.MultiSelect:
        return 'Multi Select';
      case FormElementsEnum.MultiSelectQuantity:
        return 'Multi Select & QTY';
      case FormElementsEnum.VerbalRatingScale:
        return 'Verbal Rating Scale';
      case FormElementsEnum.SingleSelect:
        return 'Single Select';
      case FormElementsEnum.Boolean:
        return 'Boolean';
      case FormElementsEnum.TextBlock:
        return 'Text Block';
      case FormElementsEnum.TextInput:
        return 'Text Input';
      case FormElementsEnum.Number:
        return 'Number';
      case FormElementsEnum.DatePicker:
        return 'Date';
      case FormElementsEnum.TimePicker:
        return 'Time';
      case FormElementsEnum.Image:
        return 'Image';
      default:
        return value;
    }
  }
}

<div class="list-card position-relative">

  <ng-content select=".mfm-title"></ng-content>
  <ng-content select="mfm-title"></ng-content>

  <ng-content select=".mfm-content"></ng-content>
  <ng-content select="mfm-content"></ng-content>

  <ng-content select=".mfm-card-footer"></ng-content>
  <ng-content select="mfm-card-footer"></ng-content>

</div>

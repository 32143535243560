/* src/app/shared/phar-controls/number-input/number-input.component.scss */
.number-input-content {
  display: flex;
  gap: 0.75rem;
}
.number-input-content_operator,
.number-input-content_descriptor {
  margin-top: 6px;
}
/*# sourceMappingURL=number-input.component.css.map */

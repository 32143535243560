import { DatasetModel } from '../dataset.model';
import { DatasetDataTypeModel } from '../dataset-data-type.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IDatasetList } from '../../_old/_dataset/_dataset-lists/dataset-list.interface';

export interface CurrentDatasetManage {
  dataset: DatasetModel;
  datasetSnapshot: DatasetModel;
  pending: boolean;
  success: boolean;
  error: boolean;
  errors: string[];
  dispatched: boolean;
  pendingChanges: boolean;
  datasetLists: {
    list: IDatasetList[],
    current: IDatasetList
  }
}

export interface DatasetVersion {
  dataSetVersion: number;
}

export interface DatasetState {
  list: DatasetModel[];
  current: CurrentDatasetManage;
  types: DatasetDataTypeModel[];
  versions: DatasetVersion[];
}

export interface AppDatasetState {
  dataset: DatasetState;
}

export const appStateDataset: AppDatasetState = {
  dataset: {
    list: [],
    current: {
      dataset: {
        description: '',
        forms: [],
        projects: [],
        name: '',
        displayName: '',
        id: null,
        organizationId: null,
        userIdCreated: null,
        userIdUpdated: null,
        shared: [],
        type: 'dataset',
        data: {} as DatasetModel,
        updatedAt: '',
        createdAt: '',
        settings: {},
        datasetSchema: {},
        datasetFieldTypes: [],
        dataSetVersion: null
      },
      datasetSnapshot: {
        description: '',
        forms: [],
        projects: [],
        name: '',
        displayName: '',
        id: null,
        organizationId: null,
        userIdCreated: null,
        userIdUpdated: null,
        shared: [],
        type: 'dataset',
        data: <DatasetModel>{},
        updatedAt: '',
        createdAt: '',
        settings: {},
        datasetSchema: {},
        datasetFieldTypes: [],
        dataSetVersion: null
      },
      error: false,
      errors: [],
      pending: false,
      success: false,
      dispatched: false,
      pendingChanges: false,
      datasetLists: {
        list: [],
        current: {} as IDatasetList
      }
    },
    types: [],
    versions: []
  }
};

export const selectDatasetState = createFeatureSelector<DatasetState>('dataset');
export const selectCurrentDatasetState = createSelector(selectDatasetState, (state) => state.current);
export const selectDatasetStateCurrentDataset = createSelector(selectCurrentDatasetState, (state: CurrentDatasetManage) => state.dataset);
export const selectCurrentDatasetDataLists = createSelector(selectDatasetState, (state: DatasetState) => state.current.datasetLists.list);
export const selectCurrentDatasetCurrentDataList = createSelector(selectDatasetState, (state: DatasetState) => state.current.datasetLists.current);


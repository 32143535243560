<div
  class="list-view full-width"
  cdkDropList
  [id]="rootDragDropName"
  [ngClass]="config.customClasses"
  [cdkDropListConnectedTo]="dls"
  [cdkDropListData]="draggableListData"
  [cdkDropListDisabled]="draggingDisabled"
  (cdkDropListDropped)="dropItem($event)"
>
  @if (gridColumns) {
    <div [style.grid-template-columns]="gridColumns" class="list-view_list_headers no-copy">
      @for (column of config.columns | filterBy: ['show']: true; track $index) {
        <div [class.cursor-pointer]="column.order !== undefined" class="d-flex"
        >
          <!--                (click)="onOrder(column.order !== undefined ? column : undefined)"-->
          @if (column.show) {
            <span class="title"> {{ column.title }}</span>
          }
          <!--                <ng-container *ngIf="column.field === config.order || '-' + column.field === config.order">-->
          <!--                    <mat-icon-->
          <!--                            class="cursor-pointer"-->
          <!--                            *ngIf="column.field === config.order"-->
          <!--                    >arrow_drop_up-->
          <!--                    </mat-icon>-->
          <!--                    <mat-icon-->
          <!--                            class="cursor-pointer"-->
          <!--                            *ngIf="'-' + column.field === config.order"-->
          <!--                    >arrow_drop_down-->
          <!--                    </mat-icon>-->
          <!--                </ng-container>-->
        </div>

      }

    </div>

  } @else {
    <div id="no-headers" class="list-view_list_headers no-copy w-100">
    </div>
  }
  @for (formField of draggableListData; track formField.id; let i = $index) {
    <div class="list-view_list_content"
         cdkDrag
         cdkDropListGroup
         [cdkDragData]="formField"
         [cdkDropListGroupDisabled]="draggingDisabled"
         [ngClass]="{'active-group': formField.type === fieldType.Group && formField.isExpanded,}"
         (click)="onSelectHandler(formField)"
    >

      <div *cdkDragPlaceholder class="drop-placeholder">
      </div>


      @if (formField.type === fieldType.Group) {
        <!-- Root level group elements -->
        <div class="list-view_list_element {{idClassMap[formField.id] || ''}} {{groupClass}}"
             [id]="'item-' + formField?.id"
             [ngClass]="{'active': formField?.id === selectedRecordId,
                 'cursor-pointer': isRowSelectable,
                 'base-record' : formField.id === highlightedRecordId,
                 'group-expanded': formField.isExpanded
                 }"
             [style.grid-template-columns]="gridColumns"
             (click)="manageExpanded(formField)"
        >
          @for (column of config.columns | filterBy: ['show']: true; let i = $index; let l = $last; track column.field) {
            <div>
              <div class="d-flex align-items-center">
                @if (!l && column.show) {
                  @if (column.hasTemplate && column.template) {
                    <ng-template [ngTemplateOutletContext]="{$implicit: formField}"
                                 [ngTemplateOutlet]="column.template"></ng-template>
                  } @else {
                    <span class="three-dots-no-wrap">{{ formField[column.field] }}</span>
                  }
                }

                @if (l) {
                  @if (config && config.actions.length) {
                    <button class="ms-auto list-card_more"
                            mat-icon-button
                            [matMenuTriggerFor]="actionsMenu"
                            (click)="$event.stopImmediatePropagation()"
                    >
                      <mat-icon class="more">more_vert</mat-icon>
                    </button>
                    <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
                      @for (button of config.actions; track $index) {
                        <button mat-menu-item
                                (click)="afterActionClicked.emit({eventName:button.eventName, dataItem: formField})"
                        >
                          <span>{{ button.title }}</span>
                        </button>
                      }
                    </mat-menu>
                  } @else {
                    <ng-template [ngTemplateOutletContext]="{$implicit: column, formField: formField}"
                                 [ngTemplateOutlet]="elementCellTemplate">
                    </ng-template>
                  }
                }
              </div>

            </div>

          }
        </div>

        <!-- Group row.  -->
          <!-- Do not use ngIf because it breaks the drag and drop functionality. Use css class instead.  -->

        <div
          style="min-height: 100px;"
          class="child-containers"
          cdkDropList
          [ngClass]="{'hidden': !formField.isExpanded}"
          [id]="formField.id + '-' + i"
          [cdkDropListConnectedTo]="dls"
          [cdkDropListData]="formField.children"
          [cdkDropListDisabled]="draggingDisabled"
          (cdkDropListDropped)="dropItem($event, formField)"

        >
          @for (formField of formField.children; track formField.id) {
            <div
              cdkDrag
              pharNoPropagation
              [cdkDragData]="formField"
              (click)="onSelectHandler(formField)"
            >

              <div *cdkDragPlaceholder class="drop-placeholder children">

              </div>


              <div class="list-view_list_element {{idClassMap[formField.id] || ''}}"
                   [style.grid-template-columns]="gridColumnsNested"
                   [ngClass]="{
                         'active': formField?.id === selectedRecordId,
                         'cursor-pointer': isRowSelectable
                         }"
              >
                @for (column of config.columns | filterBy: ['show']: true; let i = $index; let l = $last; track column.field) {
                  <div class="d-flex align-items-center tabbed">
                    @if (!l && column.show) {
                      @if (column.hasTemplate && column.template) {
                        <ng-template [ngTemplateOutletContext]="{$implicit: formField}"
                                     [ngTemplateOutlet]="column.template"></ng-template>
                      } @else {
                        <span class="three-dots-no-wrap">{{ formField[column.field] }}</span>

                      }
                    }
                    @if (l) {
                      @if (config.actions && config.actions.length) {
                        <button class="ms-auto list-card_more"
                                mat-icon-button
                                (click)="$event.stopImmediatePropagation()" [matMenuTriggerFor]="actionsMenu"
                        >
                          <mat-icon class="more">more_vert</mat-icon>
                        </button>
                        <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
                          @for (button of config.actions; track $index) {
                            <button
                              mat-menu-item
                              (click)="afterActionClicked.emit({eventName:button.eventName, dataItem: formField})"
                            >
                              <span>{{ button.title }}</span>
                            </button>
                          }
                        </mat-menu>
                      } @else {
                        <ng-template [ngTemplateOutletContext]="{$implicit: column, formField: formField}"
                                     [ngTemplateOutlet]="elementCellTemplate">
                        </ng-template>
                      }
                    }
                  </div>

                }
              </div>
            </div>

          }
          @if (formField.type === fieldType.Group && !formField.children?.length) {
            <div class="text-center text-muted my-3 no-children">
              This group doesn't contain any items
            </div>
          }
          <!-- Group children -->

        </div>
        <!-- END Group row.  -->
      }


      @if (formField.type !== fieldType.Group) {
        <div
          class="list-view_list_element {{idClassMap[formField.id] || ''}}"
          [id]="'item-' + formField?.id"
          [style.grid-template-columns]="gridColumns"
          [ngClass]="{'active': formField?.id === selectedRecordId,
              'cursor-pointer': isRowSelectable,
             'base-record' : formField.id === highlightedRecordId
         }"
        >

          @for (column of config.columns | filterBy: ['show']: true; let i = $index; let l = $last; track column.field) {
            <div class="d-flex align-items-center">

              @if (!l && column.show) {
                @if (column.hasTemplate && column.template) {
                  <ng-template [ngTemplateOutletContext]="{$implicit: formField}"
                               [ngTemplateOutlet]="column.template"></ng-template>
                } @else {
                  <span class="three-dots-no-wrap">{{ formField[column.field] }}</span>

                }
              }

              @if (l) {
                @if (config.actions && config.actions.length) {
                  <button class="ms-auto list-card_more"
                          mat-icon-button
                          [matMenuTriggerFor]="actionsMenu"
                          (click)="$event.stopImmediatePropagation()"
                  >
                    <mat-icon class="more">more_vert</mat-icon>
                  </button>
                  <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
                    @for (button of config.actions; track $index) {
                      <button mat-menu-item
                              (click)="afterActionClicked.emit({eventName:button.eventName, dataItem: formField})"
                      >
                        <span>{{ button.title }}</span>
                      </button>
                    }
                  </mat-menu>
                } @else {
                  <ng-template [ngTemplateOutletContext]="{$implicit: column, formField: formField}"
                               [ngTemplateOutlet]="elementCellTemplate">
                  </ng-template>
                }

              }
            </div>
          }

        </div>

      }


    </div>

  }

  @if (!dataSource.length) {
    <p [innerHTML]="noDataMessage" class="d-flex justify-content-center mt-3"></p>
  }
  @if (footer) {
    <p [innerHTML]="footer" class="d-flex justify-content-center mt-3"></p>
  }
</div>

<ng-template #elementCellTemplate let-element let-formField="formField">
  @if (element.hasTemplate && element.template) {
    <ng-template [ngTemplateOutletContext]="{$implicit: formField}"
                 [ngTemplateOutlet]="element.template"></ng-template>
  } @else {
    <span class="three-dots-no-wrap">{{ formField[element.field] }}</span>
  }
</ng-template>


import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';
import { Directive, inject, Input, OnInit } from '@angular/core';

@Directive({
  selector: `[pharTooltip]`,
  standalone: true,
  hostDirectives: [MatTooltip]
})
export class PharTooltipDirective implements OnInit {
  private readonly DEFAULT_DELAY = 300;
  private message: string = '';
  private disabled: boolean = false;
  private delay: number = this.DEFAULT_DELAY;
  private matTooltip = inject(MatTooltip);

  @Input()
  set pharTooltipDelay(delay: number) {
    this.delay = delay;
    this.matTooltip.showDelay = this.delay;
  }

  @Input() set pharTooltipDisabled(disable: boolean) {
    this.disabled = !!disable;
    this.matTooltip.disabled = this.disabled;
  }

  @Input() set pharTooltipPosition(position: TooltipPosition) {
    if (!position) return;
    this.matTooltip.position = position;
  }

  @Input({ required: true })
  set pharTooltip(message: string) {
    this.message = message;
    this.matTooltip.message = this.message;
  }

  ngOnInit() {
    this.matTooltip.showDelay = this.delay;
    this.matTooltip.tooltipClass = 'phar-tooltip';
  }
}

import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../../store/models/app.state";
import { PharBaseControlModelComponent } from "../phar-base-control/phar-base-control-model.component";

@Component({
  selector: 'phar-verbal-rating-scale',
  templateUrl: 'verbal-rating-scale.component.html',
  styleUrls: ['verbal-rating-scale.component.scss'],
})
export class VerbalRatingScaleComponent extends PharBaseControlModelComponent implements OnInit {

  constructor(
    store: Store<AppState>
  ) {
    super(store);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}

<div class="search-container">
  <mat-form-field class="search-term-container">
    <input [placeholder]="searchPlaceholder" [formControl]="searchFormCtrl" matInput/>
    <button mat-icon-button matPrefix>
      <mat-icon class="search-icon" matTextPrefix svgIcon="search"></mat-icon>
    </button>

    <mat-icon
      class="clear cursor-pointer"
      matSuffix
      (click)="clearValue()"
    >
      clear
    </mat-icon>
  </mat-form-field>

  @if (enableFilters) {
    <div class="phar-icon-button filter" [matMenuTriggerFor]="filters" pharTooltip="Filters">
      <mat-icon svgIcon="filter"></mat-icon>
    </div>

    @if (activeFilters.length) {
      <span class="cursor-pointer clear-filters" (click)="clearAllFilters()">
        Clear all filters
      </span>
    }
  }


  @if (showCounter && activeFilters.length) {
    <div>
      {{ resultCounter }} items(s) filtered
    </div>
  }
</div>
@if (activeFilters && activeFilters.length) {
  <div class="filters-container d-flex flex-row gap-2 mb-3 mt-2 flex-wrap">
    @for (filter of activeFilters; track filter.id) {
      @if (filter.field !== searchField) {
        <phar-filter-item [filter]="filter" (filterUpdate)="filterUpdate.emit($event)"/>
      }
    }

  </div>
}
<mat-menu #filters="matMenu" [overlapTrigger]="false">
  @for (filter of displayedFilterOptions | manageFilters: activeFilters; track $index) {
    <button
      mat-menu-item
      [disabled]="filter.disabled"
      (click)="addFilter(filter)"
    >
      <span>{{ filter.title }}</span>
    </button>
  }


</mat-menu>


import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgPipesModule } from 'ngx-pipes';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ColorPickerModule } from 'ngx-color-picker';
// import { NgxSummernoteModule } from 'ngx-summernote';
// import * as PlotlyJS from 'plotly.js/dist/plotly-basic.min.js';
import { MaterialModule } from './material/material.module';

import { PharCardsListComponent } from './cards-list/cards-list.component';
import { PharCardComponent } from './card/card.component';
import { PharDragService } from './drag.service';
import { PharConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { PharConfirmDialogService } from './confirm-dialog/confirm-dialog-service.service';
import { SecondLevelMenuComponent } from './second-level-menu/second-level-menu.component';
import { ThirdLevelMenuComponent } from './third-level-menu/third-level-menu.component';

import { QuestionDropZoneComponent } from './question-drop-zone/question-drop-zone.component';
import { BaseCardComponent } from './base-card/base-card.component';
import { SafeHtmlPipe, SafeStylePipe, BindDataFieldShortenPipe, SafeUrlPipe } from './pipes';
import { CardListSwitcherComponent } from './card-list-switcher/card-list-switcher.component';
import { ListViewComponent } from './list-view/list-view.component';



import {
  AutoIncreaseFieldWidthDirective,
  AllowOnlyNumberDirective,
  SecondHeaderDirective,
  NoDataFieldDirective,
  ScrollToElementDirective
} from './directives';
/*import * as MfbControls from './mfm-controls';*/
import * as PharControls from './phar-controls';

// import {
//   NgxMatDatetimePickerModule,
//   NgxMatNativeDateModule,
//   NgxMatTimepickerModule
// } from '@angular-material-components/datetime-picker';
// "@angular-material-components/datetime-picker": "^16.0.1", @TODO check when the library supports version 17


import { ExportComponent } from './export/export.component';
import { PendingChangesGuard } from "./guards/pending-changes.guard";
import { MaxInputLengthTooltipDirective } from './directives/max-input-length-tooltip.directive';
import { StatusBadgeComponent } from './status-badge/status-badge.component';
import { DraggableListViewComponent } from './draggable-list-view/draggable-list-view.component';
import { HtmlInputComponent } from './html-input/html-input.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { TimeUnitConverterPipe } from './pipes/time-unit-converter.pipe';
import { ListFilterComponent } from './list-filter/list-filter.component';
import { FilterItemComponent } from './list-filter/filter-component/filter-item.component';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { FormTypePipe } from '../form/pipes/form-type.pipe';
import { ElementTypePipe } from './element-type/element-type.pipe';
import { ManageFiltersPipe } from './list-filter/manage-filters.pipe';
import { NumberRangeComponent } from './number-range/number-range.component';
import { TimeRangePickerComponent } from './time-range-picker/time-range-picker.component';
import { PharTooltipDirective } from './directives/tooltip.directive';
import { ListColumnSelectionComponent } from './list-column-selection/list-column-selection.component';
import {PharAutocompleterComponent} from "./phar-dropdown-list-autocompleter/phar-autocompleter.component";
import { HelpIconComponent } from './help-icon/help-icon.component';
import { QuestionCommentsDialogComponent } from './question-comments';
import { NoPropagationDirective } from './directives/no-propagation.directive';
import { EntityCommentsComponent } from './entity-comments/entity-comments.component';
import { EntityCommentComponent } from './entity-comments/entity-comment/entity-comment.component';
import { InputTrimDirective } from './directives/input-trim.directive';
import { EmailMarkedTextPipe } from './entity-comments/entity-comment/email-marked-text.pipe';

/*const mfmControls = [
  MfbControls.MfmTextblockWidgetComponent,
  MfbControls.MfmTextblockWidgetViewComponent,
  MfbControls.MfmGridWidgetComponent,
  MfbControls.MfmPieChartWidgetComponent,
  MfbControls.MfmGridWidgetViewComponent,
  MfbControls.MfmPieChartWidgetViewComponent,
  MfbControls.MfmGridEditorComponent,
  MfbControls.MfmTextareaEditorComponent,
  MfbControls.MfmTimepickerFieldComponent,
  MfbControls.MfmTextboxComponent,
  MfbControls.MfmTextboxWidgetComponent,
  MfbControls.MfmRadioButtonListComponent,
  MfbControls.MfmRadioButtonListWidgetComponent,
  MfbControls.MfmCheckboxComponent,
  MfbControls.MfmCheckboxWidgetComponent,
  MfbControls.MfmDropdownListComponent,
  MfbControls.MfmDropdownListWidgetComponent,
  MfbControls.MfmDateComponent,
  MfbControls.MfmDateWidgetComponent,
  MfbControls.MfmRepeatableGroupComponent,
  MfbControls.MfmRepeatableGroupWidgetComponent,
  MfbControls.MfmFileUploadComponent,
  MfbControls.MfmImageUploadComponent,
  MfbControls.MfmImageUploadWidgetComponent,
  MfbControls.MfmFileUploadWidgetComponent,
  MfbControls.MfmSignatureComponent,
  MfbControls.MfmSignatureWidgetComponent,
  MfbControls.MfmBarChartComponent,
  MfbControls.MfmBarChartWidgetComponent,
  MfbControls.MfmLineChartComponent,
  MfbControls.MfmLineChartWidgetComponent,
  MfbControls.MfmPieChartComponent,
  MfbControls.MfmGridColumnEditorComponent,
  MfbControls.DatasetDialogComponent,
  MfbControls.MfmNumberComponent,
  MfbControls.MfmNumberWidgetComponent,
  MfbControls.SystemFieldDialogComponent,
  MfbControls.MfmPiechartEditorComponent,
  MfbControls.MfmPieChartFieldsEditorComponent,
  MfbControls.MfmScriptWidgetComponent,
  MfbControls.MfmScriptWidgetViewComponent,
  MfbControls.MfmScriptAreaEditorComponent,
  MfbControls.MfmBoxedInputComponent,
  MfbControls.MfmBoxedInputWidgetComponent,
  MfbControls.MfmTableElementComponent,
  MfbControls.MfmTableElementWidgetComponent,
  MfbControls.MfmTableCellDropzoneComponent,
  MfbControls.MfmRadioImageListComponent,
  MfbControls.MfmRadioImageListWidgetComponent,
  MfbControls.MfmGridRadioComponent,
  MfbControls.MfmGridRadioWidgetComponent,
  MfbControls.MfmImageTaggingComponent,
  MfbControls.MfmImageTaggingWidgetComponent,
  MfbControls.MfmSliderWrapperComponent,
  MfbControls.MfmSliderWidgetComponent,
  MfbControls.MfmImageHighlightingComponent,
  MfbControls.MfmImageHighlightingWidgetComponent,
  MfbControls.DrawCanvasComponent,
  MfbControls.MfmSliderComponent,
  MfbControls.MfmUserSelectWidgetComponent,
  MfbControls.MfmUserSelectComponent,
  MfbControls.MfmTimeComponent,
  MfbControls.MfmTimeWidgetComponent,
  MfbControls.MfmTimepickerControlComponent,
  MfbControls.MfmTimepickerPeriodSelectorComponent,
  MfbControls.MfmDatepickerInputComponent,
  MfbControls.MfmDataTableComponent,
  MfbControls.MfmDataTableWidgetComponent,
  MfbControls.MfmHiddenInputComponent,
  MfbControls.MfmHiddenInputComponentWidgetComponent,
  MfbControls.MfmWidgetComponent,
];*/
const pharControls = [
  PharControls.PharTimepickerControlComponent,
  PharControls.PharTimepickerFieldComponent,
  PharControls.PharTimepickerPeriodSelectorComponent,
  PharControls.BaseControlTemplateComponent,
  PharControls.MultiSelectQuantityComponent,
  PharControls.MultiSelectQuantityWidgetComponent,
  PharControls.MultiSelectComponent,
  PharControls.MultiSelectWidgetComponent,
  PharControls.NumericRatingScaleWidgetComponent,
  PharControls.NumericRatingScaleComponent,
  PharControls.VerbalRatingScaleComponent,
  PharControls.VerbalRatingScaleWidgetComponent,
  PharControls.SingleSelectComponent,
  PharControls.SingleSelectWidgetComponent,
  PharControls.GeneralBooleanComponent,
  PharControls.GeneralBooleanWidgetComponent,
  PharControls.VisualAnalogScaleWidgetComponent,
  PharControls.VisualAnalogScaleComponent,
  PharControls.TextBlockWidgetComponent,
  PharControls.TextBlockComponent,
  PharControls.TextInputComponent,
  PharControls.TextInputWidgetComponent,
  PharControls.NumberInputComponent,
  PharControls.NumberInputWidgetComponent,
  PharControls.DatePickerComponent,
  PharControls.DatePickerWidgetComponent,
  PharControls.TimePickerComponent,
  PharControls.TimePickerWidgetComponent,
  PharControls.ImageComponent,
  PharControls.ImageWidgetComponent,
];
const components = [
  ...pharControls,
  PharCardsListComponent,
  PharCardComponent,
  PharConfirmDialogComponent,
  SecondLevelMenuComponent,
  ThirdLevelMenuComponent,
  QuestionDropZoneComponent,
  BaseCardComponent,
  ListViewComponent,
  DraggableListViewComponent,
  CardListSwitcherComponent,
  ExportComponent,
  StatusBadgeComponent,
  HtmlInputComponent,
  ApprovalsComponent,
  FilterItemComponent,
  ListColumnSelectionComponent,
  // UsersAvatarsListComponent,
  // MfGridViewComponent,
  // ContextHeaderComponent,
  // GlobalSearchComponent,
  // GlobalSortComponent,
  // UnderConstructionComponent,
  // CardFooterComponent,
  // OrganizationCardComponent,
  // ButtonEditorComponent,
  // MfmInformationStripeComponent,
  // AutocompleteFieldSelectComponent,
  // BooleanColumnComponent,
  // StringColumnComponent,
  // DateColumnComponent,
  // NumberColumnComponent,
  // FloatColumnComponent,
  // CreateProjectBtnEditorComponent,
  // MfmAutocompleterComponent,
];
const directives = [
  SecondHeaderDirective,
  AutoIncreaseFieldWidthDirective,
  AllowOnlyNumberDirective,
  NoDataFieldDirective,
  MaxInputLengthTooltipDirective,
  InputTrimDirective,
  ScrollToElementDirective
];
const declarations = [
  ...components,
  ...directives,
  SafeHtmlPipe,
  SafeStylePipe,
  SafeUrlPipe,
  BindDataFieldShortenPipe,
  TimeUnitConverterPipe,
  EmailMarkedTextPipe,
  ListFilterComponent,
  DateRangePickerComponent,
  NumberRangeComponent,
  TimeRangePickerComponent,
  QuestionCommentsDialogComponent,
  EntityCommentsComponent,
  EntityCommentComponent
];
const imports = [
  ReactiveFormsModule,
  RouterModule,
  FormsModule,
  HttpClientModule,
  NgPipesModule,
  NgxPermissionsModule,
  ColorPickerModule,
  MaterialModule,
  // NgxMatDatetimePickerModule,
  // NgxMatNativeDateModule,
  // NgxMatTimepickerModule
];
const forExports: any[] = [
  ...components,
  ...imports,
  ...directives
];

@NgModule({
  imports: [
    CommonModule,
    ...imports,
    // NgxSummernoteModule,
    UserAvatarComponent,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    FormTypePipe,
    ElementTypePipe,
    ManageFiltersPipe,
    PharTooltipDirective,
    PharAutocompleterComponent,
    HelpIconComponent,
    NoPropagationDirective,
  ],
  declarations,
  exports: [
    forExports,
    SafeStylePipe,
    SafeHtmlPipe,
    TimeUnitConverterPipe,
    ListFilterComponent,
    NumberRangeComponent,
    TimeRangePickerComponent,
    EntityCommentsComponent

  ],
  providers: [
    // MfmTreeViewService,
    PharConfirmDialogService,
    PharDragService,
    PendingChangesGuard,
    DatePipe,
    FormTypePipe
  ]
})
export class SharedModule {
}

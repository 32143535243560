import { PageOrientationEnum } from './page-orientation.enum';
import { PageSizeEnum } from './page-size.enum';
import { MarginsFormatEnum } from './margins-format.enum';

export const DefaultPrintSettings = {
  landscape: PageOrientationEnum.Portrait,
  format: PageSizeEnum.A4,
  marginTop: 0.75,
  marginRight: 0.52,
  marginBottom: 1.44,
  marginLeft: 0.75,
  marginFormat: MarginsFormatEnum.IN
}

<div class="d-flex flex-wrap list-cards-wrapper pb-5">
  @for (card of cards; track trackFn) {
    <phar-card
      class="d-inline-block"
      [card]="card"
      [deletePermission]="permissionDelete"
      [moreOptions]="moreOptions"
      [footerInfoLeft]="footerInfoLeft"
      [footerInfoRight]="footerInfoRight"
    />
  }

  @if (!cards.length) {
    <div class="mx-auto mt-5">
      {{ noDataMessage }}
    </div>
  }
</div>

import { Component, Input, Output, EventEmitter } from '@angular/core';


export enum ListCardView {
  Card = 'card',
  List = 'list'
}

@Component({
  selector: 'phar-card-list-switcher',
  templateUrl: './card-list-switcher.component.html',
  styleUrls: ['./card-list-switcher.component.scss']
})
export class CardListSwitcherComponent {
  @Input() view: ListCardView = ListCardView.List;
  @Output() afterViewSelected: EventEmitter<ListCardView> = new EventEmitter();

  protected readonly ListCardView = ListCardView;
}

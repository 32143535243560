import { Component, Input } from '@angular/core';

import { Store } from '@ngrx/store';

import { AppState } from '../../../../store/models/app.state';
import { FormElementsEnum } from '../../../../form/form-elements.enum';
import { PharDragService } from '../../../drag.service';
import { setDragged, setDraggedHelper } from '../../../../store/actions/ui.actions';

@Component({
  selector: 'phar-multi-select-widget',
  templateUrl: './multi-select-widget.component.html',
  styleUrls: ['./multi-select-widget.component.scss']
})
export class MultiSelectWidgetComponent {
  @Input() draggable = true;
  type = FormElementsEnum.MultiSelect;

  get dragData(): any {
    return {
      control: {
        controlID: '',
        label: '',
        info: '',
        controlType: this.type,
        validators: {},
        bindDataField: '',
        dependencies: {},
        values: []
      }
    };
  }

  constructor(
    public store: Store<AppState>,
    public dragService: PharDragService
  ) {
  }

  onDragStart(event): void {
    if (!this.draggable) {
      return;
    }
    event.stopImmediatePropagation();
    this.dragService.setDragData(this.dragData);
    this.store.dispatch(setDragged({ dragged: true }));
    this.store.dispatch(setDraggedHelper({ draggedHelper: { type: this.type, reorder: false } }));
  }

  onDragEnd(): void {
    if (!this.draggable) {
      return;
    }
    this.store.dispatch(setDragged({ dragged: false }));
    this.store.dispatch(setDraggedHelper({ draggedHelper: {} }));
  }

}

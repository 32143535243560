<div class="mfm-timepicker-control" [ngClass]="{'mfm-timepicker-control--active': isFocused, 'disabled': disabled}">
  <input
    #inputElement
    class="mfm-timepicker-control__input"
    type="number"
    pharAllowOnlyNumber
    [max]="max"
    [min]="min"
    [disabled]="disabled"
    [maxAllowedCharacters]="2"
    [placeholder]="placeholder"
    [ngModel]="timeControl"
    (ngModelChange)="handleChanges($event)"
    (keyup.arrowdown)="onKeyUp($event)"
    (keyup.arrowup)="onKeyUp($event)">
  <div class="mfm-timepicker-control__arrows">
      <span (click)="increase()" class="mfm-timepicker-control__arrow" role="button">
        &#9650;
      </span>
    <span (click)="decrease()" class="mfm-timepicker-control__arrow" role="button">
        &#9660;
      </span>
  </div>
</div>

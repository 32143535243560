<div class="grid-list-switcher-view">
  <button (click)="afterViewSelected.emit(view = ListCardView.List)" [ngClass]="{'active-switch-button': view === ListCardView.List}"
          class="cursor-pointer left-switch-button"
          mat-button>
    <mat-icon svgIcon="list"></mat-icon>
  </button>
  <button (click)="afterViewSelected.emit(view = ListCardView.Card)" [ngClass]="{'active-switch-button': view === ListCardView.Card}"
          class="cursor-pointer right-switch-button"
          mat-button>
    <mat-icon svgIcon="grid"></mat-icon>
  </button>
</div>

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isBoolean } from 'lodash-es';
import { PharConfirmDialogComponent } from './confirm-dialog.component';

@Injectable()
export class PharConfirmDialogService {

  constructor(public dialog: MatDialog) {
  }

  openConfirmDialog(
    description: string,
    title = 'Please Confirm',
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    disableClose = false,
    width = 300,
    allowNonBooleanResponse = false,
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(PharConfirmDialogComponent, {
      width: width + 'px',
      data: {
        title,
        description,
        confirmText,
        cancelText,
        disableClose: disableClose
      },
      autoFocus: false
    });
    return dialogRef.afterClosed().pipe(filter((result) => {
      return allowNonBooleanResponse ? true : isBoolean(result)
    }));
  }

}

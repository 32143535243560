import { ControlModel } from '../../question/control.model';


export interface CurrentControlManage {
  control: ControlModel;
}

export interface ControlState {
  current: CurrentControlManage;
}


export const CONTROL_INITIAL_STATE = () => ({
  control: {
    addButton: '',
    controlID: '',
    question_uuid: '',
    page_uuid: '',
    title: '',
    label: '',
    controlType: '',
    validators: {},
    dependencies: {},
    bindDataField: '',
    info: '',
    text: '',
    values: [],
    grid: {
      columnStart: null,
      columnEnd: '',
      rowStart: null
    },
    inRepeatable: false
  },
})

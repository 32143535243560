<phar-base-control-template
  controlName="Verbal Rating Scale"
  [control]="control()"
  [question]="question"
  [builderMode]="builderMode"
  [isDraggable]="isDraggable"
  [isCommentsVisible]="isCommentsVisible"
  [canManageControl]="canManageControl"
  [showActionButtons]="(isControlActive$ | async) || (isControlHovered$ | async)"
  [highlightBackground]="isControlHighlighted$ | async"
  [hasConditionalRules]="hasConditionalRules()"
  (actionClick)="actionsHandler($event, control())">
  <div class="d-flex flex-column">
    @for (value of control().values; track value; let i = $index) {
      <button class="m-1 no-pointer-events button-content-height" mat-stroked-button>
        <span [innerHTML]="value.label | safeHtml" class="button-text"></span>
      </button>
    }
  </div>
</phar-base-control-template>



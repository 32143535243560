<!-- <aside class="mfm-slm" [class.mfm-slm--no-header]="!!!(header | async)" [@toggleInOut]="sidebar | async">
  <div class="mfm-slm_collapse cursor-pointer" (click)="toggleMenu()"
       [class.mfm-slm_collapse--closed]="(sidebar | async) === 'close'">
    <mat-icon>fast_rewind</mat-icon>
  </div>
  <mat-nav-list class="mfm-slm_list">
    <ng-container *ngFor="let link of links">
      <mat-list-item class="mfm-slm_link" routerLinkActive="active"
                     [routerLink]="[link.url]"
                     [class.mfm-slm_link--closed]="(sidebar | async) === 'close'"
                     [matTooltip]="link.text"
                     matTooltipPosition="right"
                     [matTooltipDisabled]="(sidebar | async) === 'open'"
                     [ngClass]="link?.additionalClass || ''">
        <span class="{{link.iconClass}} mfm-slm_link-icon"></span>
        <span class="ms-2 mfm-slm_link-text"
              [class.mfm-slm_link-text--hiden]="(sidebar | async) === 'close'">{{link.text}}</span>
      </mat-list-item>
    </ng-container>
  </mat-nav-list>
</aside> -->

<div class="mfm-tlm d-flex justify-content-between p-0 phar-slm-wrapper">
  <div class="d-flex">
    <ng-container *ngFor="let link of links">
      <button
        [disabled]="link.isDisabled"
        [routerLink]="[link.url]"
        class="mfm-tlm_link"
        mat-button
        routerLinkActive="active">
      <span class="d-flex align-items-center">
        {{ link.text }}
      </span>
      </button>
    </ng-container>
  </div>

  <div class="overflow-hidden">
    <ng-content></ng-content>
  </div>
</div>

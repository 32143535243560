<phar-base-control-template
  controlName="Multi Select"
  [control]="control()"
  [question]="question"
  [builderMode]="builderMode"
  [isDraggable]="isDraggable"
  [isCommentsVisible]="isCommentsVisible"
  [canManageControl]="canManageControl"
  [showActionButtons]="(isControlActive$ | async) || (isControlHovered$ | async)"
  [highlightBackground]="isControlHighlighted$ | async"
  [hasConditionalRules]="hasConditionalRules()"
  (actionClick)="actionsHandler($event, control())">
  <div>
    <div
      *ngFor="let value of control().values"
      [class.form-check-inline]="(control().settings && control().settings.horizontal)"
      class="form-check outline container-outline d-flex align-items-center">
      <input class="no-pointer-events" type="checkbox">
      <label
        [innerHTML]="value.label | safeHtml"
        class="form-check-label ps-2">
      </label>
    </div>
  </div>
</phar-base-control-template>

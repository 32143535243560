import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Store } from '@ngrx/store';
import { AppState, getState } from '../store/models/app.state';


import { AppConfig } from '../core/config/app.config';
import { QuestionModel } from './question.model';
import { PharHttp } from '../core/http/http-client.service';
import { each, reject } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  private isAutoCreate: boolean;
  private saveControlBeforeSaveQuestion: boolean;

  constructor(
    private store: Store<AppState>,
    private http: PharHttp,
    private config: AppConfig
  ) {
  }

  getQuestionList(datasetId: number): Observable<QuestionModel[]> {
    return this.http.get(this.config.buildUri('question') + '/QuestionsByDatasetId/?id=' + datasetId);
  }

  getQuestionListByForm(formId: number): Observable<QuestionModel[]> {
    return this.http.get(this.config.buildUri('question') + '/QuestionsByFormId/?id=' + formId);
  }

  deleteQuestion(id: number, form = false): Observable<any> {
    const urlPart = form ? 'FormQuestion' : 'Question';
    return this.http.delete(this.config.buildUri('question') + '/' + urlPart + '/?id=' + id);
  }

  deleteQuestions(ids: number[]): Observable<any> {
    return this.http.delete(this.config.buildUri('question') + '/DeleteMultipleQuestionsAsync/?ids=' + ids.join(','));
  }

  create(question: QuestionModel): Observable<QuestionModel> {
    delete question.id;
    const urlPart = question.formId ? 'FormQuestion' : 'Question';
    question.organizationId = getState(this.store).user.profile.organizationId;
    question.userIdCreated = getState(this.store).user.profile.userId;
    const payload = { ...question, controls: this.removeDropZonesDeep(question.controls) }
    return this.http.post(this.config.buildUri('question') + '/' + urlPart, payload);
  }

  update(question: QuestionModel): Observable<QuestionModel> {
    const urlPart = question.formId ? 'FormQuestion' : 'Question';
    question.userIdUpdated = getState(this.store).user.profile.userId;
    const payload = { ...question, controls: this.removeDropZonesDeep(question.controls) }
    return this.http.put(this.config.buildUri('question') + '/' + urlPart, payload);
  }

  updateMultiple(questions: QuestionModel[]): Observable<QuestionModel[]> {
    return this.http.put(this.config.buildUri('question') + '/UpdateMultipleQuestionsAsync', questions);
  }

  /**
   * Search question by ID
   * @param id: (string) question ID
   */
  searchById(id: number): Observable<QuestionModel> {
    return this.http.get(this.config.buildUri('question') + '/QuestionById/?id=' + id);
  }

  removeDropZonesDeep(controls): any {
    controls = reject(controls, c => c['controlType'] === 'dropzone');
    each(controls, (item, idx) => {
      if (controls[idx].controls) {
        controls[idx].controls = this.removeDropZonesDeep(controls[idx].controls);
      }
    });
    return controls;
  }

  setQuestionAutoCreateMode(auto: boolean) {
    this.isAutoCreate = auto;
  }

  isQuestionAutoCreateMode() {
    return this.isAutoCreate;
  }

  setSaveControlBeforeSaveQuestion(value: boolean) {
    this.saveControlBeforeSaveQuestion = value;
  }

  shouldSaveControlBeforeSaveQuestion() {
    return this.saveControlBeforeSaveQuestion;
  }
}

<div role="group" class="input-container"
     [formGroup]="formGroup"
     [attr.aria-labelledby]="_formField.getLabelId()"
     (focusin)="onFocusIn($event)"
     (focusout)="onFocusOut($event)">
  <input class="input-element"
         formControlName="start"
         type="number"
         [placeholder]="startPlaceholder"
         (input)="_handleInput()"
         #startInput>
  <span class="input-spacer">&ndash;</span>
  <input class="input-element"
         formControlName="end"
         type="number"
         [placeholder]="endPlaceholder"
         (input)="_handleInput()"
         #endInput>
</div>



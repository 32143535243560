export enum AssignTypeEnum {

  // Old enums
  // 'GroupAssign' = 2,
  // 'UsersAssign' = 1

  // Taken the values from FormTypeEnum and added AssignGroupEntity
  DataCapture = 1,
  Questionnaire = 2,
  Report = 3,
  EObsRO = 4,
  InformedConsent = 5,
  AssignGroupEntity = 6,
}

import { Observable, throwError as observableThrowError } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import { errorPopup } from '../../store/actions/ui.actions';


@Injectable({ providedIn: 'root' })
export class PharHttp {
  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) {
  }


  get<Type = any>(url: string, httpParams?: HttpParams): Observable<Type> {
    let headers = new HttpHeaders();
    return this.http.get<any>(url, {
      headers,
      params: httpParams
    }).pipe(catchError(this.handleError));
  }

  post<Type = any>(url: string, data?: any, options = { headers: new HttpHeaders() }): Observable<Type> {
    return this.http.post<any>(url, data, options).pipe(catchError(this.handleError));
  }

  put<Type = any>(url: string, data: any): Observable<Type> {
    let headers = new HttpHeaders();
    return this.http.put<any>(url, data, {
      headers
    }).pipe(catchError(this.handleError));
  }

  delete(url: string, body?: any): Observable<any> {
    let headers = new HttpHeaders();
    return this.http.delete(url, {
      headers,
      body
    }).pipe(catchError(this.handleError));
  }

  handleError = (res) => {
    const message = res.error && res.error.message ? res.error.message : 'There is an error';
    if (res.error && res.error.message) {
      this.store.dispatch(errorPopup({ error: message }));
    }
    return observableThrowError(new Error(message));
  }

}

import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[pharAllowOnlyNumber]'
})
export class AllowOnlyNumberDirective {
  @Input() maxAllowedCharacters: number;
  @Input() max: number;
  @Input() min: number;
  @Input() allowNegatives: boolean;
  POSITIVE_NUMBERS_REGEX: RegExp = /[^0-9]*/g;
  NEGATIVE_NUMBERS_REGEX: RegExp = /[^-?0-9]*/g;

  constructor(private el: ElementRef) {
  }

  @HostListener('input', ['$event']) onInputChange(event): void {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue.replace(this.allowNegatives ?
      this.NEGATIVE_NUMBERS_REGEX :
      this.POSITIVE_NUMBERS_REGEX, '');
    if (this.el.nativeElement.value === '') {
      return;
    }
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
      this.dispatchChange();
      return;
    }
    if (Number(this.el.nativeElement.value) > this.max || Number(this.el.nativeElement.value) < this.min) {
      const timeString = String(this.el.nativeElement.value);
      this.el.nativeElement.value = +timeString[timeString.length - 1];
      this.dispatchChange();
      event.stopPropagation();
    }
    if (this.maxAllowedCharacters) {
      if (this.el.nativeElement.value.length > this.maxAllowedCharacters) {
        event.stopPropagation();
        if (this.maxAllowedCharacters) {
          this.el.nativeElement.value = this.el.nativeElement.value.slice(-this.maxAllowedCharacters);
        } else {
          this.el.nativeElement.value = '00';
        }
        this.dispatchChange();
      }
    }
  }

  dispatchChange() {
    this.el.nativeElement.dispatchEvent(new Event('input', { bubbles: true }))
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrentControlValidationService {
  private readonly _editorFormValidObservables$: BehaviorSubject<Observable<boolean>[]>;
  private readonly _isEditorFormsValid$: Observable<boolean>;
  private readonly _markFormAsTouched$: Subject<null>;
  private _isEditorFormsValid: boolean = true;
  readonly markFormAsTouched$: Observable<any>;

  constructor() {
    this._editorFormValidObservables$ = new BehaviorSubject<Observable<boolean>[]>([]);
    this._markFormAsTouched$ = new Subject<null>();
    this.markFormAsTouched$ = this._markFormAsTouched$.asObservable();
    this._isEditorFormsValid$ = this._editorFormValidObservables$.pipe(
      switchMap(formValidObservables => {
        return formValidObservables.length ? combineLatest([...formValidObservables]) : of([]);
      }),
      map((formsValid) => formsValid.every((isFormValid) => isFormValid)),
      distinctUntilChanged(),
    );

    this._isEditorFormsValid$.subscribe((isEditorFormsValid) => {
      this._isEditorFormsValid = isEditorFormsValid;
    });
  }

  get isEditorFormsValid(): boolean {
    return this._isEditorFormsValid;
  }

  setFormValidObservable(observable: Observable<boolean>): void {
    const editorFormValidObservables = this._editorFormValidObservables$.value;

    this._editorFormValidObservables$.next([...editorFormValidObservables, observable]);
  }

  markFormAsTouched(): void {
    this._markFormAsTouched$.next(null);
  }

  resetFormValidObservables(): void {
    this._editorFormValidObservables$.next([]);
  }
}

import { Injectable, TemplateRef } from '@angular/core';
import { AppConfig } from './config/app.config';
import { AppState, getState } from '../store/models/app.state';
import { Action, Store } from '@ngrx/store';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { isArray } from 'lodash-es';
import { DatasetDataTypeEnum } from '../dataset/dataset-data-type.enum';
import { ControlModel } from '../question/control.model';
import { Moment } from 'moment/moment';
import moment from 'moment';
import { FormModel } from '../form/form.model';
import { Observable, of } from 'rxjs';
import { QuestionModel } from '../question/question.model';
import { PharConfirmDialogService } from '../shared/confirm-dialog/confirm-dialog-service.service';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { TemplateColumn } from '../shared/models/table-column';
import { IPermission } from '../shared/models/permission.interface';
import { EntityCommentState } from '../shared/entity-comments/entity-comment.state.enum';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private appConfig: AppConfig) {
  }

  public formatDate(date: Moment): string {
    return moment(date).format(this.appConfig.config.dateTimeFormatToISO)
  }

  findDatasetFieldOrEntity(dataset: any, rootPath: string, lookingFor: string): any {
    let found;
    if (lookingFor === `${rootPath}.${dataset.name}` || lookingFor === rootPath) {
      found = dataset;
    }

    for (const key in dataset.data) {
      if (dataset.data.hasOwnProperty(key)) {

        const element = dataset.data[key];

        if (lookingFor === `${rootPath}.${element.name}`) {
          found = element;
          throw (found);
        }

        if (element.type === 'entity') {
          found = this.findDatasetFieldOrEntity(dataset.data[key], `${rootPath}.${element.name}`, lookingFor);
        }
      }
    }
    return found;
  }

  public appendFilePrefix(bindDataField: string): string {
    if (bindDataField) {
      const toArray: Array<string> = bindDataField.split('.');
      const last: number = toArray.length - 1;
      toArray[last] = this.appConfig.config.fileFieldPrefix + toArray[last];
      return toArray.join('.');
    } else {
      return '';
    }

  }

  /**
   * Dispatch multiple actions
   * @param store of host
   * @param actions list
   */
  public dispatchActions(store: Store<AppState>, actions: Action[]): void {
    actions.forEach(action => store.dispatch(action));
  }

  public markAllControlsAsTouched(fg: UntypedFormGroup): void {
    Object.keys(fg.controls).forEach((formKey => {
      const ctrl: AbstractControl = fg.get(formKey);
      ctrl.markAsTouched();
      if (ctrl.hasOwnProperty('controls')) {
        if (isArray((ctrl as UntypedFormArray).controls)) {
          // FormArray with FormGroups
          (ctrl as UntypedFormArray).controls.forEach((innerFg) => {
            this.markAllControlsAsTouched(innerFg as UntypedFormGroup);
          });
        } else {
          // another nested FormGroup
          this.markAllControlsAsTouched(ctrl as UntypedFormGroup);
        }
      }
    }));
  }

  /**
   * Set Column templates, The generic type must implement Template Column interface
   * @param columns
   * @param templates
   * @param key
   */
  setColumnTemplate<C extends TemplateColumn>(columns: C[], templates: {
    [key: string]: TemplateRef<any>
  }, key: string): C[] {
    return columns.map((column: C) => {
      if (column.hasOwnProperty(key) && templates[column[key]] && column.hasTemplate) {
        column.template = templates[column[key]];
      }
      return column;
    });
  }

  createDatasetSchema(data, parent, valueTypes: { Name: string, ValueType: DatasetDataTypeEnum }[] = []): {
    [key: string]: any
  } {
    let datasetSchema = {};
    for (const label in data) {
      if (data.hasOwnProperty(label)) {
        const newLabel = parent ? parent + '.' + label : label;
        if (data[label].isRepeatable) {
          datasetSchema[parent ? parent + '.' + data[label].name : data[label].name] = [];
          valueTypes.push({
            Name: parent ? parent + '.' + data[label].name : data[label].name,
            ValueType: data[label].ValueType
          });
          datasetSchema[parent ? parent + '.' + data[label].name : data[label].name][0] =
            this.createDatasetSchema(data[label].data, newLabel, valueTypes);
        } else if (data[label].type === DatasetDataTypeEnum.Entity) {
          valueTypes.push({
            Name: parent ? parent + '.' + data[label].name : data[label].name,
            ValueType: data[label].ValueType
          });
          datasetSchema = { ...datasetSchema, ...this.createDatasetSchema(data[label].data, parent + '.' + data[label].name, valueTypes) };

        } else if (data[label].type === DatasetDataTypeEnum.File) {
          const name = this.appConfig.config.fileFieldPrefix + data[label].name;

          datasetSchema[parent ? parent + '.' + name : name] = this.setTypeValue(data[label].type);
          valueTypes.push({
            Name: parent ? parent + '.' + name : name,
            ValueType: data[label].ValueType
          });
        } else {
          datasetSchema[parent ? parent + '.' + data[label].name : data[label].name] = this.setTypeValue(data[label].type);
          valueTypes.push({
            Name: parent ? parent + '.' + data[label].name : data[label].name,
            ValueType: data[label].ValueType
          });
        }

      }
    }
    return datasetSchema;
  }

  setTypeValue(type): string | boolean {
    switch (type) {
      case type === DatasetDataTypeEnum.Date:
      case type === DatasetDataTypeEnum.String:
        return '';
      case type === DatasetDataTypeEnum.File:
      case type === DatasetDataTypeEnum.Float:
      case type === DatasetDataTypeEnum.Number:
        return null;
      case type === DatasetDataTypeEnum.Boolean:
        return false;
      default:
        return '';
    }
  }

  generateUUID(): string {
    let timestamp = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (timestamp + Math.random() * 16) % 16 | 0;
      timestamp = Math.floor(timestamp / 16);
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  waitForElementToExist(selector: string, maxAttempts = 40, interval = 100): Promise<Element> {
    return new Promise((resolve, reject) => {
      let attempts = 0;
      const checkInterval = setInterval(() => {
        const element: Element = document.querySelector(selector);
        if (element) {
          clearInterval(checkInterval);
          resolve(element);
        } else {
          attempts++;
          if (attempts === maxAttempts) {
            clearInterval(checkInterval);
            reject(new Error('Element not found within max attempts'));
          }
        }
      }, interval);
    });
  }

  public generateNewControlTitleAndLabel(control: ControlModel, store: Store<AppState>, usedData?: {
    labels: string[],
    titles: string[]
  }): {
    title: string,
    label: string
  } {
    const itemDefaultName = 'Item ' + this.generateUUID();
    return this.updateControlTitleAndLabel({
      title: `${control.title ?? itemDefaultName} (copy)`,
      label: `${control.label ?? itemDefaultName} (copy)`
    }, store, getState(store).form.current.form, usedData);
  }

  public updateControlTitleAndLabel(data: {
    title: string,
    label: string
  }, store: Store<AppState>, form: FormModel, usedData?: {
    labels: string[],
    titles: string[]
  }): {
    title: string,
    label: string
  } {
    const needsCopy = form.questions.some(questionId => form.questionsPopulated[questionId].controls.some(c => c.title === data.title && c.label === data.label));

    if (needsCopy || (usedData && usedData?.titles.includes(data.title) || usedData?.labels.includes(data.label))) {
      data = this.updateControlTitleAndLabel({
        label: data.label + ' (copy)',
        title: data.title + ' (copy)'
      }, store, form, usedData);
    }

    return data;
  }

  generateUniqueNumber(): number {
    return Math.floor(Math.random() * Date.now());
  }

  public formExportApprovalEmptyQuestionValidation(
    form: FormModel,
    confirmDialog: PharConfirmDialogService,
    router: Router,
    mode: 'export' | 'approve' = 'export',
  ): Observable<FormModel | null> {
    const questionIds = form.body.pages.map((page) => page.questions.map((question) => question.id)).flat();
    const questions: QuestionModel[] = questionIds.map((questionId) => form.questionsPopulated[questionId]);
    if (!questions.length) {
      return of(form)
    }
    const invalidQuestion: QuestionModel | undefined = questions.find((question) =>
      !question.controls.length || (question.controls.some(c => c.controlType !== 'dropzone' && !c.label))
    );
    if (!invalidQuestion) {
      //form is valid then return it
      return of(form)
    }

    // continue to show the first match page with invalid question

    const page = form.body.pages.findIndex(page => page.questions.find(q => q.id === Number(invalidQuestion.id)));

    return confirmDialog.openConfirmDialog(`You have an empty question/not completed question at page <b>${page + 1}</b>. <br/>
      Complete the question properties or delete the question to proceed with the ${mode === 'export' ? 'exporting' : 'approving'}.`, 'Please check',
      'Go to the page', 'Close', true, 320
    ).pipe(
      map((result) => {
        if (result) {
          router.navigate([`dashboard/workspace/edit/${form.id}/content`], { queryParams: { page: page + 1 } });
        }
        return null;
      }),
      map(() => null)
    )
  }

  public formExportApprovalUnresolvedCommentsValidation(
    form: FormModel,
    confirmDialog: PharConfirmDialogService,
    router: Router,
  ): Observable<FormModel | null> {
    const questionIds = form.body.pages.map((page) => page.questions.map((question) => question.id)).flat();
    const questions: QuestionModel[] = questionIds.map((questionId) => form.questionsPopulated[questionId]);
    if (!questions.length) {
      return of(form);
    }

    const questionWithUnresolvedComments = questions.find((question: QuestionModel) => {
      return !!question.controls.length && question.controls.some((control: ControlModel) =>
        control.commentsState === EntityCommentState.HasUnresolved
      );
    });

    if (!questionWithUnresolvedComments) {
      return of(form);
    }

    const unresolvedCommentsQuestionPageIndex = form.body.pages.findIndex(page =>
      page.questions.find(q => q.id === Number(questionWithUnresolvedComments.id))
    );

    const message = `There are still comments to be resolved. Please return to form to review all flagged items.`;
    return confirmDialog.openConfirmDialog(
      message,
      'Please check',
      'Go to the page',
      'Close',
      true,
      320,
    ).pipe(
      filter(isConfirmed => !!isConfirmed),
      map(() => {
        router.navigate([`dashboard/workspace/edit/${form.id}/content`], { queryParams: { page: unresolvedCommentsQuestionPageIndex + 1 } });

        return null;
      }),
      filter(() => false),
    );
  }

  public trimToLower(val: string): string {
    if (!val || val === '') {
      return val;
    }
    return val.trim().toLowerCase();
  }

  /**
   * Version can be in the follow format x | x.y | x.y.z
   * @param version
   * @param part "major", "minor", or "patch"
   */
  public incrementVersion(version, part: 'major' | 'minor' | 'patch'): string {
    // Split version into parts and ensure it has at least 3 parts (major.minor.patch)
    let parts = version.split('.');
    while (parts.length < 3) {
      parts.push('0');
    }

    // Parse the parts into integers
    parts = parts.map(num => parseInt(num, 10));

    switch (part) {
      case 'major':
        parts[0] += 1; // Increment major version
        parts[1] = 0;  // Reset minor version
        parts[2] = 0;  // Reset patch version
        break;
      case 'minor':
        parts[1] += 1; // Increment minor version
        parts[2] = 0;  // Reset patch version
        break;
      case 'patch':
        parts[2] += 1; // Increment patch version
        break;
      default:
        throw new Error('Invalid part to increment. Use "major", "minor", or "patch".');
    }

    // Join the parts back into a version string
    return parts.join('.');
  }

  parsePermissionNames(
    permission: IPermission,
    uncategorizedName = ''
  ): { categoryName: string, groupName: string, name: string } {
    //permissionName format [crud operation]:[module / object]:[group]:[category];
    //group name is [group] + [module / object]
    const parts = permission.permissionName.split(':');
    const categoryName = parts[3] ? `${parts[3].charAt(0).toUpperCase()}${parts[3].slice(1)}` : uncategorizedName;
    const groupName = parts[2] ? `${parts[2].charAt(0).toUpperCase()}${parts[2].slice(1)}` : '';
    const moduleName = parts[1] ? `${parts[1].charAt(0).toUpperCase()}${parts[1].slice(1)}` : '';

    const categoryGroupName = groupName && moduleName ? `${groupName} ${moduleName}` : '';

    return {
      categoryName,
      groupName: categoryGroupName,
      name: permission.description,
    };
  }


}

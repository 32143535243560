import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ListViewColumnModel } from '../list-view/list-view-column.model';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isEqual } from 'lodash-es';

export interface ColumnsChangeNotifier {
  onColumnsChange(): void;
}

@Component({
  selector: 'phar-list-column-selection',
  templateUrl: 'list-column-selection.component.html',
  styleUrls: ['list-column-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListColumnSelectionComponent implements OnInit {
  @Input({required: true}) columns: ListViewColumnModel[];
  @Input() listComponent: ColumnsChangeNotifier ;
  @Output() columnsSelectionChange = new EventEmitter<ListViewColumnModel[]>();
  @ViewChild(MatMenuTrigger) matMenu: MatMenuTrigger;
  selectedColumnFieldsFormControl: FormControl<string[]> = new FormControl([]);
  defaultSelectedColumnFields: string[];
  hasChanges$: Observable<boolean>;

  ngOnInit(): void {
    this.defaultSelectedColumnFields = this.getSelectedColumnFields(this.columns);
    this.hasChanges$ = this.selectedColumnFieldsFormControl.valueChanges.pipe(
      map((selectedFields) => !isEqual(selectedFields, this.getSelectedColumnFields(this.columns))),
      distinctUntilChanged(),
    );
  }

  handleOpenMenu(): void {
    const selectedColumnFields: string[] = this.getSelectedColumnFields(this.columns);
    this.selectedColumnFieldsFormControl.setValue(selectedColumnFields);
  }

  handleCloseMenu(): void {
    this.selectedColumnFieldsFormControl.setValue([]);
  }

  saveSelections(): void {
    const selectedColumnFields: string[] = this.selectedColumnFieldsFormControl.getRawValue();
    const columns: ListViewColumnModel[] = this.columns.map((col) => {
      if (!col.selectable) {
        return col;
      }

      return {
        ...col,
        show: selectedColumnFields.includes(col.field),
      };
    });

    this.columnsSelectionChange.emit(columns);
    this.matMenu.closeMenu();
    
    if (this.listComponent && this.listComponent.onColumnsChange) {
      this.listComponent.onColumnsChange();
    }
  }

  cancelSelections(): void {
    this.matMenu.closeMenu();
  }

  restoreDefaults(): void {
    this.selectedColumnFieldsFormControl.setValue(this.defaultSelectedColumnFields);
  }

  private getSelectedColumnFields(columns: ListViewColumnModel[]): string[] {
    return columns.filter((col) => col.selectable && col.show).map((col) => col.field);
  }

}

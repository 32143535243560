import { Component, OnDestroy } from '@angular/core';
import { FormService } from "../../form/form.service";
import { Store } from "@ngrx/store";
import { AppState } from "../../store/models/app.state";
import { filter, map, switchMap, take, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { of } from "rxjs";
import { BaseComponent } from '../base.class';
import { selectCurrentFormStateForm } from '../../form/store/form.state';
import { PharConfirmDialogService } from '../confirm-dialog/confirm-dialog-service.service';
import { FormModel } from '../../form/form.model';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../core/utils.service';
import moment from 'moment';


@Component({
  selector: 'phar-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent extends BaseComponent implements OnDestroy {

  constructor(
    private readonly formService: FormService,
    private readonly store: Store<AppState>,
    private dialog: PharConfirmDialogService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService,
  ) {
    super();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  downloadJsonFile(): void {
    this.store.select(selectCurrentFormStateForm)
      .pipe(
        take(1),
        switchMap((form: FormModel) => this.utilsService.formExportApprovalEmptyQuestionValidation(form, this.dialog, this.router, 'export')),
        filter<FormModel>((form) => !!form),
        switchMap((form: FormModel) => this.utilsService.formExportApprovalUnresolvedCommentsValidation(form, this.dialog, this.router)),
        switchMap((form: FormModel) => {
          return this.formService.getExportFormJson(form.id)
            .pipe(withLatestFrom(of(form)));
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: ([exportFormJson, form]) => {
          const blob = new Blob([exportFormJson], { type: 'application/json' });
          const blobUrl = URL.createObjectURL(blob);
          const a = document.createElement('a');

          a.style.display = 'none';
          a.setAttribute('href', blobUrl);
          a.setAttribute(
            'download',
            `${form.name}-${this.utilsService.formatDate(moment())}.json`,
          );

          document.body.appendChild(a);
          a.click();

          document.body.removeChild(a);
          URL.revokeObjectURL(blobUrl);
        }
      });

  }
}

import { ChangeDetectionStrategy, Component, computed, inject, Injector, input, Input } from '@angular/core';
import { FormStatusEnum } from '../../form/form.model';
import { ProjectStatusEnum } from '../../../app/project/project.model';
import { EntityType } from '../../core/models/entity-type-enum';
import { UserStatusEnum } from '../../user/user-status.enum';

@Component({
  selector: 'phar-status-badge',
  templateUrl: 'status-badge.component.html',
  styleUrls: ['status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusBadgeComponent {
  status = input<FormStatusEnum | ProjectStatusEnum | UserStatusEnum>();
  type = input<EntityType>();
  injector = inject(Injector);
  currentStatus = computed(() => {
    switch (this.type()) {
      case (EntityType.Form):
        return this.getFormattedFormStatus(this.status() as FormStatusEnum);
      case (EntityType.Project):
        return this.getFormattedProjectStatus(this.status() as ProjectStatusEnum);
      case (EntityType.User):
        return this.getFormattedUserStatus(this.status() as UserStatusEnum);
      default:
        return this.type;
    }
  },)

  getFormattedFormStatus(status: FormStatusEnum): string {
    switch (status) {
      case FormStatusEnum.Draft:
        return 'Draft';
      case FormStatusEnum.Rejected:
        return 'Rejected';
      case FormStatusEnum.Released:
        return 'Released';
      case FormStatusEnum.UnderReview:
        return 'Under Review';
      case FormStatusEnum.PendingReview:
        return 'Pending Review';
      case FormStatusEnum.Amended:
        return 'Amended';
    }
  }

  getFormattedProjectStatus(status: ProjectStatusEnum): string {
    switch (status) {
      case ProjectStatusEnum.Draft:
        return 'Draft';
      case ProjectStatusEnum.Rejected:
        return 'Rejected';
      case ProjectStatusEnum.Released:
        return 'Released';
      case ProjectStatusEnum.PendingReview:
        return 'Pending Review';
      case ProjectStatusEnum.UnderReview:
        return 'Under Review';
      case ProjectStatusEnum.Amended:
        return 'Amended';
      case ProjectStatusEnum.Archived:
        return 'Archived';
    }
  }

  getFormattedUserStatus(status: UserStatusEnum): string {
    switch (status) {
      case UserStatusEnum.Active:
        return 'Active';
      case UserStatusEnum.Inactive:
        return 'Inactive';
    }
  }

}

<phar-base-control-template
  controlName="Time Picker"
  [control]="control()"
  [question]="question"
  [builderMode]="builderMode"
  [isDraggable]="isDraggable"
  [isCommentsVisible]="isCommentsVisible"
  [canManageControl]="canManageControl"
  [showActionButtons]="(isControlActive$ | async) || (isControlHovered$ | async)"
  [highlightBackground]="isControlHighlighted$ | async"
  [hasConditionalRules]="hasConditionalRules()"
  (actionClick)="actionsHandler($event, control())">
  <mat-form-field class="no-pointer-events w-100">
    <input matInput placeholder="00:00"/>
    <mat-icon matSuffix>access_time</mat-icon>
  </mat-form-field>
</phar-base-control-template>

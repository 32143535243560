import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/models/app.state';
import { PharBaseControlModelComponent } from '../phar-base-control/phar-base-control-model.component';

@Component({
  selector: 'phar-visual-analog-scale',
  templateUrl: 'visual-analog-scale.component.html',
  styleUrls: ['visual-analog-scale.component.scss'],
})
export class VisualAnalogScaleComponent extends PharBaseControlModelComponent implements OnInit {
  @ViewChild('slider', { static: true }) slider: ElementRef;
  value: number | null = 50;

  constructor(
    store: Store<AppState>
  ) {
    super(store);
  }

  isVertical(): boolean {
    return !this.control().settings.horizontal;
  }

  ngOnInit() {
    super.ngOnInit();
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/models/app.state';

import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'phar-third-level-menu',
  templateUrl: './third-level-menu.component.html',
  styleUrls: ['./third-level-menu.component.scss']
})
export class ThirdLevelMenuComponent implements OnInit, OnDestroy {

  @Input() bottomBordered = false;

  links: Observable<any[]>;
  subscription: Subscription = new Subscription();
  url: string;

  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {
    this.subscription.add(
      router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe(event => {
          const eventUrl = event.urlAfterRedirects.split('/');
          this.url = eventUrl[eventUrl.length - 1];
        })
    );
  }

  ngOnInit(): void {
    this.links = this.store.select(store => store.ui.thirdLevelMenuElements);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

import { Component } from '@angular/core';
import { PharBaseControlModelComponent } from '../phar-base-control/phar-base-control-model.component';

@Component({
  selector: 'phar-number-input',
  templateUrl: 'number-input.component.html',
  styleUrls: ['number-input.component.scss'],
})
export class NumberInputComponent extends PharBaseControlModelComponent {
  getPlaceholder(numberOfDecimalPlaces: number): string {
    const defaultPlaceHolder = '1234';

    if (numberOfDecimalPlaces) {
      return `${defaultPlaceHolder}.1`;
    }

    return defaultPlaceHolder;
  }

}

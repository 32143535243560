import { SafeHtml } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';
import { DateTimeUnitEnum } from '../../events/enums/datetime-unit.enum';

@Pipe({ name: 'pharTransformTimeUnit' })
export class TimeUnitConverterPipe implements PipeTransform {
  constructor() {
  }

  transform(value: string | number, unit: number, prefix?: string, suffix?: string, fullUnitName?: boolean): SafeHtml {
    return `${prefix || ''}${value ?? ''}${this.getUnit(unit, fullUnitName)}${suffix || ''}`;
  }

  private getUnit(unit: number, fullUnitName = false): string | number {
    switch (unit) {
      case DateTimeUnitEnum.Hour:
        return fullUnitName ? 'Hour(s)' : 'h';
      case DateTimeUnitEnum.Day:
        return fullUnitName ? 'Day(s)' : 'd';
      case DateTimeUnitEnum.Week:
        return fullUnitName ? 'Week(s)' : 'w';
      case DateTimeUnitEnum.Month:
        return fullUnitName ? 'Month(s)' : 'm';
      case DateTimeUnitEnum.Year:
        return fullUnitName ? 'Year(s)' : 'y';
      default:
        return unit;
    }
  }
}

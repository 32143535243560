<div class="list-view full-width">
  <div class="list-view_list position-relative">

    <div
      [style.grid-template-columns]="(config.columns | filterBy: ['show']: true | pluck: 'size').join(' ')"
      class="list-view_list_headers no-copy"
    >
      @for (column of config.columns | filterBy: ['show']: true; track column.field) {
        <div (click)="onOrder(column.order !== undefined ? column : undefined)"
             [class.cursor-pointer]="column.order !== undefined"
             class="d-flex">
          <span class="title"> {{ column.title }}</span>

          @if (column.field === config.order || '-' + column.field === config.order) {
            <mat-icon class="cursor-pointer sort-icon">
              @if (column.field === config.order) {
                arrow_drop_up
              } @else {
                arrow_drop_down
              }
            </mat-icon>
          }
        </div>
      }

    </div>
    <div class="list-view_list_content">
      @if (!dataSource.length) {
        <div class="list-view_list_element">
          <p id="no-data" class="d-flex justify-content-center mt-3">{{ noDataMessage }}</p>

        </div>
      }

      @if (virtualScroll) {
        <cdk-virtual-scroll-viewport [itemSize]="itemHeight"
                                     [style.height]="parentHeight - 45 + 'px'"
                                     [minBufferPx]="5 * itemHeight" [maxBufferPx]="9 * itemHeight">
          <ng-container *cdkVirtualFor="let item of dataSource | orderBy: config.order">
            <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{$implicit: item}"/>
          </ng-container>

        </cdk-virtual-scroll-viewport>
      } @else {
        @for (item of dataSource | orderBy: config.order; track $index) {
          <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{$implicit: item}"/>
        }
      }

    </div>

  </div>
</div>
<ng-template #content let-element>
  <div
    class="list-view_list_element cursor-pointer"
    [style.grid-template-columns]="(config.columns | filterBy: ['show']: true | pluck: 'size').join(' ')"
    [class.list-view_list_element--selected]="element._selected"
    [id]="'list-view-item-' + element.id"
    (click)="onClickHandler(element)"
  >
    @for (column of config.columns | filterBy: ['show']: true; let i = $index; let l = $last; track i) {
      <div class="d-flex align-items-center">
        @if ((!l || !config.actions) && column.show) {
          @if (column.hasTemplate && column.template) {
            <ng-template
              [ngTemplateOutletContext]="{$implicit: element}"
              [ngTemplateOutlet]="column.template"
            ></ng-template>
          } @else {
            <span class="three-dots-no-wrap">{{ element[column.field] || '-' }}</span>
          }
        }


        @if (l && config.actions) {
          <button class="ms-auto list-card_more"
                  mat-icon-button
                  [matMenuTriggerFor]="actionsMenu"
                  (click)="$event.stopImmediatePropagation()"
          >
            <mat-icon class="more">more_vert</mat-icon>
          </button>
          <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
            @for (button of config.actions; track $index) {
              <button mat-menu-item
                      (click)="afterActionClicked.emit({eventName:button.eventName, dataItem: element})">
                <span>{{ button.title }}</span>
              </button>
            }
          </mat-menu>
        }
      </div>
    }

  </div>

</ng-template>

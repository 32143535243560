import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../../../store/models/app.state";
import { PharBaseControlModelComponent } from "../phar-base-control/phar-base-control-model.component";
import { range } from 'lodash-es';

@Component({
  selector: 'phar-numeric-rating-scale',
  templateUrl: 'numeric-rating-scale.component.html',
  styleUrls: ['numeric-rating-scale.component.scss'],
})
export class NumericRatingScaleComponent extends PharBaseControlModelComponent implements OnInit {
  selectedScaleNumber: number | null = null;

  constructor(
    store: Store<AppState>
  ) {
    super(store);
  }

  getScaleNumbers(): number[] {
    const min = this.control().settings.min;
    const max = this.control().settings.max;
    return range(min, max + 1);
  }

  ngOnInit() {
    super.ngOnInit();
  }
}

import { Subject } from 'rxjs';
import { Directive, OnDestroy } from '@angular/core';
@Directive()
export class BaseComponent implements OnDestroy {
  destroy$ = new Subject<boolean>();

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

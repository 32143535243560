import { UserModel, UsersCountGroupByStatus } from '../user.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface UserState {
  profile: UserModel;
  errors: string[];
  list: UserModel[];
  current: UserModel;
  usersCountByStatus: UsersCountGroupByStatus;
}

export interface AppUserState {
  user: UserState;
}

export const appStateUser: AppUserState = {
  user: {
    profile: {
      userId: null,
      organizationId: null,
      sub: '',
      email: '',
      family_name: '',
      given_name: '',
      userName: '',
      subscriptionlevel: '',
      createdAt: '',
      updatedAt: '',
      userIdCreated: null,
      userIdUpdated: null,
      amr: [],
      address: '',
      country: '',
      statusId: null,
      isPending: false,
      pinCode: null,
      phoneNumber: null,
      customerIdentifier: null,
      dateOfBirthDay: null,
      dateOfBirthMonth: null,
      dateOfBirthYear: null,
      customFields: []
    },
    usersCountByStatus: {
      invitedUsers: 0,
      pendingUsers: 0,
      activeUsers: 0
    },
    list: [],
    errors: [],
    current: {
      userId: null,
      organizationId: null,
      sub: '',
      email: '',
      family_name: '',
      given_name: '',
      userName: '',
      subscriptionlevel: '',
      createdAt: '',
      updatedAt: '',
      userIdCreated: null,
      userIdUpdated: null,
      amr: [],
      address: '',
      country: '',
      statusId: null,
      isPending: false,
      pinCode: null,
      phoneNumber: null,
      customerIdentifier: null,
      dateOfBirthDay: null,
      dateOfBirthMonth: null,
      dateOfBirthYear: null,
      customFields: []
    },
  }
};

export const selectUserState = createFeatureSelector<UserState>('user');
export const selectCurrentUserProfile = createSelector(selectUserState, (state: UserState) => state.profile);
export const selectUsersList = createSelector(selectUserState, (state: UserState) => state.list);


import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { AppState } from '../../../store/models/app.state';
import { PharConfirmDialogService } from '../../confirm-dialog/confirm-dialog-service.service';
import { PharBaseControlModelComponent } from '../phar-base-control/phar-base-control-model.component';

@Component({
  selector: 'phar-date-picker',
  templateUrl: 'date-picker.component.html',
  styleUrls: ['date-picker.component.scss'],
})
export class DatePickerComponent extends PharBaseControlModelComponent {

  constructor(
    store: Store<AppState>,
    protected pharConfirmDialogService: PharConfirmDialogService
  ) {
    super(store);
  }

  save(event, control): void {
    // Intercept the base class component save method and do some validation.
    if (control.settings.year && control.settings.day && !control.settings.month) {
      const question = `Are you sure you want to collect information on day and year only, without the month?`;
      this.pharConfirmDialogService
        .openConfirmDialog(question, "", "Save", "Edit", false, 350)
        .pipe(
          take(1),
          filter(answer => !!answer)
        )
        .subscribe(() => {
          super.save(event, control);
        });
    } else {
      super.save(event, control);
    }
  }
}

/* src/app/shared/phar-dropdown-list-autocompleter/phar-autocompleter.component.scss */
:host.ng-touched.ng-invalid {
}
:host.ng-touched.ng-invalid mat-select {
}
#auto-completer-icon {
  vertical-align: middle;
  position: absolute;
  right: -10px;
  height: 30px;
  top: 3px;
  display: flex;
  align-items: center;
  padding: 0 7.5px;
  cursor: pointer;
}
#auto-completer-icon:hover {
  cursor: pointer;
}
#auto-completer-icon .mat-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
}
.disabled-item {
  text-decoration: line-through !important;
}
/*# sourceMappingURL=phar-autocompleter.component.css.map */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { QuestionCommentsService } from './question-comments.service';
import { ControlModel } from '../../question/control.model';
import { QuestionModel } from '../../question/question.model';
import { getState } from '../../store/models/app.state';
import { IQuestionComment } from './question-comment.interface';
import { errorPopup, messagePopup } from '../../store/actions/ui.actions';
import { IEntityComment } from '../models/entity-comments.interface';
import { updateQuestion } from '../../question/store/question.actions';
import { updateFormField } from '../../form/store/form.actions';
import { EntityCommentsBaseComponent } from '../entity-comments/entity-comment-base/entity-component-base.component';

@Component({
  selector: 'phar-question-comments-dialog',
  templateUrl: 'question-comments-dialog.component.html',
  styleUrls: ['question-comments-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionCommentsDialogComponent extends EntityCommentsBaseComponent implements OnInit, OnDestroy {
  @ViewChild('commentInput', { static: true }) commentInputElement: ElementRef;
  @ViewChild('commentsSection') commentsSectionElement: ElementRef;
  @ViewChild('commentsAutocomplete') commentsAutocompleteElement: ElementRef;
  @Input({ required: true }) control: ControlModel;
  @Input({ required: true }) question: QuestionModel;
  @Input() isViewOnly: boolean = false;
  @Output() requestClose: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;
  declare comments: IQuestionComment[];

  constructor(
    protected readonly changeDetector: ChangeDetectorRef,
    private readonly questionCommentsService: QuestionCommentsService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadComments(false);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  createComment(comment: IEntityComment): void {
    this.isLoading = true;
    const questionComment: IQuestionComment = {
      questionId: this.question.id,
      controlId: this.control.controlID,
      ...comment
    };
    this.questionCommentsService.create(questionComment).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.loadComments(true);
        this.store.dispatch(messagePopup({ message: 'Comment added' }));
        this.handleRequestClose();
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  deleteComment(comment: IEntityComment): void {
    this.questionCommentsService.delete(comment.id).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.loadComments();
        this.store.dispatch(messagePopup({ message: 'Comment deleted' }));
      }, error: () => {
        this.isLoading = false;
      }
    });
  }

  handleRequestClose(): void {
    this.requestClose.emit();
  }

  editComment(entity: IEntityComment): void {
    this.isLoading = true;
    this.questionCommentsService.update(entity as IQuestionComment).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.loadComments();
        this.store.dispatch(messagePopup({ message: 'Comment updated' }));
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  markCommentAsResolved(comment: IEntityComment): void {
    this.isLoading = true;
    this.questionCommentsService.markAsResolved(comment.id).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.loadComments();
        this.store.dispatch(messagePopup({ message: 'Comment updated' }));
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }


  private loadComments(refreshState = true): void {
    this.isLoading = true;
    this.questionCommentsService.getComments(this.question.id, this.control.controlID)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (comments) => {
          this.comments = comments;
          this.changeDetector.markForCheck();
          if (refreshState) {
            this.updateControlState();
            this.handleRequestClose();
          }

          this.isLoading = false;
        },
        error: () => {
          const errorMessage = 'Something happened while loading comments';
          this.store.dispatch(errorPopup({ error: errorMessage }));
          this.isLoading = false;
        }
      });
  }


  private updateControlState() {
    const currentCommentsState = this.getCommentsState();
    if (this.control.commentsState === currentCommentsState) {
      return;
    }
    const control = {
      ...this.control,
      commentsState: currentCommentsState,
    };
    const controls = [
      ...this.question.controls.filter((control) => control.controlID !== this.control.controlID),
      control,
    ];
    const question = {
      ...this.question,
      controls,
    };
    const questionsPopulated = {
      ...getState(this.store).form.current.form.questionsPopulated,
      [question.id]: question,
    };

    this.store.dispatch(updateQuestion({ question }));
    this.store.dispatch(updateFormField({ field: 'questionsPopulated', value: questionsPopulated }))
  }
}

import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { AppState } from '../../store/models/app.state';
import { selectHeader } from '../../store/reducers/ui.reducer';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[pharSecondHeader]'
})
export class SecondHeaderDirective implements OnInit, OnDestroy {

  header: Observable<boolean>;

  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private el: ElementRef,
    private store: Store<AppState>
  ) {
    el.nativeElement.classList.add('mfm-second-header');
  }

  ngOnInit(): void {
    this.store.pipe(
      select(selectHeader),
      filter(h => !h),
      takeUntil(this.unsubscribe$)
    ).subscribe(() => this.el.nativeElement.classList.add('mfm-second-header--no-header'));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

}

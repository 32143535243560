@if (elementDraggedHelper$ | async) {
  <div
    [class.question-view_drop-zone--highlighted]="builderMode && (elementResized | async)"
    [class.question-view_drop-zone--builder-mode]="builderMode"
    class="question-view_drop-zone"
    id="question-view_drop-zone">
    @for (control of controls; track control.controlID) {
      @if (control.controlType === 'dropzone' && allowMultipleControls) {
        <div
          class="question-view_drop-zone_new-line"
          [class.question-view_drop-zone_between]="control.betweenLinesDrop"
          [class.question-view_drop-zone_new-line--highlighted]="builderMode && (elementDragged | async) && canDropElement"
          [class.question-view_drop-zone_new-line--inactive]="(((elementDraggedHelper$ | async).reorder && (elementDraggedHelper$ | async).parent !== parentDataField))
          || ((elementDraggedHelper$ | async).type === FormElementsEnum.RepeatableGroup && control.grid.columnEnd !== 'span 4')"
          [style.grid-column-end]="control.grid.columnEnd" [style.grid-column-start]="control.grid.columnStart"
          [style.grid-row-start]="control.grid.rowStart"
          (dragenter)="dragOverNewLine($event, 'start')"
          (dragleave)="dragOverNewLine($event, 'end')"
          (dragover)="allowDrop($event, control)"
          (drop)="onDrop($event, control)"
        >
          <ng-container *ngTemplateOutlet="dropText"></ng-container>
        </div>
      } @else if (control.controlType !== 'dropzone') {
        <div [class.builder-element--bordered]="builderMode && (control.controlType === FormElementsEnum.TableElement ||
            control.controlType === FormElementsEnum.DataTable ||
            control.controlType === FormElementsEnum.GridRadio ||
            control.controlType === FormElementsEnum.ImageHighlighting ||
            control.controlType === FormElementsEnum.ImageTagging ||
            control.controlType === FormElementsEnum.FileUpload ||
            control.controlType === FormElementsEnum.TextBlock ||
            control.controlType === FormElementsEnum.ImageUpload)"
             [class.builder-element--repeatable-group]="builderMode && control.controlType === FormElementsEnum.RepeatableGroup"
             [class.builder-element--selected]="builderMode && (selectedControl | async).controlID === control.controlID && control.controlType !== FormElementsEnum.QuestionGroup"
             [class.builder-element]="builderMode && control.controlType !== FormElementsEnum.QuestionGroup"
             [class.builder-element_border-right-warn]=" control.commentsState === CommentsState.HasUnresolved"
             [id]="control.controlID"
             [style.grid-column-end]="control.grid.columnEnd" [style.grid-column-start]="control.grid.columnStart"
             [style.grid-row-start]="control.grid.rowStart">
          <!--        <div (drag)="onResizerDrag($event, control)"-->
          <!--             (dragend)="onResizerDragEnd()"-->
          <!--             (dragstart)="onResizerDragStart($event, control)"-->
          <!--             *ngIf="builderMode && (selectedControl | async).controlID === control.controlID && control.controlType !== FormElementsEnum.RepeatableGroup"-->
          <!--             [draggable]="builderMode" class="builder-element_left-resizer">-->
          <!--        </div>-->


          @switch (control.controlType) {
            @case (FormElementsEnum.MultiSelectQuantity) {
              <phar-multi-select-quantity
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()"
                (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.MultiSelectQuantity, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
            @case (FormElementsEnum.MultiSelect) {
              <phar-multi-select
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()"
                (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.MultiSelect, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
            @case (FormElementsEnum.NumericRatingScale) {
              <phar-numeric-rating-scale
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()" (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.NumericRatingScale, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
            @case (FormElementsEnum.VerbalRatingScale) {
              <phar-verbal-rating-scale
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()"
                (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.VerbalRatingScale, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
            @case (FormElementsEnum.SingleSelect) {
              <phar-single-select
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()"
                (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.SingleSelect, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
            @case (FormElementsEnum.Boolean) {
              <phar-general-boolean
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()"
                (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.Boolean, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
            @case (FormElementsEnum.VisualAnalogScale) {
              <phar-visual-analog-scale
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()" (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.VisualAnalogScale, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
            @case (FormElementsEnum.TextBlock) {
              <phar-text-block
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()"
                (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.TextBlock, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
            @case (FormElementsEnum.TextInput) {
              <phar-text-input
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()" (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.TextInput, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
            @case (FormElementsEnum.Number) {
              <phar-number-input
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()" (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.Number, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
            @case (FormElementsEnum.DatePicker) {
              <phar-date-picker
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()"
                (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.DatePicker, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
            @case (FormElementsEnum.TimePicker) {
              <phar-time-picker
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()" (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.TimePicker, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
            @case (FormElementsEnum.Image) {
              <phar-image
                [builderMode]="builderMode"
                [control]="control"
                [inRepeatable]="inRepeatable"
                [isDraggable]="allowMultipleControls"
                [isCommentsVisible]="isCommentsVisible"
                [isVerticalLayout]="isVerticalLayout"
                [canManageControl]="allowMultipleControls"
                [question]="question"
                (afterDragEnd)="onControlDragEnd()" (afterDragStart)="onControlDragStart($event, control)"
                (afterRemove)="removeEvent(control)"
                (afterUpdate)="afterControlUpdate($event)"
                (afterUpdateClick)="editQuestionElement(FormElementsEnum.Image, $event)"
                (duplicate)="duplicateQuestion(control)"
              />
            }
          }



          <!--        <div (drag)="onResizerDrag($event, control, false)"-->
          <!--             (dragend)="onResizerDragEnd()"-->
          <!--             (dragstart)="onResizerDragStart($event, control)"-->
          <!--             *ngIf="builderMode && (selectedControl | async).controlID === control.controlID && control.controlType !== FormElementsEnum.RepeatableGroup"-->
          <!--             [draggable]="builderMode" class="builder-element_right-resizer">-->
          <!--        </div>-->

        </div>
      }

    }


  </div>

}

@if ((builderMode && (elementDraggedHelper$ | async) && (allowMultipleControls || !controls.length))) {
  <div
    class="question-view_drop-zone_new-line question-view_drop-zone_bottom"
    [class.question-view_drop-zone_new-line--highlighted]="(elementDragged | async) && canDropElement"
    [class.question-view_drop-zone_new-line--inactive]="((elementDraggedHelper$ | async).reorder && (elementDraggedHelper$ | async).parent !== parentDataField)"
    (dragenter)="dragOverNewLine($event, 'start')"
    (dragleave)="dragOverNewLine($event, 'end')"
    (dragover)="allowDrop($event)"
    (drop)="onDrop($event)"
  >
    <ng-container *ngTemplateOutlet="dropText"></ng-container>
  </div>
}
<ng-template #dropText>
  <div class="drop-text w-100 text-center text-muted mt-1">
    Drop here
  </div>
</ng-template>

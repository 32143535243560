import { BaseCardSettings } from '../shared/card/card.model';

export enum ProjectStatusEnum {
  Draft = 'Draft',
  UnderReview = 'UnderReview',
  Rejected = 'Rejected',
  Released = 'Released',
  Archived = 'Archived',
  Amended = 'Amended',
  PendingReview = 'PendingReview'
}

export type ProjectModel = BaseCardSettings & {
  id: number;
  datasetId: number;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  userIdCreated: number;
  userIdUpdated: number;
  displayName: string;
  recordTitleField: string;
  isDeleted: boolean;
  userID: number;
  projectType: ProjectTypeEnum;
  integration: { [key: string]: string[] };
  agentId: number;
  isTemplate: boolean;
  parentTemplateId: number;
  shortName: string;
  organizationId: number;
  shared: string[];
  forms: any[];
  userFirstNameField: string;
  userLastNameField: string;
  customerIdentifier: string;
  projectStatus: ProjectStatusEnum;
  projectVersion: string;
  amendedDate: string | null;
  dateSentForReview: string | null;
  dateReviewStarted: string | null;
  rejectionDate: string | null;
  releaseDate: string | null;
  projectDuplicatedId?: number;
  projectAmendId?: number;
};

export interface ProjectByCustomer {
  projectId: number;
  projectName: string;
  lastModifiedAt: Date;
  lastModifiedBy: string;
  lastModifiedById: number;
  agentId: number;
  agentFirstName: string;
  agentLastName: string;
}

export enum ProjectTypeEnum {
  GeneralPurpose = 1,
  Dossier = 2
}

export type RecordFormsModel = BaseCardSettings & {
  recordFormId: number;
  recordId: number;
  assignmentId: number;
  recordTitle: string;
  formName: string;
  statuses?: {
    additionalProp1: {},
    additionalProp2: {},
    additionalProp3: {}
  };
  lastUpdated: string | Date;
  projectName: string;
  datasetName: string;
  type: number;
  createdBy: string;
  userIdCreatedBy: number;
  createdAt: string;
  formId: number;
};

export interface ExtendedRecordFormModel extends RecordFormsModel {
  records: RecordFormsModel[];
}


<div class="filter-container d-flex flex-row gap-1" (mouseenter)="showDelete = true"
     (mouseleave)="showDelete = false">
  <div class="filter-container__row position-relative w-100">
    @switch (filter.type) {
      @case (FilterType.Number) {
        <mat-form-field appearance="outline">
          <mat-label>{{ filter.label }}</mat-label>
          <input
            matInput
            type="number"
            inputmode="decimal"
            [formControl]="filterFormCtrl"
          >
        </mat-form-field>

      }
      @case (FilterType.String) {
        <mat-form-field appearance="outline">
          <mat-label>{{ filter.label }}</mat-label>
          <input
            matInput
            [formControl]="filterFormCtrl"
          >
        </mat-form-field>

      }
      @case (FilterType.Dropdown) {
        <mat-form-field appearance="outline">
          <mat-label>{{ filter.label }}</mat-label>

          <mat-select [multiple]="filter.options.multiple"
                      [formControl]="filterFormCtrl"
                      [panelClass]="'pb-0'"
                      (opened)="panelOpen()"
                      (closed)="panelClose()"
                      #matSelect
          >
            <div class="custom-select-panel">
              @for (option of filter.options.data | async; track option.id) {
                <mat-option [value]="option.id">{{ option.label }}
                </mat-option>
              }
            </div>
            @if (filter.options.multiple) {
              <footer id="apply-values" class="d-flex align-center justify-content-center w-100 cursor-pointer"
                      matRipple
                      (click)="applyValues(matSelect)">
                <mat-icon [svgIcon]="'check'"></mat-icon>
                <b>Confirm</b>
              </footer>
            }
          </mat-select>


        </mat-form-field>
      }
      @case (FilterType.DateRange) {
        <phar-date-range-picker id="date-range-picker" [formControl]="filterFormCtrl" [label]="filter.label"/>
      }
      @case (FilterType.AutoCompleter) {
        <mat-form-field id="autocompleter" class="w-100" appearance="outline">
          <mat-label>{{ filter.label }}</mat-label>

          @if (filter.options.multiple) {
            <mat-chip-grid #chipGrid [ngStyle]="{height:  filterFormCtrl.value?.length ? 'auto' : '33px'}">
              @for (option of filterFormCtrl.value; track option.id; let i = $index) {
                <mat-chip-row (removed)="removeAutoCompleteValue(option)">
                            <span class="text-truncate autocomplete-label d-flex">
                            {{ option.label }}
                            </span>
                  <button matChipRemove [attr.aria-label]="'remove ' + option.label">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              }
            </mat-chip-grid>
            <input #autoCompleteInput
                   [formControl]="autoCompleteFormCtrl"
                   [matChipInputFor]="chipGrid"
                   placeholder="Search"
                   [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              @for (option of filteredValues$ | async; track option.id) {
                <mat-option [value]="option">
                  <span class="text-break">{{ option.label }}</span>
                </mat-option>
              }
            </mat-autocomplete>
          } @else {
            <phar-autocompleter [formControl]="filterFormCtrl" [data]="filter.options.data | async"
                                [autoCompleter]="true"
                                class="w-100"
                                filterValue="label"
                                displayValue="label"
                                returnValue="id"/>
          }

        </mat-form-field>
      }
      @case (FilterType.Boolean) {
        <mat-slide-toggle class="mt-2" color="primary" [formControl]="filterFormCtrl">{{ filter.label }}
        </mat-slide-toggle>
      }
      @case (FilterType.RadioButtons) {
        <mat-radio-group [formControl]="filterFormCtrl">
          @for (option of filter.options.data | async; track option.id; let last = $last) {
            <mat-radio-button color="primary" [ngClass]="{'me-2': last}" [value]="option.id">{{ option.label }}
            </mat-radio-button>
          }
        </mat-radio-group>
      }
    }
    @if (showDelete) {
      <mat-icon id="clear-icon" class="cursor-pointer align-self-center" matTooltip="Remove filter"
                (click)="deleteFilter()">cancel
      </mat-icon>
    }

  </div>
</div>


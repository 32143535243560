<div class="approvals-header-title">Approvals</div>
@for (approval of approvals$ | async; track i; let i = $index) {
  <div class="approval">
    <div class="approval_info" [ngClass]="'approval_info_' + approval.state">
      <div class="approval_info_badge">
        @if (approval.state === ApprovalState.Pending ||
        approval.state === ApprovalState.Active) {
          <span>{{ i + 1 }}</span>
        }
        @switch (approval.state) {
          @case (ApprovalState.Approved) {
            <mat-icon svgIcon="check"></mat-icon>
          }
          @case (ApprovalState.Rejected) {
            <mat-icon svgIcon="close"></mat-icon>
          }
          @case (ApprovalState.Archived) {
            <mat-icon>archive</mat-icon>
          }
          @case (ApprovalState.Amended) {
            <mat-icon>edit</mat-icon>
          }
        }
      </div>
      <span class="approval_info_label">{{ approval.label }}</span>
    </div>
    @if (approval.state === ApprovalState.Active && approval.actionsTemplate) {
      <ng-container>
        <ng-template [ngTemplateOutlet]="approval.actionsTemplate"></ng-template>
      </ng-container>
    }
  </div>

}

<ng-template #sendForReviewTemplate>
  <button
    mat-raised-button
    class="button-success"
    [disabled]="locked"
    (click)="setFormStatus(ApprovalStatus.PendingReview)">
    <mat-icon svgIcon="send" class="approval_action-icon"></mat-icon>
    Send for review
  </button>
</ng-template>

<ng-template #startReviewTemplate>
  <button
    mat-raised-button
    class="button-success"
    [disabled]="locked"
    (click)="setFormStatus(ApprovalStatus.UnderReview)">
    <mat-icon svgIcon="visibility" class="approval_action-icon"></mat-icon>
    Start review
  </button>
</ng-template>

<ng-template #releaseOrRejectTemplate>
  <div class="d-flex">
    <button
      mat-raised-button
      class="button-warn me-2"
      [disabled]="locked"
      (click)="setFormStatus(ApprovalStatus.Rejected)">
      <mat-icon svgIcon="close" class="approval_action-icon"></mat-icon>
      Reject
    </button>

    <button
      mat-raised-button
      class="button-success"
      [disabled]="locked"
      (click)="setFormStatus(ApprovalStatus.Released)">
      <mat-icon svgIcon="check" class="approval_action-icon"></mat-icon>
      Release
    </button>
  </div>
</ng-template>


import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, computed,
  EventEmitter,
  HostListener,
  inject,
  input,
  Input,
  OnDestroy,
  OnInit,
  Output, Signal
} from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState, getState, selectCurrentControl } from '../../../store/models/app.state';
import { controlValueChanged, populateCurrentQuestion, updateQuestion } from '../../../question/store/question.actions';
import { QuestionModel } from '../../../question/question.model';
import { populateCurrentControl } from '../../../store/actions/control.actions';
import { isEqual, omit } from 'lodash-es';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ControlModel } from '../../../question/control.model';
import { PendingChangesService } from '../../pending-changes.service';
import { selectCurrentQuestion } from '../../../question/store/question.state';
import { QuestionService } from '../../../question/question.service';
import { globalLoading } from '../../../store/actions/ui.actions';
import { CurrentControlValidationService } from '../../services/current-control-validation.service';
import { DataFieldBindingService } from '../../../question/editors/bind-data-field-on-label-change/data-field-binding.service';
import { ControlActionClick, ControlActionsEnum } from '../base-control-template';
import { MatDialog } from '@angular/material/dialog';
import { RulesType } from '../../../question/rules-list/rules-type.enum';

@Component({
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PharBaseControlModelComponent implements OnInit, OnDestroy, AfterViewInit {
  control = input.required<ControlModel>();
  @Input() builderMode;
  @Input() question: QuestionModel;
  @Input() isDraggable = false;
  @Input() isCommentsVisible = false;
  @Input() isVerticalLayout = false;
  @Input() canManageControl = false;
  @Input() inRepeatable = false;
  @Output() public afterUpdateClick: EventEmitter<any> = new EventEmitter();
  @Output() public afterUpdate: EventEmitter<any> = new EventEmitter();
  @Output() public afterRemove: EventEmitter<any> = new EventEmitter();
  @Output() public afterDragStart: EventEmitter<any> = new EventEmitter();
  @Output() public afterDragEnd: EventEmitter<any> = new EventEmitter();
  @Output() public afterSave: EventEmitter<any> = new EventEmitter();
  @Output() public duplicate: EventEmitter<any> = new EventEmitter();

  isControlActive$: Observable<boolean>;
  isControlHovered$: Observable<boolean>;
  isControlHighlighted$: Observable<boolean>;
  protected pendingChangesService: PendingChangesService = inject(PendingChangesService);
  protected questionService: QuestionService = inject(QuestionService);
  protected currentControlValidationService: CurrentControlValidationService = inject(CurrentControlValidationService);
  protected dataFieldBindingService: DataFieldBindingService = inject(DataFieldBindingService);
  protected dialog: MatDialog = inject(MatDialog);
  private _isControlHovered$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isControlHighlighted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly destroy$ = new Subject<null>();
  hasConditionalRules: Signal<boolean> = computed(() => {
    return !!this.control().dependencies[RulesType.Conditions] &&
      Object.keys(this.control().dependencies[RulesType.Conditions]).some((ruleAction) =>
        this.control().dependencies[RulesType.Conditions][ruleAction]?.data?.length
      );
  })

  constructor(
    public store: Store<AppState>,
  ) {
    this.isControlActive$ = this.store.select(selectCurrentControl).pipe(switchMap((control) => of(control.controlID === this.control().controlID)));

    this.isControlHovered$ = this._isControlHovered$.asObservable();
  }

  @HostListener('mouseenter')
  mousEenter() {
    this._isControlHovered$.next(true);
  }

  @HostListener('mouseleave')
  mouseLeave() {
    this._isControlHovered$.next(false);
  }

  ngOnInit(): void {
    this.isControlActive$ = this.store.select(selectCurrentControl).pipe(switchMap((control) => of(control.controlID === this.control().controlID)));

    this.isControlHovered$ = this._isControlHovered$.asObservable();
    this.isControlHighlighted$ = this._isControlHighlighted$.asObservable();


    // https://pm.bgosoftware.com/browse/PHAR-508
    if (this.questionService.isQuestionAutoCreateMode()) {
      this.store.dispatch(populateCurrentControl({ control: this.control() }));
      this.store.dispatch(populateCurrentQuestion({ question: this.question }));

      // Open question editor when control is rendered
      this.edit(null, this.control());
    }
  }

  ngAfterViewInit(): void {
    this.selectCurrentControl();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  edit(event, control: ControlModel): void {
    const currentControlId = getState(this.store).control.current.control.controlID;
    if (!this.builderMode || currentControlId === control.controlID) {
      return;
    }

    this.checkShouldPropagate(this.pendingChangesService.hasPendingChanges, event);

    if (!this.canPerformActionOnControl(control)) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }

    this.afterUpdateClick.emit(control);
  }

  actionsHandler(actionClickEvent: ControlActionClick, control: ControlModel): void {
    const { action, event } = actionClickEvent;

    switch (action) {
      case ControlActionsEnum.Save:
        this.save(event, control);
        break
      case ControlActionsEnum.Remove:
        this.remove(event, control);
        break;
      case ControlActionsEnum.Edit:
        this.edit(event, control);
        break;
      case ControlActionsEnum.ViewComments:
        // this.viewComments(event, control);
        break;
      case ControlActionsEnum.DragStart:
        this.onDragStart(event);
        break;
      case ControlActionsEnum.DragEnd:
        this.onDragEnd(event);
        break;
      case ControlActionsEnum.Duplicate:
        this.duplicate.emit();
        break;
    }
  }

  save(event: MouseEvent, control: ControlModel): void {
    this.questionService.setSaveControlBeforeSaveQuestion(false);
    this.checkShouldPropagate(this.pendingChangesService.hasPendingChanges, event);

    if (!this.canPerformActionOnControl(control)) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }

    if (!this.currentControlValidationService.isEditorFormsValid) {
      this.currentControlValidationService.markFormAsTouched();
      return;
    }
    this.store.dispatch(globalLoading(true));
    this.store.dispatch(updateQuestion({ question: this.question }));
    this.dataFieldBindingService.saveDataFields();
    this.afterUpdate.emit(control);
  }

  remove(event: MouseEvent, control: ControlModel): void {
    this.checkShouldPropagate(this.pendingChangesService.hasPendingChanges, event);

    if (!this.canPerformActionOnControl(control)) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }

    this.afterRemove.emit(control);
  }

  onDragStart(event): void {
    this.checkShouldPropagate(this.pendingChangesService.hasPendingChanges, event);

    if (!this.canPerformActionOnControl(this.control())) {
      this.pendingChangesService.openPendingChangesSaveDialog();
      return;
    }
    this.afterDragStart.emit(event);
  }

  onDragEnd(event): void {
    this.afterDragEnd.emit(event);
  }

  private selectCurrentControl() {
    this.store
      .select((state) => state.control.current.control)
      .pipe(
        filter((control) => control.controlID === this.control()?.controlID),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        // do not check bindDataField since it retriggers multiple control changes
        // it is changed in the editors afterViewInit hook.
        if (this.control && data.controlID === this.control().controlID && !isEqual(omit(this.control, ['bindDataField']), omit(data, ['bindDataField']))) {
          this.emitValueChanged(data, true);
        } else if (this.control && data.controlID === this.control().controlID && !data.bindDataField) {
          this.emitValueChanged(data, true);
        }
      });
  }

  private emitValueChanged(control: ControlModel, shouldUpdateDataField: boolean = true) {
    this.afterUpdate.emit(control);
    this.store.dispatch(controlValueChanged({ control: control, shouldUpdateDataField: shouldUpdateDataField }));
  }

  private canPerformActionOnControl(control: ControlModel): boolean {
    let currentQuestion: QuestionModel;

    this.store.select(selectCurrentQuestion).pipe().subscribe((question) => {
      currentQuestion = question;
    })

    let isControlInActiveQuestion = false;

    if (currentQuestion && currentQuestion.controls.length > 0) {
      isControlInActiveQuestion = !!currentQuestion.controls.find((questionControl) => questionControl.controlID === control.controlID)
    }

    return !this.pendingChangesService.hasPendingChanges || isControlInActiveQuestion;

  }

  private checkShouldPropagate(hasChanges: boolean, event: any) {
    if (hasChanges) {
      event.stopImmediatePropagation();
    }
  }
}

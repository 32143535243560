import { Component, Input, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { PharTooltipDirective } from '../directives/tooltip.directive';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'phar-help-icon',
  templateUrl: 'help-icon.component.html',
  styleUrls: ['help-icon.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    PharTooltipDirective
  ]
})

export class HelpIconComponent {
  @Input() helpText: string;
  @Input() position: TooltipPosition;
}

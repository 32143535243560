import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { CardModel } from './card.model';
import { AppState } from '../../store/models/app.state';
import { deleteDataset } from '../../dataset/store/dataset.actions';
import { PharConfirmDialogService } from '../confirm-dialog/confirm-dialog-service.service';
import { deleteProject } from '../../project/store/project.actions';
import { deleteForm } from '../../form/store/form.actions';
import { deleteAssign, getAssignById } from '../../assign/store/assign.actions';
import { AssignTypeEnum } from '../../assign/assign-type.enum';
import { GroupTypeEnum } from '../../_old/_group/group-type.enum';
import { FormTypeEnum } from '../../form/form.model';
import { EntityType } from '../../core/models/entity-type-enum';
import { FormRouteData } from '../../core/models/route-data.interface';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'phar-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class PharCardComponent implements OnInit {

  @Input() card: CardModel<any>;
  @Input() deletePermission: string;
  @Input() buttonTemplate: TemplateRef<any>;
  @Input() footerInfoLeft: TemplateRef<any>;
  @Input() footerInfoRight: TemplateRef<any>;
  @Input() moreOptions: TemplateRef<any>;
  @Output() cardSelected: EventEmitter<CardModel<any>> = new EventEmitter<CardModel<any>>();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  deleteActions = {
    dataset: deleteDataset,
    project: deleteProject,
    form: deleteForm,
    assignment: deleteAssign,
    // group: deleteGroup,
    // role: deleteRole
  };
  assignTypeEnum = AssignTypeEnum;
  groupTypeEnum = GroupTypeEnum;
  url = '../edit';
  formTypesEnum = FormTypeEnum;
  cardBtnStyles = {};
  cardStyle = {};
  cardPrimaryColor = {};
  cardSecondaryColor = {};
  EntityType = EntityType;


  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private confirmDialog: PharConfirmDialogService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {

    this.activatedRoute.data
      .subscribe((data: FormRouteData) => {
        if (data.isContext) {
          this.url = '../edit-context';
        }
      });

    if (this.card.card.hasOwnProperty('settings') && this.card.card.settings) {
      this.cardBtnStyles['background-color'] = this.card.card.settings.buttonBackgroundColor;
      /* eslint-disable-next-line */
      this.cardBtnStyles['color'] = this.card.card.settings.buttonForegroundColor;
      this.cardStyle['background-color'] = this.card.card.settings.backgroundColor;
      /* eslint-disable-next-line */
      this.cardPrimaryColor['color'] = this.card.card.settings.foregroundPrimaryColor;
      /* eslint-disable-next-line */
      this.cardSecondaryColor['color'] = this.card.card.settings.foregroundSecondaryColor;
    }
  }

  cardClick(): void {
    this.openCard(this.card);
  }

  openCard(card: CardModel<any>): void {
    if (this.card.card.type === 'assignment') {
      // this.store.dispatch(getAssignById({ id: card.original.id }));
      // const dialogRef = this.dialog.open(AssignFormsComponent, {
      //   width: '980px',
      //   minWidth: 980,
      //   backdropClass: 'assign-form_backdrop',
      //   panelClass: 'assign-form-dialog',
      //   data: {
      //     mode: 'edit'
      //   },
      //   autoFocus: false,
      //   restoreFocus: false
      // });
      // dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      // });
    } else {
      if (this.card.card.type === 'customerProject') {
        this.router.navigate([`dashboard/study/edit/${this.card.original.projectId}/data/forms`]);
      } else {
        this.router.navigate([this.url, card.original.id], { relativeTo: this.activatedRoute });
      }
    }
  }

  deleteCard(event, card: CardModel<any>): void {
    event.stopImmediatePropagation();
    const cardName = this.card.card.type === 'assignment' ? card.original.formName : card.card.name;
    this.confirmDialog.openConfirmDialog('Do you want to remove ' + cardName + '?').subscribe(result => {
      if (result) {
        this.deleteElement(card);
      }
      this.trigger.closeMenu();
    });
  }

  deleteElement(card: CardModel<any>): void {
    this.store.dispatch(this.deleteActions[this.card.card.type]({ id: card.original.id }));
  }

  mouseOver($event: MouseEvent): void {
    if (this.card.card.hasOwnProperty('settings') && this.card.card.settings) {
      /* eslint-disable-next-line */
      $event.target['style'].backgroundColor = this.card.card.settings.buttonOnHoverBackgroundColor;
      /* eslint-disable-next-line */
      $event.target['style'].color = this.card.card.settings.buttonOnHoverForegroundColor;
    }
  }

  mouseLeave($event: MouseEvent): void {
    if (this.card.card.hasOwnProperty('settings') && this.card.card.settings) {
      /* eslint-disable-next-line */
      $event.target['style'].backgroundColor = this.cardBtnStyles['background-color'];
      /* eslint-disable-next-line */
      $event.target['style'].color = this.cardBtnStyles['color'];
    }

  }

}

import { animate, state, style, transition, trigger } from '@angular/animations';

export enum ToggleSidebarSectionState {
  Open = 'open',
  Close = 'close',
}

export const toggleSidebarSection = trigger('toggleSidebarSection', [
  state(ToggleSidebarSectionState.Open, style({
    height: 'auto',
    maxHeight: '6000px'
  })),
  state(ToggleSidebarSectionState.Close, style({
    height: 'auto',
    maxHeight: '0',
    overflow: 'hidden',
    paddingTop: '0',
    paddingBottom: '0'
  })),
  transition(`${ToggleSidebarSectionState.Open} <=> ${ToggleSidebarSectionState.Close}`, animate('.1s ease-in-out'))
]);
